import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col, Tab, Nav, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";

const WalletAddress = (props) => {
const t = useTranslation("wallet_address")

    return (
        <>
            <div className="buy-crypto-sec">
                <Row className="justify-content-md-center">
                    <Col md={5}>
                        <div className="exchange-box">
                            <Tab.Container id="left-tabs-example" defaultActiveKey="buy-crypto">
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="buy-crypto">{t("buy")}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="swap-crypto">{t("swap")}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Tab.Container>
                            <Form className="exchange-form">
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>{t("wallet.name")}</Form.Label>
                                    <Form.Control type="email" placeholder={t("wallet.placeholder")} />
                                </Form.Group>
                                <div className="error-card">
                                    <Image
                                        src={window.location.origin + "/img/warning-icon.svg"}
                                        className="exchange-warning-icon"
                                    />
                                    <span>Please Enter a valid wallet address</span>
                                </div>
                                <div className="exchange-btn-sec">
                                    <Button className="default-btn" onClick={() => props.setStep(3)}>
                                    {t("continue")}
                                    </Button>
                                    <p> {t("by_continuing_you_agree_to_our")} <Link to="#"> {t("cookie")}</Link></p>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default WalletAddress;
