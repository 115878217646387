import React, { useState } from "react";
import {Container, Row, Col, Accordion, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const FAQIndex = (props) => {
  return (
    <>
      <div className="faq-sec sm-padding">
        <Container>
            <Row className="justify-content-md-center">
                <Col md={8} lg={9} className="text-center">
                    <div className="section-title">
                        <h2 data-aos="fade-up">FAQ</h2>
                    </div>
                </Col>
            </Row>
            <div className="faq-box">
            <Row className="align-items-center">
                <Col md={12} xl={6}>
                    <div className="faq-img-sec">
                        <Image
                            className="faq-img-1"
                            data-aos="fade-up"
                            src={
                                window.location.origin +
                                "/img/gif/faq.gif"
                            }
                            type="image/png"
                        />
                    </div>
                </Col>
                <Col md={12} xl={6}>
                  <Accordion defaultActiveKey="0" className="faq-accordion-1">
                    <Accordion.Item eventKey="0" data-aos="fade-up">
                      <Accordion.Header>What is Herald Exchange Pro and how does it work?</Accordion.Header>
                      <Accordion.Body>
                      Herald Exchange Pro is a digital platform where you can buy, sell, and trade a variety of cryptocurrencies. It works by matching your trade orders with other users, providing a secure and intuitive interface for managing your digital assets. To start trading, simply create an account, deposit funds, and you're ready to go.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1" data-aos="fade-up">
                      <Accordion.Header>How do I create an account on Herald Exchange Pro?</Accordion.Header>
                      <Accordion.Body>
                      Creating an account is easy! Visit the Herald Exchange website, click on 'Sign Up', and fill in the necessary details. After verifying your email address and completing any required KYC (Know Your Customer) processes, your account will be active and ready for trading.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2" data-aos="fade-up">
                      <Accordion.Header>What kind of security measures does Herald Exchange Pro implement?</Accordion.Header>
                      <Accordion.Body>
                      Herald Exchange Pro prioritizes your security with features like two-factor authentication, end-to-end encryption, and cold storage of assets. Regular security audits and user-focused privacy protocols are in place to protect your information and funds.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3" data-aos="fade-up">
                      <Accordion.Header>Can I access Herald Exchange Pro on my mobile device?</Accordion.Header>
                      <Accordion.Body>
                      Yes, Herald Exchange Pro offers a mobile app that allows you to manage your trades and monitor your portfolio from anywhere, at any time. The app is designed for both Android and iOS devices, ensuring a seamless trading experience on the go.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4" data-aos="fade-up">
                      <Accordion.Header>What fees does Herald Exchange Pro charge for trading?</Accordion.Header>
                      <Accordion.Body>
                      Herald Exchange Pro has a competitive and transparent fee structure for trading. Fees vary based on the transaction type and volume, but we strive to keep costs low to ensure trading is accessible for everyone. For detailed information on our fees, please visit the 'Fees and Charges' section on our website.
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5" data-aos="fade-up">
                      <Accordion.Header>How can I get support if I encounter issues on Herald Exchange Pro?</Accordion.Header>
                      <Accordion.Body>
                      If you need assistance, our customer support team is available 24/7. You can reach out to us via the support section on our website, through our in-app support feature, or by emailing our helpdesk directly. We're here to help resolve any issues promptly.
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </Col>
            </Row>
            </div>
        </Container>
      </div>
    </>
  );
};

export default FAQIndex;
