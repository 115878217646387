import React from 'react'
import { Form, Button, Modal } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";

const IfscCodeModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <Formik>
          <FORM className="create-recipient-form">
            <Form.Group controlId="formBasicEmail" className="mb-4">
              <Form.Label>
                Ifsc Code <span>*</span>
              </Form.Label>
              <Field
                type="text"
                className="form-control"
                name="middle_name"
                placeholder="Enter Ifsc Code"
              />
              <ErrorMessage
                component={"div"}
                name="last_name"
                className="text-danger"
              />
            </Form.Group>
          </FORM>
        </Formik>
        <div className="create-recipient-btn-sec">
          <Button
            className="new-overlay-btn"
            type="submit"
          >
            Cancel
          </Button>
          <Button
            className="default-btn"
            type="submit"
          >
            Submit
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default IfscCodeModal