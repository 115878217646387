import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  TOKEN_BUY_START,
  GET_ADMIN_BANK_ACCOUNT_START,
  TOKEN_BUY_TRANSACTIONS_LIST_START,
  MORE_TOKEN_BUY_TRANSACTIONS_LIST_START,
  WITHDRAWAL_REQUEST_START,
  GET_ADMIN_CRYPTO_ACCOUNT_START,
  DEPOSIT_TOKEN_START,
  TOKEN_SWAP_START,
  GET_CURRENCIES_LIST_START,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  TOKEN_SWAP_TRANSACTIONS_LIST_START,
  MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START,
  GET_ADMIN_WALLET_START,
  SELL_TOKEN_START,
  SELL_TOKEN_TRANSACTIONS_LIST_START,
  SELL_TOKEN_MORE_TRANSACTIONS_LIST_START,
  BUY_TOKENS_EXPORT_START,
  SELL_TOKENS_EXPORT_START,
  DEPOSIT_TOKENS_EXPORT_START,
  SWAP_TOKENS_EXPORT_START,
  BUY_TOKENS_VIEW_START,
  SELL_TOKENS_VIEW_START,
  DEPOSIT_TOKENS_VIEW_START,
  SWAP_TOKENS_VIEW_START,
  CARD_TRANSACTIONS_START,
  WALLET_PAYMENTS_EXPORT_START,
  USER_WITHDRAWALS_EXPORT_START,
  GENERATE_QUOTE_START,
  WITHDRAWAL_STATUS_START,
  BUY_TOKENS_VIA_WALLET_START,
  DEPOSIT_TOKENS_VIA_BANK_START,
  CRYPTO_WITHDRAWAL_REQUEST_START,
  GET_SWAP_WALLETS_LIST_START,
  GET_CURRENCY_PAIR_LIST_START,
  TOKEN_TRADE_TRANSACTION_START,
  TOKEN_TRADE_TRANSACTION_LIST_START,
  SINGLE_TRADE_TRANSACTION_START,
  EXPORT_TRADE_TRANSACTION_START,
  SINGLE_WITHDRAW_TRANSACTION_START,
} from "../actions/ActionConstant";
import {
  getAdminBankAccountSuccess,
  getAdminBankAccountFailure,
  tokenBuySuccess,
  tokenBuyFailure,
  tokenBuyTransactionsListSuccess,
  tokenBuyTransactionsListFailure,
  withdrawalRequestSuccess,
  withdrawalRequestFailure,
  getAdminCryptoAccountSuccess,
  getAdminCryptoAccountFailure,
  depositTokenSuccess,
  depositTokenFailure,
  tokenSwapSuccess,
  tokenSwapFailure,
  getCurrenciesListSuccess,
  getCurrenciesListFailure,
  tokenDepositTransactionsListSuccess,
  tokenDepositTransactionsListFailure,
  tokenSwapTransactionsListSuccess,
  tokenSwapTransactionsListFailure,
  sellTokenSuccess,
  sellTokenFailure,
  getAdminWalletSuccess,
  getAdminWalletFailure,
  sellTokenTransactionsListSuccess,
  sellTokenTransactionsListFailure,
  buyTokensExportSuccess,
  buyTokensExportFailure,
  sellTokensExportSuccess,
  sellTokensExportFailure,
  depositTokensExportSuccess,
  depositTokensExportFailure,
  swapTokensExportSuccess,
  swapTokensExportFailure,
  buyTokensViewSuccess,
  buyTokensViewFailure,
  sellTokensViewSuccess,
  sellTokensViewFailure,
  depositTokensViewSuccess,
  depositTokensViewFailure,
  swapTokensViewSuccess,
  swapTokensViewFailure,
  cardTransactionsSuccess,
  cardTransactionsFailure,
  walletPaymentsExportSuccess,
  walletPaymentsExportFailure,
  userWithdrawalsExportSuccess,
  userWithdrawalsExportFailure,
  generateQuoteSuccess,
  generateQuoteFailure,
  withdrawalStatusSuccess,
  withdrawalStatusFailure,
  buyTokensViaWalletSuccess,
  buyTokensViaWalletFailure,
  depositTokensViaBankSuccess,
  depositTokensViaBankFailure,
  cryptoWithdrawalRequestSuccess,
  cryptoWithdrawalRequestFailure,
  getSwapWalletsListSuccess,
  getSwapWalletsListFailure,
  getCurrencyPairListSuccess,
  getCurrencyPairListFailure,
  tokenTradeTransactionSuccess,
  tokenTradeTransactionFailure,
  singleTradeTransactionSuccess,
  singleTradeTransactionFailure,
  tokenTradeTransactionListSuccess,
  tokenTradeTransactionListFailure,
  exportTradeTransactionSuccess,
  exportTradeTransactionFailure,
  singleWithdrawTransactionSuccess,
  singleWithdrawTransactionFailure,
} from "../actions/BuyAction";

import {
  fetchWithDrawalsSuccess,
  fetchAllTransactionSuccess,
  fetchSingleWalletViewSuccess,
} from "../actions/WalletAction";

import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";

function* getAdminBankAccountAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_admin_account",
    payload: action.data,
    errorNotify: false,
    success: getAdminBankAccountSuccess,
    failure: getAdminBankAccountFailure,
  });
}

function* tokenBuyAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_via_bank",
    successNotify: true,
    payload: action.data,
    success: tokenBuySuccess,
    failure: tokenBuyFailure,
  });
}

function* tokenBuyTransactionsListAPI(action) {
  const tokenBuyTransactionsListData = yield select(
    (state) => state.buy.tokenBuyTransactionsList.data
  );
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_list",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      if (Object.keys(tokenBuyTransactionsListData).length > 0) {
        store.dispatch(
          tokenBuyTransactionsListSuccess({
            token_transactions: [
              ...tokenBuyTransactionsListData.token_transactions,
              ...data.token_transactions,
            ],
            total: data.total,
          })
        );
      } else {
        store.dispatch(tokenBuyTransactionsListSuccess(data));
      }
    },
    failure: tokenBuyTransactionsListFailure,
  });
}

function* withdrawalRequestAPI(action) {
  const withDrawalsData = yield select(
    (state) => state.wallet.withDrawals.data
  );
  const walletData = yield select((state) => state.wallet.singleWallet.data);
  yield getCommonSaga({
    apiUrl: "user_withdrawals/withdraw_fund",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(withdrawalRequestSuccess(data));
      if (Object.keys(walletData).length > 0)
        store.dispatch(
          fetchSingleWalletViewSuccess({
            ...walletData,
            user_wallet: data.user_wallet,
          })
        );
      if (Object.keys(withDrawalsData).length > 0) {
        store.dispatch(
          fetchWithDrawalsSuccess({
            ...withDrawalsData,
            user_withdrawals: [
              data.user_withdrawal,
              ...withDrawalsData.user_withdrawals,
            ],
            total_user_withdrawals: withDrawalsData.total_user_withdrawals + 1,
          })
        );
      }
    },
    failure: withdrawalRequestFailure,
  });
}

function* getAdminCryptoAccountAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_admin_wallet",
    payload: action.data,
    success: getAdminCryptoAccountSuccess,
    failure: getAdminCryptoAccountFailure,
  });
}

function* depositTokenAPI(action) {
  const transactionData = yield select(
    (state) => state.wallet.allTransaction.data
  );
  yield getCommonSaga({
    apiUrl: "efi/deposit_tokens",
    payload: action.data,
    successNotify: false,
    successCallback: true,
    success: (data) => {
      store.dispatch(depositTokenSuccess(data));
      if (Object.keys(transactionData).length > 0) {
        store.dispatch(
          fetchAllTransactionSuccess({
            ...transactionData,
            user_wallet_payments: [
              data.user_wallet_payment,
              ...transactionData.user_wallet_payments,
            ],
            total_payments: transactionData.total_payments + 1,
          })
        );
      }
      getSuccessNotificationMessage("Tokens initiated successfully");
    },
    failure: depositTokenFailure,
  });
}
function* tokenDepositTransactionsListAPI(action) {
  const tokenDepositransactionsList = yield select(
    (state) => state.buy.tokenDepositTransactionsList.data
  );
  yield getCommonSaga({
    apiUrl: "efi/deposit_tokens_list",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      if (Object.keys(tokenDepositransactionsList).length > 0) {
        store.dispatch(
          tokenDepositTransactionsListSuccess({
            token_transactions: [
              ...tokenDepositransactionsList.token_transactions,
              ...data.token_transactions,
            ],
            total_token_transactions: data.total,
          })
        );
      } else {
        store.dispatch(tokenDepositTransactionsListSuccess(data));
      }
    },
    failure: tokenDepositTransactionsListFailure,
  });
}

function* tokenSwapAPI(action) {
  const { endpoint, ...rest } = action.data;
  yield getCommonSaga({
    apiUrl: endpoint,
    payload: rest,
    successNotify: false,
    success: tokenSwapSuccess,
    failure: tokenSwapFailure,
  });
}
function* tokenSwapTransactionsListAPI(action) {
  const tokenSwapTransactionsListData = yield select(
    (state) => state.buy.tokenSwapTransactionsList.data
  );
  yield getCommonSaga({
    apiUrl: "efi/swap_tokens_list",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      if (Object.keys(tokenSwapTransactionsListData).length > 0) {
        store.dispatch(
          tokenSwapTransactionsListSuccess({
            token_transactions: [
              ...tokenSwapTransactionsListData.token_transactions,
              ...data.token_transactions,
            ],
            total: data.total,
          })
        );
      } else {
        store.dispatch(tokenSwapTransactionsListSuccess(data));
      }
    },
    failure: tokenSwapTransactionsListFailure,
  });
}

function* getCurrenciesAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_currencies",
    payload: action.data,
    success: getCurrenciesListSuccess,
    failure: getCurrenciesListFailure,
  });
}

function* getAdminWalletAPI(action) {
  yield getCommonSaga({
    apiUrl: "get_admin_wallet",
    payload: action.data,
    success: getAdminWalletSuccess,
    failure: getAdminWalletFailure,
  });
}

function* tokenSellAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/sell_tokens",
    successNotify: true,
    payload: action.data,
    success: sellTokenSuccess,
    failure: sellTokenFailure,
  });
}

function* sellTokenTransactionsListAPI(action) {
  const sellTokenTransactionsListData = yield select(
    (state) => state.buy.sellTokenTransactionsList.data
  );
  yield getCommonSaga({
    apiUrl: "efi/sell_tokens_list",
    payload: action.data,
    successCallback: true,
    success: (data) => {
      if (Object.keys(sellTokenTransactionsListData).length > 0) {
        store.dispatch(
          sellTokenTransactionsListSuccess({
            token_transactions: [
              ...sellTokenTransactionsListData.token_transactions,
              ...data.token_transactions,
            ],
            total: data.total,
          })
        );
      } else {
        store.dispatch(sellTokenTransactionsListSuccess(data));
      }
    },
    failure: sellTokenTransactionsListFailure,
  });
}

function* buyTokensExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_export",
    successNotify: true,
    payload: action.data,
    success: buyTokensExportSuccess,
    failure: buyTokensExportFailure,
  });
}

function* sellTokensExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/sell_tokens_export",
    successNotify: true,
    payload: action.data,
    success: sellTokensExportSuccess,
    failure: sellTokensExportFailure,
  });
}

function* depositTokensExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/deposit_tokens_export",
    successNotify: true,
    payload: action.data,
    success: depositTokensExportSuccess,
    failure: depositTokensExportFailure,
  });
}

function* swapTokensExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/swap_tokens_export",
    successNotify: true,
    payload: action.data,
    success: swapTokensExportSuccess,
    failure: swapTokensExportFailure,
  });
}

function* buyTokensViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_view",
    successNotify: false,
    payload: action.data,
    success: buyTokensViewSuccess,
    failure: buyTokensViewFailure,
  });
}

function* sellTokensViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/sell_tokens_view",
    successNotify: false,
    payload: action.data,
    success: sellTokensViewSuccess,
    failure: sellTokensViewFailure,
  });
}

function* depositTokensViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/deposit_tokens_view",
    successNotify: false,
    payload: action.data,
    success: depositTokensViewSuccess,
    failure: depositTokensViewFailure,
  });
}

function* swapTokensViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/swap_tokens_view",
    successNotify: false,
    payload: action.data,
    success: swapTokensViewSuccess,
    failure: swapTokensViewFailure,
  });
}

function* cardTransactionsAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_via_card",
    successNotify: false,
    payload: action.data,
    success: cardTransactionsSuccess,
    failure: cardTransactionsFailure,
  });
}

function* walletPaymentsExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "wallet_payments/export",
    successNotify: true,
    payload: action.data,
    success: walletPaymentsExportSuccess,
    failure: walletPaymentsExportFailure,
  });
}

function* userWithdrawalsExportAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals/export",
    successNotify: true,
    payload: action.data,
    success: userWithdrawalsExportSuccess,
    failure: userWithdrawalsExportFailure,
  });
}

function* generateQuoteAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals/generate_quote",
    successNotify: true,
    payload: action.data,
    success: generateQuoteSuccess,
    failure: generateQuoteFailure,
  });
}

function* withdrawalStatusAPI(action) {
  let withdrawList = yield select((state) => state.wallet.withDrawals.data);
  yield getCommonSaga({
    apiUrl: "user_withdrawals/refresh",
    successNotify: true,
    payload: action.data,
    success: withdrawalStatusSuccess,
    failure: withdrawalStatusFailure,
  });
}

function* buyTokensViaWalletAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/buy_tokens_via_wallet",
    successNotify: true,
    payload: action.data,
    success: buyTokensViaWalletSuccess,
    failure: buyTokensViaWalletFailure,
  });
}

function* depositTokensViaBankAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/deposit_tokens_via_bank",
    successNotify: false,
    payload: action.data,
    success: depositTokensViaBankSuccess,
    failure: depositTokensViaBankFailure,
  });
}

function* cryptoWithdrawalRequestAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals/withdraw_crypto",
    successNotify: false,
    payload: action.data,
    success: cryptoWithdrawalRequestSuccess,
    failure: cryptoWithdrawalRequestFailure,
  });
}

function* getSwapWalletsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/get_swap_wallets",
    successNotify: true,
    payload: action.data,
    success: getSwapWalletsListSuccess,
    failure: getSwapWalletsListFailure,
  });
}

function* getCurrencyPairListAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/get_currency_pairs",
    successNotify: true,
    payload: action.data,
    success: getCurrencyPairListSuccess,
    failure: getCurrencyPairListFailure,
  });
}

function* tokenTradeTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/trade_tokens",
    successNotify: true,
    payload: action.data,
    success: tokenTradeTransactionSuccess,
    failure: tokenTradeTransactionFailure,
  });
}

function* tokenTradeTransactionListAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/trade_tokens_list",
    successNotify: true,
    payload: action.data,
    success: tokenTradeTransactionListSuccess,
    failure: tokenTradeTransactionListFailure,
  });
}

function* singleTradeTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/trade_tokens_view",
    successNotify: true,
    payload: action.data,
    success: singleTradeTransactionSuccess,
    failure: singleTradeTransactionFailure,
  });
}

function* exportTradeTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "efi/trade_tokens_export",
    successNotify: true,
    payload: action.data,
    success: exportTradeTransactionSuccess,
    failure: exportTradeTransactionFailure,
  });
}

function* singleWithdrawTransactionAPI(action) {
  yield getCommonSaga({
    apiUrl: "user_withdrawals/show",
    successNotify: true,
    payload: action.data,
    success: singleWithdrawTransactionSuccess,
    failure: singleWithdrawTransactionFailure,
  });
}

export default function* BuySaga() {
  yield all([
    yield takeLatest(GET_ADMIN_BANK_ACCOUNT_START, getAdminBankAccountAPI),
    yield takeLatest(TOKEN_BUY_START, tokenBuyAPI),
    yield takeLatest(
      TOKEN_BUY_TRANSACTIONS_LIST_START,
      tokenBuyTransactionsListAPI
    ),
    yield takeLatest(
      MORE_TOKEN_BUY_TRANSACTIONS_LIST_START,
      tokenBuyTransactionsListAPI
    ),
    yield takeLatest(WITHDRAWAL_REQUEST_START, withdrawalRequestAPI),
    yield takeLatest(GET_ADMIN_CRYPTO_ACCOUNT_START, getAdminCryptoAccountAPI),
    yield takeLatest(DEPOSIT_TOKEN_START, depositTokenAPI),
    yield takeLatest(
      TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
      tokenDepositTransactionsListAPI
    ),
    yield takeLatest(
      MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
      tokenDepositTransactionsListAPI
    ),
    yield takeLatest(
      TOKEN_SWAP_TRANSACTIONS_LIST_START,
      tokenSwapTransactionsListAPI
    ),
    yield takeLatest(
      MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START,
      tokenSwapTransactionsListAPI
    ),
    yield takeLatest(TOKEN_SWAP_START, tokenSwapAPI),
    yield takeLatest(GET_CURRENCIES_LIST_START, getCurrenciesAPI),
    yield takeLatest(GET_ADMIN_WALLET_START, getAdminWalletAPI),
    yield takeLatest(SELL_TOKEN_START, tokenSellAPI),
    yield takeLatest(
      SELL_TOKEN_TRANSACTIONS_LIST_START,
      sellTokenTransactionsListAPI
    ),
    yield takeLatest(
      SELL_TOKEN_MORE_TRANSACTIONS_LIST_START,
      sellTokenTransactionsListAPI
    ),
    yield takeLatest(BUY_TOKENS_EXPORT_START, buyTokensExportAPI),
    yield takeLatest(SELL_TOKENS_EXPORT_START, sellTokensExportAPI),
    yield takeLatest(DEPOSIT_TOKENS_EXPORT_START, depositTokensExportAPI),
    yield takeLatest(SWAP_TOKENS_EXPORT_START, swapTokensExportAPI),
    yield takeLatest(BUY_TOKENS_VIEW_START, buyTokensViewAPI),
    yield takeLatest(SELL_TOKENS_VIEW_START, sellTokensViewAPI),
    yield takeLatest(DEPOSIT_TOKENS_VIEW_START, depositTokensViewAPI),
    yield takeLatest(SWAP_TOKENS_VIEW_START, swapTokensViewAPI),
    yield takeLatest(CARD_TRANSACTIONS_START, cardTransactionsAPI),
    yield takeLatest(WALLET_PAYMENTS_EXPORT_START, walletPaymentsExportAPI),
    yield takeLatest(USER_WITHDRAWALS_EXPORT_START, userWithdrawalsExportAPI),
    yield takeLatest(GENERATE_QUOTE_START, generateQuoteAPI),
    yield takeLatest(WITHDRAWAL_STATUS_START, withdrawalStatusAPI),
    yield takeLatest(BUY_TOKENS_VIA_WALLET_START, buyTokensViaWalletAPI),
    yield takeLatest(DEPOSIT_TOKENS_VIA_BANK_START, depositTokensViaBankAPI),
    yield takeLatest(
      CRYPTO_WITHDRAWAL_REQUEST_START,
      cryptoWithdrawalRequestAPI
    ),
    yield takeLatest(GET_SWAP_WALLETS_LIST_START, getSwapWalletsListAPI),
    yield takeLatest(GET_CURRENCY_PAIR_LIST_START, getCurrencyPairListAPI),
    yield takeLatest(TOKEN_TRADE_TRANSACTION_START, tokenTradeTransactionAPI),
    yield takeLatest(
      TOKEN_TRADE_TRANSACTION_LIST_START,
      tokenTradeTransactionListAPI
    ),
    yield takeLatest(SINGLE_TRADE_TRANSACTION_START, singleTradeTransactionAPI),
    yield takeLatest(EXPORT_TRADE_TRANSACTION_START, exportTradeTransactionAPI),
    yield takeLatest(
      SINGLE_WITHDRAW_TRANSACTION_START,
      singleWithdrawTransactionAPI
    ),
  ]);
}
