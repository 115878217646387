import React, { useCallback, useEffect, useState, useRef } from "react";
import { Button, Form, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import {
  getAdminWalletStart,
  sellTokenStart,
} from "../../store/actions/BuyAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useDropzone } from "react-dropzone";
import { setTokenSellData } from "../../store/actions/BuyAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  getErrorNotificationMessage,
  getSuccessNotificationMessage,
} from "../helper/ToastNotification";
import { updateSwapData } from "../../store/actions/UserAction";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import CopyToClipboard from "react-copy-to-clipboard";

const SwapWalletAddressSec = (props) => {
  const dispatch = useDispatch();
  const t = useTranslation("sell_index");
  const formRef = useRef(null);
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const adminWallet = useSelector((state) => state.buy.adminWallet);
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));
  const tokenSell = useSelector((state) => state.buy.tokenSell);
  const swapData = useSelector((state) => state.user.swapData);
  const setSwapData = (data) => dispatch(updateSwapData(data));
  const currencies = useSelector((state) => state.buy.currencies);
  const [errorMessage, setErrorMessage] = useState(false);

  const [skipRender, setSkipRender] = useState(true);

  const validationSchema = Yup.object().shape({
    // user_bank_account_id: Yup.string().required(t("bank_account.required")),
    transaction_hash: Yup.string()
      .required(t("transaction.required"))
      .min(3, t("transaction.invalid"))
      .max(66, "Max 66 characters allowed"),
    // file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  const validationAddressSchema = Yup.object().shape({
    user_wallet_address: Yup.string().required(
      t("user_wallet_address.required")
    ),
    transaction_hash: Yup.string()
      .required(t("transaction.required"))
      .min(3, t("transaction.invalid")),
    file: Yup.string().required(t("please_upload_payment_screenshot")),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getAdminWalletStart());
    // dispatch(getBankAccountStart());
  }, []);

  useEffect(() => {
    if (adminWallet.data && Object.keys(adminWallet.data).length > 0) {
      setSwapData({
        ...swapData,
        admin_crypto_account_id:
          adminWallet.data.admin_crypto_account.admin_crypto_account_id,
      });
      updateTokenSellData({
        ...tokenSellData,
        admin_crypto_account_id:
          adminWallet.data.admin_crypto_account.admin_crypto_account_id,
        admin_bank_account_id:
          adminWallet.data.admin_crypto_account.admin_crypto_account_id,
      });
    }
  }, [adminWallet]);

  const [files, setFiles] = useState([]);

  const onDropRejected = useCallback((rejectedFiles) => {
    if (rejectedFiles.length > 1) {
      setErrorMessage(t("please_upload_only_one_file_at_a_time"));
    } else {
      const invalidFiles = rejectedFiles.filter(
        (file) => !["image/jpeg", "image/png", "image/jpg"].includes(file.type)
      );
      if (invalidFiles.length > 0) {
        setErrorMessage(t("invalid_file_format"));
      }
    }
  }, []);

  const onDropAccepted = () => {
    setErrorMessage("");
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDropRejected,
    onDropAccepted,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/jpg": [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      formRef.current.setFieldValue("file", "preview");
    },
  });

  const images = files.map((file, key) => (
    <div className="preview-added-image" key={key}>
      <Button
        variant="link"
        className="preview-cancel"
        onClick={() => {
          formRef.current.setFieldValue("file", "");
          setFiles([]);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="#fff"
          viewBox="0 0 24 24"
        >
          <path d="M18 6a1 1 0 00-1.414 0L12 10.586 7.414 6A1 1 0 006 6a1 1 0 000 1.414L10.586 12 6 16.586A1 1 0 006 18a1 1 0 001.414 0L12 13.414 16.586 18A1 1 0 0018 18a1 1 0 000-1.414L13.414 12 18 7.414A1 1 0 0018 6z"></path>
        </svg>
      </Button>
      <CustomLazyLoad
        className="product-preview"
        key={file.name}
        src={file.preview}
        alt="image-preview"
      />
    </div>
  ));

  const onSubmit = (values) => {
    // if (files.length > 0) {
    if (
      currencies.data.currencies.find(
        (item) => item.currency_code == tokenSellData.to_currency
      ).currency_type == "forex"
    ) {
      dispatch(
        sellTokenStart({
          ...tokenSellData,
          transaction_hash: values.transaction_hash,
          // user_bank_account_id: values.user_bank_account_id,
          // file: files[0] ? files[0] : "",
        })
      );
    } else {
      dispatch(
        sellTokenStart({
          ...tokenSellData,
          transaction_hash: values.transaction_hash,
          user_wallet_address: values.user_wallet_address,
          // file: files[0],
        })
      );
    }
    // } else {
    //   getErrorNotificationMessage(t("please_upload_payment_screenshot"));
    // }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !tokenSell.loading &&
      Object.keys(tokenSell.data).length > 0
    ) {
      updateTokenSellData({
        ...tokenSellData,
        step: 3,
      });
    }
    setSkipRender(false);
  }, [tokenSell]);

  return (
    <>
      {adminWallet.loading ? (
        <CommonCenterLoader />
      ) : Object.keys(adminWallet.data).length > 0 ? (
        <div className="bank-account-details-sec">
          <div className="bank-account-details out_space_mx_1_5">
            <div
              className="back-arrow-btn-sec"
              onClick={() => updateTokenSellData({ ...tokenSellData, step: 1 })}
            >
              <Image
                src={
                  window.location.origin + "/img/exchange/back-arrow-icon.svg"
                }
                className="back-arrow-btn"
              />
              <h4>{t("sell_crypto")} </h4>
            </div>
          </div>
          {adminWallet.data.admin_crypto_account.qr_code ? (
            <div className="auth_deposit_qr_scan out_space_mx_1_5">
              <div className="admin-wallet-qr-frame">
                <CustomLazyLoad
                  src={adminWallet.data.admin_crypto_account.qr_code}
                  className="auth_deposit_qr"
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <Formik
            initialValues={
              currencies.data.currencies.find(
                (item) => item.currency_code == tokenSellData.to_currency
              ).currency_type == "forex"
                ? {
                    transaction_hash: "",
                    // user_bank_account_id: "",
                    file: "",
                  }
                : {
                    transaction_hash: "",
                    user_wallet_address: "",
                    file: "",
                  }
            }
            validationSchema={
              currencies.data.currencies.find(
                (item) => item.currency_code == tokenSellData.to_currency
              ).currency_type == "forex"
                ? validationSchema
                : validationAddressSchema
            }
            innerRef={formRef}
            onSubmit={(values) => onSubmit(values)}
          >
            {({ values, touched, errors, setFieldValue }) => (
              <FORM>
                <div className="deposit_modal_form">
                  <div className="sell_address_card  out_space_mx_1_5">
                    <div className="buy-data-card mb-3">
                      <h6>{t("admin_wallet_address")}</h6>
                      <div className="auth_buy_code">
                        <div className="auth_deposit_code_card">
                          <span>
                            {" "}
                            {
                              adminWallet.data.admin_crypto_account
                                .wallet_address
                            }
                          </span>{" "}
                        </div>

                        <CopyToClipboard
                          text={
                            adminWallet.data.admin_crypto_account.wallet_address
                          }
                          onCopy={(e) =>
                            getSuccessNotificationMessage(
                              "Transaction hash copied"
                            )
                          }
                        >
                          <Button className=" action-btn overlay item_center_column p-0">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#298BFF"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              />
                              <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                              <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="deposit_address_field">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>
                          {t("transaction.label")} <span>*</span>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder={t("transaction.placeholder")}
                          name="transaction_hash"
                        />
                        <ErrorMessage
                          component={"div"}
                          name="transaction_hash"
                          className="text-danger text-right"
                        />
                      </Form.Group>
                    </div>
                    {currencies.data.currencies.find(
                      (item) => item.currency_code == tokenSellData.to_currency
                    ).currency_type == "forex" ? null : (
                      <div className="deposit_address_field">
                        <Form.Group className="mb-3">
                          <Form.Label>{t("your_wallet_address")}</Form.Label>
                          <Field
                            type="text"
                            className="form-control"
                            placeholder={t("your_wallet_address")}
                            name="user_wallet_address"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="user_wallet_address"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                      </div>
                    )}
                  </div>
                  <div className="deposit_amount_action item_flex_x_5">
                    <Button
                      className="action-btn primary w-100"
                      type="submit"
                      disabled={tokenSell.buttonDisable}
                    >
                      {tokenSell.buttonDisable ? t("loading") : t("continue")}
                    </Button>
                  </div>
                  <div className="deposit_bank_action">
                    <p className="out_space_mt_1 other_links">
                      {t("by_continuing_you_agree_to_our")}{" "}
                      <Link className="cookie-txt" to="/page/privacy-agreement">
                        {t("cookie_policy")}.
                      </Link>
                    </p>
                  </div>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
      ) : (
        <div className="no-bank-details-sec exchange-btn-sec">
          <CustomLazyLoad
            src={window.location.origin + "/img/no-bank-found.png"}
            className="no-data-found-img"
          />
          <p>{t("no_wallet_details_found")}</p>
          <Button
            className="default-btn w-100"
            onClick={() => dispatch(getAdminWalletStart())}
          >
            {t("retry")}
          </Button>
        </div>
      )}
    </>
  );
};

export default SwapWalletAddressSec;
