import React, { useState, useEffect } from "react";
import { Image, Form, Container, Row, Col } from "react-bootstrap";
import BuyCard from "./BuyCard";
import BuyCrypto from "./BuyCrypto";
import BuyFailure from "./BuyFailure";
import BuyProcessing from "./BuyProcessing";
import BuySuccess from "./BuySuccess";
import WalletAddress from "./WalletAddress";

const ExchangeIndex = (props) => {

    const [step, setStep] = useState(1);

  useEffect(() => {
    setStep(1);
  }, []);


  return (
    <>
      <div className="exchange-sec">
        <Container>
        {step == 1 && (
                <div className="step-1">
                  <BuyCrypto setStep={setStep} />
                </div>
              )}
               {step == 2 && (
                <div className="step-2">
                  <WalletAddress setStep={setStep} />
                </div>
              )}
              {step == 3 && (
                <div className="step-3">
                  <BuyCard setStep={setStep} />
                </div>
              )}
              {step == 4 && (
                <div className="step-4">
                  <BuyProcessing setStep={setStep} />
                </div>
              )}
              {step == 5 && (
                <div className="step-5">
                  <BuyFailure setStep={setStep} />
                </div>
              )}
              {step == 6 && (
                <div className="step-6">
                  <BuySuccess setStep={setStep} />
                </div>
              )}
        </Container>
      </div>
    </>
  );
};

export default ExchangeIndex;
