import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import '../Wallet/Wallet.css';
import AddAccount from './AddAccount';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { useTranslation } from 'react-multi-lang';
import Select from "react-select";
import { addBankAccountStart } from '../../store/actions/BankAccountAction';
import Skeleton from 'react-loading-skeleton';

const EditAccountBank = (props) => {

  const t = useTranslation("bankAccount_create");
  const { accountId } = props;

  const validationSchema = Yup.object().shape({
    bank_name: Yup.string()
      .required(t("bank_name.required")),
    account_holder_name: Yup.string().required(t("account_holder_name.required")),
    account_number: Yup.string()
      .required(t("account_number.required")),
    account_number_confirmation: Yup.string()
      .required(t("account_number_confirmation.required"))
      .when("account_number", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("account_number")],
          t("account_number_confirmation.invalid")
        ),
      }),
    ifsc_code: Yup.string()
      .required(t("ifsc_code.required")),
    account_type: Yup.string()
      .required(t("account_type.required"))
  });

  const handleSubmit = (values) => {
    props.dispatch(
      addBankAccountStart({
        ...values,
        user_bank_account_id: accountId,
      })
    );
  };

  const account_options = [
    { value: "current", label: "Current" },
    { value: "savings", label: "Savings" }
  ]

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "0px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };


  return (
    <>

<Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="new-modal-custome"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{t("edit_account")}</h4>
        {props.viewAccount.loading ?
            <Row>
              <Col md={12}>
                <Skeleton count={5} className='mb-3' height={50} />
              </Col>
            </Row>
            :
            Object.keys(props.viewAccount.data).length > 0 ?
              <Formik
                initialValues={{
                  account_holder_name: props.viewAccount.data.bank_account.account_holder_name,
                  bank_name: props.viewAccount.data.bank_account.bank_name,
                  account_number: props.viewAccount.data.bank_account.account_number,
                  account_number_confirmation: props.viewAccount.data.bank_account.account_number,
                  ifsc_code: props.viewAccount.data.bank_account.ifsc_code,
                  account_type: props.viewAccount.data.bank_account.account_type,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, setFieldTouched, errors }) => (
                  <FORM className="new-feature-form">
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("account_holder_name.label")}</Form.Label>
                      <Field
                        type="text"
                        className="form-control"
                        placeholder={t("account_holder_name.placeholder")}
                        name="account_holder_name"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_holder_name"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("bank_name.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("bank_name.placeholder")}
                        name="bank_name"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="bank_name"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("account_number.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("account_number.placeholder")}
                        name="account_number"
                        type="number"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_number"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("account_number_confirmation.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("account_number_confirmation.placeholder")}
                        name="account_number_confirmation"
                        type="number"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_number_confirmation"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" >
                      <Form.Label>{t("ifsc_code.label")}</Form.Label>
                      <Field
                        className="form-control"
                        placeholder={t("ifsc_code.placeholder")}
                        name="ifsc_code"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="ifsc_code"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>{t("account_type.label")}</Form.Label>
                      <Select
                        options={account_options}
                        styles={customStyles}
                        isisSearchable={false}
                        value={{
                          label: values.account_type.charAt(0).toUpperCase() + values.account_type.slice(1),
                          value: values.account_type,
                        }}
                        onChange={(selectedOption) => {
                          setFieldValue("account_type", selectedOption.value);
                        }}
                        placeholder={t("account_type.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="account_type"
                        className="errorMsg w-100"
                      />
                    </Form.Group>
                    <div className="new-action-frame layout-element">
                      <Button
                        className='new-overlay-btn'
                        onClick={props.onHide}
                      >{t("save_btn.cancel")}
                      </Button>
                      <Button
                        type="submit"
                        className='new-primary-btn'
                        disabled={
                          props.bankAccountSave.buttonDisable
                        }
                      >
                        {props.bankAccountSave.buttonDisable
                          ? t("save_btn.loading")
                          : t("save_btn.text")}
                      </Button>
                    </div>
                  </FORM>
                )}
              </Formik>
              :
              null
          }
      </Modal.Body>
    </Modal>
    </>
  )
};

const mapStateToPros = (state) => ({
  viewAccount: state.bankAccount.viewAccount,
  bankAccountSave: state.bankAccount.addBankAccount,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(EditAccountBank);
