import React, { useEffect, useState } from 'react';
import { Form, Button, Modal } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { connect } from 'react-redux';
import * as Yup from "yup";
import { profileUpdateStart } from "../../store/actions/UserAction";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useTranslation } from 'react-multi-lang';

const EditProfileModal = (props) => {

  const t = useTranslation("profile");
  const validationSchema = Yup.object().shape({
    website_url: Yup.string().required(t("website_url.required"))
      .matches(/((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/, t('website_url.invalid_url')),
  });

  const handleSubmit = (values) => {
    props.dispatch(profileUpdateStart(values));
  };


  return (
    <>
      <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="edit_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="edit_modal_content">
            <div className="edit_title">
              <h2>{t("heading")}</h2>
            </div>
            <div className="edit_form">
              
            </div>
            <div className="edit_action">
              
            </div>
        </div>
        <Formik
            initialValues={{
              name: props.profile.data.name,
              website_url: props.profile.data.website_url,
            }}
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
          >
            <FORM className="new-feature-form">
              <Form.Group
                controlId="formBasicEmail"
                className="mb-0"
              >
                <Form.Label>
                  {t("website_url.label")} <span>*</span>
                </Form.Label>
                <Field
                  type="text"
                  className="form-control"
                  name="website_url"
                  placeholder={t("website_url.placeholder")}
                />
                <ErrorMessage
                  component={"div"}
                  name="website_url"
                  className="text-danger"
                />
              </Form.Group>
              <div className="new-action-frame layout-element">
                <Button
                 className="new-overlay-btn"
                  onClick={props.onHide}>
                  {t("cancel")}
                </Button>
                <Button
                className="new-primary-btn"
                  type="submit"
                  disabled={
                    props.updateProfile.buttonDisable
                  }
                >
                  {props.updateProfile.buttonDisable
                    ? t("loading")
                    : t("save_changes")}
                </Button>
              </div>
            </FORM>
          </Formik>
      </Modal.Body>
    </Modal>

    </>
  )
};

const mapStateToPros = (state) => ({
  profile: state.user.profile,
  updateProfile: state.user.updateProfile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(
  mapStateToPros,
  mapDispatchToProps
)(EditProfileModal);
