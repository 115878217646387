import React from "react";
import TradeNav from "./TradeNav";
import TradingNavFav from "./TradingNavFav";
import "./trading_v2.css";
import { Container } from "react-bootstrap";

const TreadingHeader = () => {
  return (
    <>
    <Container fluid>
      <TradeNav />
      <div className="view-into"></div>
      {/* <TradingNavFav /> */}
      <div className="view-into"></div>
      </Container>
    </>
  );
};

export default TreadingHeader;
