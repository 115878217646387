import React from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
const PageNotFound = () => {
  return (
    <div className="no_data_content item_center_column  text-center">
        <Image
          className="page-error-img"
          src={window.location.origin + "/img/phase_1/data/404-error.svg"}
        />
        <div className="page-found-action">
            <Link to="/" className="action-btn primary">Back to Home</Link>
        </div>
      </div>
  )
}

export default PageNotFound;
