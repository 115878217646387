import React, { useEffect, useState } from "react";
import { Modal, Button, Table } from "react-bootstrap";
import { singleWithdrawTransactionStart } from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import CopyToClipboard from "react-copy-to-clipboard";
import "../Transaction/transaction.css";
import { useTranslation } from "react-multi-lang";
import { useSelector, useDispatch } from "react-redux";
import { getSuccessNotificationMessage } from "../helper/ToastNotification";
import NoDataFound from "../helper/NoDataFound";

const WithdrawTransactionModal = (props) => {
  const { transactionId } = props;
  const dispatch = useDispatch();
  const t = useTranslation("transactions");
  const singleWithdrawTransaction = useSelector(
    (state) => state.buy.singleWithdrawTransaction
  );

  useEffect(() => {
    dispatch(
      singleWithdrawTransactionStart({
        user_withdrawal_id: transactionId,
      })
    );
  }, []);

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {singleWithdrawTransaction.loading ? (
            <>
              <div className="trans-view-grid-layout">
                <Skeleton height={270} />

                <Skeleton height={270} />
              </div>

              <Skeleton height={340} />
            </>
          ) : Object.keys(singleWithdrawTransaction.data).length > 0 ? (
            <div className="transaction-view-card">
              <div className="trans-view-grid-layout">
                <div className="transaction-view-box">
                  <div className="view-box-card">
                    <h5 className="view-label">Withdrawal Amount</h5>
                    <p className="view-amount">
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .user_amount_formatted
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Requested Amount</h5>
                    <p className="view-amount">
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .requested_amount_formatted
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Status</h5>
                    <p
                      className={`status ${
                        singleWithdrawTransaction.data.user_withdrawal.status ==
                        1
                          ? "succes"
                          : "denied"
                      } `}
                    >
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .status_formatted
                      }
                    </p>
                  </div>
                </div>
                <div className="transaction-view-box">
                  {/* <div className="view-box-card">
                    <h5 className="view-label">Exchange Value</h5>
                    <p className="view-value">
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .adjusted_exchange_rate_formatted
                      }
                    </p>
                  </div> */}
                  <div className="view-box-card">
                    <h5 className="view-label">Transaction Date</h5>
                    <p className="view-value">
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .created_at
                      }
                    </p>
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">
                      {singleWithdrawTransaction.data.user_withdrawal
                        .withdraw_type == "crypto"
                        ? "Transaction Hash"
                        : "Transaction Reference Id"}
                    </h5>
                    {singleWithdrawTransaction.data?.user_withdrawal
                      ?.payment_id ||
                    singleWithdrawTransaction.data?.user_withdrawal
                      ?.stylopay_withdrawal_transaction_id ? (
                      <>
                        <p className="view-value transaction-value">
                          {singleWithdrawTransaction.data?.user_withdrawal
                            ?.payment_id !== ""
                            ? singleWithdrawTransaction.data?.user_withdrawal
                                ?.payment_id
                            : singleWithdrawTransaction.data.user_withdrawal
                                .stylopay_withdrawal_transaction_id}

                          <CopyToClipboard
                            text={
                              singleWithdrawTransaction.data?.user_withdrawal
                                ?.payment_id !== ""
                                ? singleWithdrawTransaction.data
                                    ?.user_withdrawal?.payment_id
                                : singleWithdrawTransaction.data.user_withdrawal
                                    .stylopay_withdrawal_transaction_id
                            }
                            onCopy={() =>
                              getSuccessNotificationMessage(
                                singleWithdrawTransaction.data.user_withdrawal
                                  .withdraw_type == "crypto"
                                  ? t(
                                      "transaction_hash_was_copied_to_clipboard"
                                    )
                                  : t("payment_id_was_copied_to_clipboard")
                              )
                            }
                          >
                            <Button className="action-btn copy-btn item_center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#298BFF"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z"></path>
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1"></path>
                              </svg>
                            </Button>
                          </CopyToClipboard>
                        </p>
                      </>
                    ) : singleWithdrawTransaction.data.user_withdrawal
                        .withdraw_type == "crypto" ? (
                      "Transaction Hash not available"
                    ) : (
                      "Transaction Reference Id not available"
                    )}
                  </div>
                  <div className="view-box-card">
                    <h5 className="view-label">Withdraw Type</h5>
                    <p className="view-value">
                      {
                        singleWithdrawTransaction.data.user_withdrawal
                          .withdraw_type
                      }
                    </p>
                  </div>
                  {singleWithdrawTransaction.data.user_withdrawal
                    .wallet_address !== "" && (
                    <div className="view-box-card">
                      <h5 className="view-label">Wallet Address</h5>
                      <p className="view-value">
                        {
                          singleWithdrawTransaction.data.user_withdrawal
                            .wallet_address
                        }
                      </p>
                    </div>
                  )}

                  {singleWithdrawTransaction.data.user_withdrawal
                    .explorer_url &&
                    singleWithdrawTransaction.data.user_withdrawal
                      .explorer_url !== "" && (
                      <div className="view-box-card">
                        <h5 className="view-label">View on Explorer</h5>
                        <Button
                          className="action-btn link-btn"
                          onClick={() => {
                            window.open(
                              singleWithdrawTransaction.data.user_withdrawal
                                .explorer_url,
                              "_blank"
                            );
                          }}
                        >
                          View on Explorer
                        </Button>
                      </div>
                    )}
                  {/* <div className="view-box-card">
                    <h5 className="view-label">Type</h5>
                    <p className="view-value">
                      {singleWithdrawTransaction.data.user_withdrawal
                        .payment_type == "bank"
                        ? "Bank"
                        : singleWithdrawTransaction.data.user_withdrawal
                            .payment_type == "card"
                        ? "Card"
                        : singleWithdrawTransaction.data.user_withdrawal
                            .payment_type == "wallet"
                        ? "Wallet"
                        : "Not available"}
                    </p>
                  </div> */}
                </div>
              </div>
              {singleWithdrawTransaction.data.user_withdrawal.wallet_address ==
                "" && (
                <div className="view-bank-box">
                  <div className="view-bank-head">
                    <h2>Bank Details</h2>
                  </div>
                  <div className="view-bank-titles-card">
                    <div className="view-bank-titles-info">
                      <div className="view-bank-icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="32"
                          height="32"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 24 24"
                        >
                          <path
                            d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                            data-original="#000000"
                          ></path>
                        </svg>
                      </div>
                      <div className="view-bank-info">
                        <p>
                          {
                            singleWithdrawTransaction.data.user_withdrawal
                              ?.account_number
                          }
                        </p>
                      </div>
                    </div>
                    <div className="view-bank-titles-type">
                      <p className="bank-type">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 16 17"
                        >
                          <path
                            stroke="#252525"
                            strokeLinecap="round"
                            strokeWidth="2"
                            d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                          ></path>
                        </svg>
                        <span>
                          {
                            singleWithdrawTransaction.data.user_withdrawal
                              ?.account_holder_name
                          }
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoDataFound />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawTransactionModal;
