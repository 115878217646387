import React, { useState } from "react";
import { useLocation } from "react-router-dom";
const AuthLayout = (props) => {
  const location = useLocation();

  return (
    <>
      <div
        className={`${
          location.pathname == "/onboarding" ? "onboarding-layout" : "auth"
        }`}
      >
        {props.children}
      </div>
    </>
  );
};

export default AuthLayout;
