import React, { useContext, useEffect, useRef, useState } from "react";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import configuration from "react-global-configuration";
import LandingPageIndexOld from "../LandingPage/LandingPageIndexOld";
import MainLayout from "../layouts/MainLayout";
import AuthLayout from "../layouts/AuthLayout";
import { ToastContainer } from "react-toastify";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguage,
} from "react-multi-lang";
import en from "../translations/en.json";
import es from "../translations/es.json";
import ExchangeIndex from "../ExchangeOld/ExchangeIndex";
import SwapIndexOld from "../SwapOld/SwapIndexOld";
import StaticIndex from "../Static/StaticIndex";
import PrivacyPolicy from "../Static/PrivacyPolicy";
import TermsAndConditions from "../Static/TermsAndConditions";
import BuyIndex from "../Buy/BuyIndex";
import SwapIndex from "../Swap/SwapIndex";
import LoginIndex from "../Auth/LoginIndex";
import RegisterIndex from "../Auth/RegisterIndex";
import ProfileIndex from "../Profile/ProfileIndex";
import EditProfileIndex from "../Profile/EditProfileIndex";
import PaymentResponse from "../Buy/PaymentResponse";
import WalletIndex from "../Wallet/WalletIndex";
import BankAccountListIndex from "../BillingAccount/BankAccountListIndex";
import WalletMain from "../Wallet/WalletMain";
import TokenTransactionsIndex from "../TokensTransactions/TokenTransactionsIndex";
import Transactions from "../Transaction/TransactionIndex";
import BeneficiaryAccountsList from "../Beneficiaries/BeneficiaryAccountsList";
import NewLandingIndex from "../NewLanding/NewLandingIndex";
import LandingLayout from "../layouts/LandingLayout";
import SwapField from "../../WidgetComponent/SwapField";
import EmptyLayout from "../layouts/EmptyLayout";
import ScrollToTop from "../helper/ScrollToTop";
import ContactUsPageIndex from "../NewLanding/ContactUsPageIndex";
import RecipientList from "../Recipient/RecipientList";
import RecipientFormCategory from "../Recipient/RecipientFormCategory";
import CreatePersonalRecipient from "../Recipient/CreatePersonalRecipient";
import ViewRecipientDetail from "../Recipient/ViewRecipientDetail";
import CreareBusinessRecipient from "../Recipient/CreateBusinessRecipient";
import OnboardingIndex from "../Onboarding/OnboardingIndex";
import TradingIndex from "../Trading/TradingIndex";
import NoDataFound from "../helper/NoDataFound";
import PageNotFound from "../helper/PageNotFound";

setTranslations({ en, es });

const $ = window.$;

const AppRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => (
  <Layout screenProps={ScreenProps} {...rest}>
    <Component />
  </Layout>
);

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  screenProps: ScreenProps,
  ...rest
}) => {
  let userId =
    localStorage.getItem("userId") || sessionStorage.getItem("userId");
  let accessToken =
    localStorage.getItem("token") || sessionStorage.getItem("token");
  let authentication = accessToken && userId ? true : false;
  return authentication === true ? (
    <Layout screenProps={ScreenProps}>
      <Component authRoute={true} />
    </Layout>
  ) : (
    <Navigate to={{ pathname: "/" }} />
  );
};

const App = () => {
  setLanguage("en");

  useEffect(() => {
    const userLanguage = localStorage.getItem("lang")
      ? localStorage.getItem("lang")
      : "en";
    localStorage.setItem("lang", userLanguage);
    setDefaultLanguage(userLanguage);
  }, []);

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{configuration.get("configData.site_name")}</title>
        <link
          rel="icon"
          type="image/png"
          href={configuration.get("configData.site_icon")}
        />
        <script src={configuration.get("configData.head_scripts")}></script>
      </Helmet>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route
            path={"/"}
            element={
              <AppRoute component={NewLandingIndex} layout={LandingLayout} />
            }
          />
          <Route
            path={"/old"}
            element={
              <AppRoute component={LandingPageIndexOld} layout={MainLayout} />
            }
          />
          <Route
            path={"/buy"}
            element={<AppRoute component={BuyIndex} layout={MainLayout} />}
          />
          <Route
            path={"/sell"}
            element={<AppRoute component={SwapIndex} layout={MainLayout} />}
          />
          <Route
            path={"/exchange"}
            element={<AppRoute component={ExchangeIndex} layout={MainLayout} />}
          />
          <Route
            path={"/swap-old"}
            element={<AppRoute component={SwapIndexOld} layout={MainLayout} />}
          />
          <Route
            path={"/page/:static_page_unique_id"}
            element={<AppRoute component={StaticIndex} layout={MainLayout} />}
          />
          <Route
            path={"/privacy-policy"}
            element={<AppRoute component={PrivacyPolicy} layout={MainLayout} />}
          />
          \
          <Route
            path={"/terms-conditions"}
            element={
              <AppRoute component={TermsAndConditions} layout={MainLayout} />
            }
          />
          <Route
            path={"/login"}
            element={<AppRoute component={LoginIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/register"}
            element={<AppRoute component={RegisterIndex} layout={AuthLayout} />}
          />
          <Route
            path={"/profile"}
            element={
              <PrivateRoute component={ProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/edit-profile"}
            element={
              <PrivateRoute component={EditProfileIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/success"}
            element={
              <PrivateRoute component={PaymentResponse} layout={MainLayout} />
            }
          />
          <Route
            path={"/fail"}
            element={
              <PrivateRoute component={PaymentResponse} layout={MainLayout} />
            }
          />
          <Route
            path={"/transaction-history"}
            element={
              <PrivateRoute
                component={TokenTransactionsIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/wallet-currency-list"}
            element={
              <PrivateRoute component={WalletMain} layout={MainLayout} />
            }
          />
          <Route
            path={"/wallet/:user_wallet_id"}
            element={
              <PrivateRoute component={WalletIndex} layout={MainLayout} />
            }
          />
          <Route
            path={"/bank-accounts"}
            element={
              <PrivateRoute
                component={BankAccountListIndex}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/transactions"}
            element={
              <PrivateRoute component={Transactions} layout={MainLayout} />
            }
          />
          <Route
            path={"/beneficiaries"}
            element={
              <PrivateRoute
                component={BeneficiaryAccountsList}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/recipient-list"}
            element={
              <PrivateRoute component={RecipientList} layout={MainLayout} />
            }
          />
          <Route
            path={"/select-recipient-type"}
            element={
              <PrivateRoute
                component={RecipientFormCategory}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/create-business-recipient"}
            element={
              <PrivateRoute
                component={CreareBusinessRecipient}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/create-personal-recipient"}
            element={
              <PrivateRoute
                component={CreatePersonalRecipient}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/recipient-detail"}
            element={
              <PrivateRoute
                component={ViewRecipientDetail}
                layout={MainLayout}
              />
            }
          />
          <Route
            path={"/contact-us"}
            element={
              <AppRoute component={ContactUsPageIndex} layout={LandingLayout} />
            }
          />
          <Route
            path={"/widget"}
            element={<AppRoute component={SwapField} layout={EmptyLayout} />}
          />
          <Route
            path={"/onboarding"}
            element={
              <PrivateRoute component={OnboardingIndex} layout={AuthLayout} />
            }
          />
          <Route
            path={"/trading"}
            element={<AppRoute component={TradingIndex} layout={EmptyLayout} />}
          />
          <Route
            path={"/*"}
            element={<AppRoute component={PageNotFound} layout={EmptyLayout} />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
