import React, { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { useTranslation } from "react-multi-lang";
import useLogout from "../layouts/Header/useLogout";
import { logoutStart } from "../../store/actions/UserAction";
import { useSelector, useDispatch } from "react-redux";
import { ButtonLoader } from "../helper/Loader";
import { useNavigate } from "react-router-dom";

const LogoutModal = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("profile");
  const logout = useSelector((state) => state.user.logout);
  const [skipRender, setSkipRender] = useState(true);
  const onSubmit = () => {
    dispatch(logoutStart());
  };
  useEffect(() => {
    if (!skipRender && !logout.loading && Object.keys(logout.data).length > 0) {
      localStorage.clear();
      sessionStorage.clear();
      localStorage.setItem("lang", "en");
      navigate("/login");
    }
    setSkipRender(false);
  }, [logout]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="new-modal-custome"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4> {t("are_you_sure_you_want_to_loout")}</h4>
          <div className="new-action-frame layout-element">
            <Button className="new-overlay-btn" onClick={props.onHide}>
              {t("cancel")}
            </Button>
            <Button className="new-primary-btn" onClick={onSubmit}>
              {logout.buttonDisable ? <ButtonLoader /> : t("logout")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutModal;
