import React, { useEffect, useState } from "react";
import { Form, Button, Modal, InputGroup } from "react-bootstrap";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "../Wallet/Wallet.css";
import { useTranslation } from "react-multi-lang";
import { connect } from "react-redux";
import { changePasswordStart } from "../../store/actions/UserAction";

const ChangePasswordModal = (props) => {
  const t = useTranslation("change_password");
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);

  const validationSchema = Yup.object().shape({
    old_password: Yup.string()
      .min(6, t("password.min_length_invalid"))
      .max(16, t("password.max_length_invalid"))
      .required(t("password.required")),
    password: Yup.string()
      .required(t("new_password.required"))
      .test(
        "complexity",
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
        (value) => {
          if (!value) return false; // Skip validation if value is not provided

          const hasUppercase = /[A-Z]/.test(value);
          const hasLowercase = /[a-z]/.test(value);
          const hasNumber = /[0-9]/.test(value);
          const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(value);

          return hasUppercase && hasLowercase && hasNumber && hasSpecialChar;
        }
      )
      .min(8, t("new_password.min_length_invalid"))
      .max(16, t("new_password.max_length_invalid"))
      .when("old_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().notOneOf(
          [Yup.ref("old_password")],
          t("new_password.new_password_invalid")
        ),
      }),
    password_confirmation: Yup.string()
      .required(t("confirm_password.required"))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("confirm_password.same_password_invalid")
        ),
      }),
  });

  const handleSubmit = (values) => {
    props.dispatch(changePasswordStart(values));
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="change_password_modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="change_password_content">
            <div className="change_password_titles  out_space_mx_1_5">
              <h2>{t("heading")}</h2>
            </div>
            <Formik
              initialValues={{
                old_password: "",
                password: "",
                password_confirmation: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue }) => (
                <FORM>
                  <div className="change_password_form  out_space_mx_1_5">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("password.label")}
                        {""} <span>*</span>
                      </Form.Label>
                      <InputGroup>
                        <Field
                          name="old_password"
                          className="form-control"
                          type={`${oldPasswordVisible ? "text" : "password"}`}
                          autoFocus={true}
                          placeholder={t("password.placeholder")}
                        />

                        <InputGroup.Text
                          id="basic-addon2"
                          onClick={() =>
                            setOldPasswordVisible(!oldPasswordVisible)
                          }
                        >
                          {oldPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#DDE1E6"
                                d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#DDE1E6"
                                d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                              ></path>
                            </svg>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="old_password"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("new_password.label")}
                        {""} <span>*</span>
                      </Form.Label>

                      <Field
                        name="password"
                        className="form-control"
                        type="password"
                        placeholder={t("new_password.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="password"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        {t("confirm_password.label")}
                        {""} <span>*</span>
                      </Form.Label>

                      <InputGroup>
                        <Field
                          name="password_confirmation"
                          className="form-control"
                          type={confirmPasswordVisible ? "text" : "password"}
                          placeholder={t("confirm_password.placeholder")}
                        />

                        <InputGroup.Text
                          id="basic-addon2"
                          onClick={() =>
                            setConfirmPasswordVisible(!confirmPasswordVisible)
                          }
                        >
                          {confirmPasswordVisible ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#DDE1E6"
                                d="M12 16.33c-2.39 0-4.33-1.94-4.33-4.33S9.61 7.67 12 7.67s4.33 1.94 4.33 4.33-1.94 4.33-4.33 4.33zm0-7.16c-1.56 0-2.83 1.27-2.83 2.83s1.27 2.83 2.83 2.83 2.83-1.27 2.83-2.83S13.56 9.17 12 9.17z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M12 21.02c-3.76 0-7.31-2.2-9.75-6.02-1.06-1.65-1.06-4.34 0-6 2.45-3.82 6-6.02 9.75-6.02s7.3 2.2 9.74 6.02c1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02zm0-16.54c-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38 2.16 3.39 5.25 5.33 8.48 5.33 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-2.16-3.39-5.25-5.33-8.48-5.33z"
                              ></path>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#DDE1E6"
                                d="M9.47 15.28c-.19 0-.38-.07-.53-.22A4.305 4.305 0 017.67 12c0-2.39 1.94-4.33 4.33-4.33 1.15 0 2.24.45 3.06 1.27a.75.75 0 010 1.06L10 15.06c-.15.15-.34.22-.53.22zM12 9.17a2.834 2.834 0 00-2.46 4.23l3.86-3.86c-.42-.24-.9-.37-1.4-.37z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M5.6 18.51c-.17 0-.35-.06-.49-.18-1.07-.91-2.03-2.03-2.85-3.33-1.06-1.65-1.06-4.34 0-6C4.7 5.18 8.25 2.98 12 2.98c2.2 0 4.37.76 6.27 2.19a.75.75 0 01-.9 1.2c-1.64-1.24-3.5-1.89-5.37-1.89-3.23 0-6.32 1.94-8.48 5.33-.75 1.17-.75 3.21 0 4.38s1.61 2.18 2.56 3c.31.27.35.74.08 1.06-.14.17-.35.26-.56.26zM12 21.02c-1.33 0-2.63-.27-3.88-.8a.75.75 0 01-.4-.98c.16-.38.6-.56.98-.4 1.06.45 2.17.68 3.29.68 3.23 0 6.32-1.94 8.48-5.33.75-1.17.75-3.21 0-4.38-.31-.49-.65-.96-1.01-1.4a.76.76 0 01.11-1.06.75.75 0 011.06.11c.39.48.77 1 1.11 1.54 1.06 1.65 1.06 4.34 0 6-2.44 3.82-5.99 6.02-9.74 6.02z"
                              ></path>
                              <path
                                fill="#DDE1E6"
                                d="M12.69 16.27c-.35 0-.67-.25-.74-.61-.08-.41.19-.8.6-.87 1.1-.2 2.02-1.12 2.22-2.22.08-.41.47-.67.88-.6.41.08.68.47.6.88-.32 1.73-1.7 3.1-3.42 3.42-.05-.01-.09 0-.14 0zM2 22.75c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06L8.94 14c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06l-7.47 7.47c-.15.15-.34.22-.53.22zM14.53 10.22c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l7.47-7.47c.29-.29.77-.29 1.06 0 .29.29.29.77 0 1.06L15.06 10c-.15.15-.34.22-.53.22z"
                              ></path>
                            </svg>
                          )}
                        </InputGroup.Text>
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="password_confirmation"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </div>

                  <div className="change_password_action item_flex_x_5">
                    <Button
                      className="action-btn overlay w-100"
                      onClick={props.onHide}
                      disabled={props.changePassword.buttonDisable}
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      className="action-btn primary w-100"
                      type="submit"
                      disabled={props.changePassword.buttonDisable}
                    >
                      {props.changePassword.buttonDisable
                        ? t("save changes_btn.loading")
                        : t("save changes_btn.text")}
                    </Button>
                  </div>
                </FORM>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToPros = (state) => ({
  changePassword: state.user.changePassword,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(ChangePasswordModal);
