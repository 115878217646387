import React, { useEffect, useState, useRef } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Link, useParams } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getBankAccountStart } from "../../store/actions/BankAccountAction";
import CommonCenterLoader from "../helper/CommonCenterLoader";
import { useTranslation } from "react-multi-lang";
import { withdrawalRequestStart } from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { recipientListStart } from "../../store/actions/RecipientAction";
import { generateQuoteStart } from "../../store/actions/BuyAction";
import Select from "react-select";
import TransactionSuccess from "../helper/TransactionSuccess";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";

const WalletWithdrawModal = (props) => {
  const formref = useRef();
  const params = useParams();
  const t = useTranslation("withdraw_modal");
  const validationSchema = Yup.object().shape({
    stylopay_recipient_id: Yup.string().required(t("bank_account.required")),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(0.0000001, t("amount.invalid"))
      .max(parseFloat(props.show).toFixed(8), t("amount.max_invalid")),
  });
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState("");
  const [id, setId] = useState("");
  const [bank, setBank] = useState(null);
  const dispatch = useDispatch();
  const bankAccountList = useSelector((state) => state.bankAccount.bankAccount);
  const withdrawalRequest = useSelector((state) => state.buy.withdrawalRequest);
  const currencies = useSelector((state) => state.buy.currencies);
  const generateQuote = useSelector((state) => state.buy.generateQuote);
  const [skipRender, setSkipRender] = useState(true);
  const recipientList = useSelector((state) => state.recipient.recipientList);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [rangeValue, setRangeValue] = useState(0);

  const handleSubmit = (values) => {
    setAmount(values.amount);
    dispatch(
      generateQuoteStart({
        ...values,
        type: "forex",
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  const handleSubmitWithDrawal = (values) => {
    dispatch(
      withdrawalRequestStart({
        user_withdrawal_id:
          generateQuote.data?.user_withdrawal?.user_withdrawal_id,
      })
    );
  };

  useEffect(() => {
    dispatch(recipientListStart({ currency: props.currency }));
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !withdrawalRequest.loading &&
      Object.keys(withdrawalRequest.data).length > 0
    ) {
      setTransactionSuccess(true);
    }
    setSkipRender(false);
  }, [withdrawalRequest]);

  useEffect(() => {
    if (
      !skipRender &&
      !generateQuote.loading &&
      Object.keys(generateQuote.data).length > 0
    ) {
      setStep(2);
    }
    setSkipRender(false);
  }, [generateQuote]);
  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #E5EAF4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="crypto_modal"
        backdrop="static"
      >
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="crypto_modal_content">
              {step == 1 ? (
                recipientList.loading ? (
                  <CommonCenterLoader />
                ) : Object.keys(recipientList.data).length > 0 &&
                  recipientList.data.total_stylopay_recipients > 0 ? (
                  <>
                    <Formik
                      initialValues={{
                        amount: amount,
                        stylopay_recipient_id: id,
                      }}
                      innerRef={formref}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ touched, errors, setFieldValue, values }) => (
                        <>
                          <div className="crypto_modal_titles out_space_mx_1_5">
                            <h4>
                              {" "}
                              {t("heading")} {`(${props.currency})`}
                            </h4>
                          </div>
                          <div className="crypto_modal_form">
                            <FORM>
                              <div className="crypto_amount_input out_space_mx_1_5">
                                <div className="crypto_amount_field margin-btm-sm">
                                  <Field
                                    type="number"
                                    name="amount"
                                    placeholder={`Enter the Amount in ${props.amount.slice(
                                      -4
                                    )}`}
                                    onChange={(e) => {
                                      const newValue = e.target.value;
                                      const remainingOriginal = props.show || 0;

                                      const percentage =
                                        (parseFloat(newValue) /
                                          remainingOriginal) *
                                        100;
                                      setRangeValue(
                                        isNaN(percentage)
                                          ? 0
                                          : Math.min(percentage, 100).toFixed(2)
                                      );
                                      setFieldValue("amount", newValue);
                                    }}
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="amount"
                                    className="text-danger text-right"
                                  />
                                </div>
                                <Form.Group
                                  controlId="exampleForm.ControlInput1"
                                  className="trade-percent-range mt-2 mb-2"
                                >
                                  {/* <div className="range-label">
                      <span>{rangeValue}%</span>
                      <span>100%</span>
                    </div> */}
                                  <Form.Range
                                    disabled={!props.show || props.show <= 0}
                                    min="0"
                                    max="100"
                                    value={rangeValue}
                                    onChange={(e) => {
                                      const data =
                                        (parseFloat(e.target.value) / 100) *
                                        props.show;
                                      setRangeValue(e.target.value);
                                      setFieldValue("amount", data.toFixed(8));
                                    }}
                                  />
                                  <div className="trade-range-value">
                                    {rangeValue} % / 100 %
                                  </div>
                                </Form.Group>
                                <div className="crypto_amount-bls">
                                  <h5>
                                    Balance = <span> {props.amount}</span>
                                  </h5>
                                </div>
                              </div>
                              <div className="crypto_address_card out_space_mx_1_5">
                                <div className="crypto_address_field">
                                  <Form.Group className="mb-3">
                                    <Form.Label>
                                      {t("bank_account.label")}
                                    </Form.Label>
                                    <Select
                                      styles={customStyles}
                                      value={bank}
                                      options={recipientList.data.stylopay_recipients.map(
                                        (account, i) => ({
                                          label: `${account.first_name} -
                                            ${account.account_number}`,
                                          value: account.id,
                                        })
                                      )}
                                      onChange={(data) => {
                                        setFieldValue(
                                          "stylopay_recipient_id",

                                          data.value
                                        );
                                        setBank(data);
                                      }}
                                      placeholder={
                                        <div className="placeholder-flex">
                                          Select Bank Account
                                        </div>
                                      }
                                    />

                                    <ErrorMessage
                                      component={"div"}
                                      name="stylopay_recipient_id"
                                      className="text-danger text-right"
                                    />
                                  </Form.Group>
                                </div>
                                <div className="crypto_address_info ">
                                  <h5 className="crypto_service_info out_space_mx_1">
                                    incl. {t("service_fee")} ={" "}
                                    <span>
                                      {!isNaN(
                                        values.amount *
                                          (currencies.data?.forex_currencies?.find(
                                            (item) =>
                                              item.currency_code ==
                                              props?.currencyCode
                                          )?.withdraw_commission /
                                            100)
                                      )
                                        ? (
                                            values.amount *
                                            (currencies.data?.forex_currencies?.find(
                                              (item) =>
                                                item.currency_code ==
                                                props?.currencyCode
                                            )?.withdraw_commission /
                                              100)
                                          ).toFixed(7)
                                        : "0.00"}
                                    </span>
                                  </h5>
                                  <h5 className="crypto_token_info">
                                    {t("user_receive_tokens")}={" "}
                                    <span>
                                      {!isNaN(
                                        values.amount -
                                          values.amount *
                                            (currencies.data?.forex_currencies?.find(
                                              (item) =>
                                                item.currency_code ==
                                                props?.currencyCode
                                            )?.withdraw_commission /
                                              100)
                                      )
                                        ? (
                                            values.amount -
                                            values.amount *
                                              (currencies.data?.forex_currencies?.find(
                                                (item) =>
                                                  item.currency_code ==
                                                  props?.currencyCode
                                              )?.withdraw_commission /
                                                100)
                                          ).toFixed(7)
                                        : "0.00"}
                                    </span>
                                  </h5>
                                </div>
                              </div>
                              <div className="crypto_action item_flex_x_5">
                                <Button
                                  className="action-btn overlay w-100"
                                  onClick={props.onHide}
                                  disabled={generateQuote.buttonDisable}
                                >
                                  {t("cancel")}
                                </Button>
                                <Button
                                  type="submit"
                                  className="action-btn primary w-100"
                                  disabled={generateQuote.buttonDisable}
                                >
                                  {generateQuote.buttonDisable
                                    ? t("loading")
                                    : t("generate_quote")}
                                </Button>
                              </div>
                            </FORM>
                          </div>
                        </>
                      )}
                    </Formik>
                  </>
                ) : (
                  <div className="add_bank_frame">
                    <div className="add_bank_avater">
                      <Image
                        className="add_bank_img"
                        src={
                          window.location.origin +
                          "/img/phase_1/wallet/add-bank-img.svg"
                        }
                        alt="onboard"
                      />
                    </div>

                    <div className="withdraw-add-other-account-wrap">
                      <Link
                        to="/create-personal-recipient"
                        className="withdraw-add-other-account-area"
                      >
                        <span>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#4495d3"
                              d="M480 224H288V32c0-17.673-14.327-32-32-32s-32 14.327-32 32v192H32c-17.673 0-32 14.327-32 32s14.327 32 32 32h192v192c0 17.673 14.327 32 32 32s32-14.327 32-32V288h192c17.673 0 32-14.327 32-32s-14.327-32-32-32z"
                              data-original="#000000"
                            ></path>
                          </svg>
                        </span>
                        <p>{t("add_bank_account")}</p>
                      </Link>
                    </div>
                  </div>
                )
              ) : (
                <>
                  <div className="crypto_address_card out_space_mx_1_5">
                    {/* <div className="crypto_address_field">
                      <Form.Group className="mb-3">
                          <Form.Label>{t("bank_account.label")}</Form.Label>
                          <Select
                          styles={customStyles}
                          options={recipientList.data.stylopay_recipients.map(
                            (account, i) => (
                              <option key={i} value={account.id}>
                                {account.first_name} -{" "}
                                {account.account_number}
                              </option>
                            )
                          )}
                          onChange={(e) =>
                            setFieldValue(
                              "stylopay_recipient_id",

                              e.target.value
                            )
                          }
                          placeholder={
                            <div className="placeholder-flex">
                             Select Bank Account
                            </div>
                          }
                        />
                         
                          <ErrorMessage
                            component={"div"}
                            name="stylopay_recipient_id"
                            className="text-danger text-right"
                          />
                        </Form.Group>
                        </div> */}
                    <div className="crypto_address_info ">
                      <h5 className="crypto_service_info out_space_mx_1">
                        Requested Amount ={" "}
                        <span>
                          {
                            generateQuote.data.user_withdrawal
                              .requested_amount_formatted
                          }
                        </span>{" "}
                      </h5>
                      <h5 className="crypto_token_info out_space_mx_1">
                        {" "}
                        Recipient will receive ={" "}
                        <span>
                          {" "}
                          {
                            generateQuote.data.user_withdrawal
                              .user_amount_formatted
                          }
                        </span>
                      </h5>
                      <h5 className="crypto_token_info">
                        {" "}
                        Requested at ={" "}
                        <span>
                          {" "}
                          {generateQuote.data.user_withdrawal.created_at}
                        </span>
                      </h5>
                    </div>
                  </div>
                  <div className="crypto_action item_flex_x_5">
                    <Button
                      className="action-btn overlay w-100"
                      onClick={props.onHide}
                      disabled={
                        withdrawalRequest.buttonDisable ||
                        generateQuote.buttonDisable
                      }
                    >
                      {t("cancel")}
                    </Button>
                    <Button
                      className="action-btn overlay w-100"
                      onClick={() => {
                        setId(bank.value);
                        setStep(1);
                      }}
                      disabled={
                        withdrawalRequest.buttonDisable ||
                        generateQuote.buttonDisable
                      }
                    >
                      {t("back")}
                    </Button>
                    <Button
                      onClick={() => handleSubmitWithDrawal()}
                      className="action-btn primary w-100"
                      disabled={withdrawalRequest.buttonDisable}
                    >
                      {withdrawalRequest.buttonDisable
                        ? t("loading")
                        : t("withdraw")}
                    </Button>
                  </div>
                </>
              )}
            </div>
          ) : (
            <TransactionSuccess
              message="Withdraw Intiated Successfully"
              onHide={onHide}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletWithdrawModal;
