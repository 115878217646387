import React from "react";

const AllStock = (props) => {
  return (
    <div className="trade-stock-list-table">
      <div className="stock-list-table-head">
        <div className="stock-list-table-heading">
          Price({props?.currencyPair?.currency2})
        </div>
        <div className="stock-list-table-heading">
          Amount({props?.currencyPair?.currency1})
        </div>
        {/* <div className="stock-list-table-heading">Time</div> */}
      </div>
      <div className="trade-stock-list-box trade-h">
        {props?.buyTrade?.length > 0 &&
          props.buyTrade.map((data) => (
            <div className="trade-stock-row">
              <div className="trade-stock-col profit">{data.price} </div>
              <div className="trade-stock-col">{data.value} </div>
              {/* <div className="trade-stock-col">15:30:13 </div> */}
            </div>
          ))}
      </div>
      <div className="stock-table-status">
        <div className="stock-status-profit">
          <div className="stock-status-total-info"></div>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="18"
            enableBackground="new 0 0 512 512"
            viewBox="0 0 24 24"
          >
            <path
              fill="#25a750"
              d="M17.71 9.88l-4.3-4.29a2 2 0 00-2.82 0l-4.3 4.29a1 1 0 000 1.41 1 1 0 001.42 0L11 8v11a1 1 0 002 0V8l3.29 3.29a1 1 0 101.42-1.41z"
              data-original="#000000"
            ></path>
          </svg> */}
        </div>
        <div className="stock-status-total-text"></div>
      </div>
      <div className="trade-stock-list-box trade-h">
        {props?.sellTrade?.length > 0 &&
          props?.sellTrade.map((data) => (
            <div className="trade-stock-row">
              <div className="trade-stock-col loss">{data.price} </div>
              <div className="trade-stock-col">{data.value} </div>
              {/* <div className="trade-stock-col">17:55:39 </div> */}
            </div>
          ))}
      </div>
      {/* <div className="trade-level">
        <div className="trade-level-profit">
          <span>B</span>
          <div className="trade-level-profit-info">65.06% </div>
        </div>
        <div className="trade-level-loss">
          <div className="trade-level-profit-info">35.94%</div>
          <span>S</span>
        </div>
      </div> */}
    </div>
  );
};

export default AllStock;
