import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { singleRecipientStart } from "../../store/actions/RecipientAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { countriesData } from "./CountriesList";
import { useTranslation } from "react-multi-lang";

const ViewRecipientDetail = () => {
  const t = useTranslation("recipient.recipient_view");
  const dispatch = useDispatch();
  const singleRecipient = useSelector(
    (state) => state.recipient.singleRecipient
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [recipient, setRecipient] = useState([]);

  useEffect(() => {
    dispatch(singleRecipientStart({ stylopay_recipient_id: location.state }));
  }, []);

  useEffect(() => {
    if (
      !singleRecipient.loading &&
      Object.keys(singleRecipient.data).length > 0
    ) {
      setRecipient([singleRecipient.data.stylopay_recipient]);
    }
  }, [singleRecipient]);

  return (
    <>
      <div className="wallet-full-transaction-table">
        <Container>
          <Row className="justify-content-center">
            <Col md={8}>
              {singleRecipient.loading ? (
                <>
                  <div className="create-recipient-form-back">
                    <Skeleton height={30} width={200} borderRadius={5} />
                  </div>
                  <div className="recipient-detail-box">
                    <Skeleton height={600} width={900}  borderRadius={10} />
                  </div>
                </>
              ) : Object.keys(singleRecipient.data).length > 0 ? (
                <>
                  <div className="create-recipient-form-back">
                    <Link onClick={() => navigate(-1)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          stroke="#171717"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                          d="M9.57 5.93L3.5 12l6.07 6.07M20.5 12H3.67"
                        ></path>
                      </svg>
                    </Link>
                    <h2>Bank Account Details</h2>
                  </div>
                  {recipient.map((recipient, index) => (
                    <>
                      <Row>
                        <Col md={12} lg={12} xl={12} >
                          <div className="bank-details-frame">
                            <div className="bank-details-info-card">
                              <div className="bank-details-info-head">
                                <div className="bank-details-name-card">
                                  <div className="bank-details-icons-info">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="32"
                                      height="32"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 24 24"
                                    >
                                      <path
                                        d="M24 23a1 1 0 01-1 1H1a1 1 0 010-2h22a1 1 0 011 1zM.291 8.552a2.443 2.443 0 01.153-2.566 4.716 4.716 0 011.668-1.5L9.613.582a5.174 5.174 0 014.774 0l7.5 3.907a4.716 4.716 0 011.668 1.5 2.443 2.443 0 01.153 2.566A2.713 2.713 0 0121.292 10H21v8h1a1 1 0 010 2H2a1 1 0 010-2h1v-8h-.292A2.713 2.713 0 01.291 8.552zM5 18h3v-8H5zm5-8v8h4v-8zm9 0h-3v8h3zM2.063 7.625A.717.717 0 002.708 8h18.584a.717.717 0 00.645-.375.452.452 0 00-.024-.5 2.7 2.7 0 00-.949-.864l-7.5-3.907a3.176 3.176 0 00-2.926 0l-7.5 3.907a2.712 2.712 0 00-.949.865.452.452 0 00-.026.499z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </div>
                                  <div className="bank-details-name-info">
                                    <h3>{recipient.first_name} {recipient.last_name}</h3>
                                    <p>{recipient.bank_id}</p>
                                  </div>
                                </div>
                                <div className="bank-details-type-card">
                                  <p className="bank-type">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      fill="none"
                                      viewBox="0 0 16 17"
                                    >
                                      <path
                                        stroke="#252525"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                        d="M1.333 15.813V15a4.875 4.875 0 014.875-4.875h3.25A4.875 4.875 0 0114.333 15v.813M7.833 7.688a3.25 3.25 0 110-6.5 3.25 3.25 0 010 6.5z"
                                      ></path>
                                    </svg>
                                    <span>{recipient.account_type}</span>

                                  </p>
                                  <h5> {recipient.account_number}</h5>
                                </div>
                              </div>
                            </div>
                            <div className="bank-personal-details-card">
                            <div className="personal-user-id">
                                <h4>
                                  User Id :
                                  <strong>
                                    {recipient.unique_id}
                                  </strong>
                                </h4>
                              </div>
                              <div className="personal-user-titles">
                                <h2>
                                  Beneficiary
                                </h2>
                              </div>
                              <div className=" benefi-user-info-card">
                                <div className="user-info-card">
                                  <h3> {recipient.ifsc_code == null
                                    ? t("iban_code")
                                    : t("ifsc_code")}</h3>
                                  <h5>{recipient.ifsc_code == null
                                    ? recipient.iban
                                    : recipient.ifsc_code}</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("receiving_currency")}</h3>
                                  <h5>{recipient.currency}</h5>
                                </div>
                              </div>

                              <div className="user-info-space"></div>
                              
                              <div className="personal-user-titles">
                                <h2>
                                  Personal Details
                                </h2>
                              </div>
                              <div className="personal-user-info-card">
                                <div className="user-info-card">
                                  <h3>{t("email")}</h3>
                                  <h5>{recipient.email}</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("phone_number")}</h3>
                                  <h5>{recipient.mobile}</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("country")}</h3>
                                  <h5> {
                                    countriesData.find(
                                      (country) => country.code === recipient.country
                                    ).name
                                  }</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("state")}</h3>
                                  <h5>{recipient.state}</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("city")}</h3>
                                  <h5>{recipient.city}</h5>
                                </div>
                                <div className="user-info-card">
                                  <h3>{t("zip_code")}</h3>
                                  <h5>{recipient.postal_code}</h5>
                                </div>
                              </div>
                              <div className="user-info-space"></div>
                              <div className="personal-user-info-address">
                                <div className="user-info-card">
                                  <h3>{t("full_address")}</h3>
                                  <h5>{recipient.address_line_1},{" "}
                                    {recipient.address_line_2},{recipient.city},{" "}
                                    {recipient.state}, {recipient.postal_code}</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                    </>
                  ))}
                </>
              ) : (
                <NoDataFound />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ViewRecipientDetail;
