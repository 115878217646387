import React from "react";
import { Image, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateSwapData } from "../../store/actions/UserAction";
import { useEffect } from "react";
import { useState } from "react";
import configuration from "react-global-configuration";
import { getErrorNotificationMessage } from "../helper/ToastNotification";
import { useTranslation } from "react-multi-lang";
import {
  getCurrenciesListStart,
  setTokenSellData,
} from "../../store/actions/BuyAction";
import Select from "react-select";
import Skeleton from "react-loading-skeleton";

const ExchangeRate = ({ redirect = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("exchange_index");
  const currencies = useSelector((state) => state.buy.currencies);
  const swapData = useSelector((state) => state.user.swapData);
  const setSwapData = (data) => dispatch(updateSwapData(data));
  const tokenSellData = useSelector((state) => state.buy.tokenSellData);
  const updateTokenSellData = (data) => dispatch(setTokenSellData(data));

  const [errorMsg, setErrorMsg] = useState("");
  const [categoriesOptions, setCategoriesOptions] = useState([]);
  const [forexOptions, setForexOptions] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [selectedForex, setSelectedForex] = useState(null);

  useEffect(() => {
    dispatch(getCurrenciesListStart());
  }, []);

  const onTokenChange = (value) => {
    if (Number(value) == value) {
      updateTokenSellData({
        ...tokenSellData,
        tokens: value,
      });
    }
  };

  const onComplete = () => {
    if (redirect && !tokenSellData.tokens) {
      if (tokenSellData.step == 6) {
        updateTokenSellData({
          ...tokenSellData,
          tokens: tokenSellData.tokens,
          from_currency: tokenSellData.from_currency,
          to_currency: tokenSellData.to_currency,
        });
      }
      navigate("/sell");
    } else if (redirect && tokenSellData.tokens) {
      updateTokenSellData({ ...tokenSellData, step: 2 });
      navigate("/sell");
    } else if (
      (!sessionStorage.getItem("userId") || !sessionStorage.getItem("token")) &&
      (!localStorage.getItem("userId") || !localStorage.getItem("token"))
    ) {
      navigate("/login");
    } else {
      tokenSellData.tokens > 0
        ? updateTokenSellData({
            ...tokenSellData,
            step: tokenSellData.step + 1,
          })
        : getErrorNotificationMessage("Please enter valid amount");
    }
  };

  useEffect(() => {
    if (!currencies.loading && Object.keys(currencies.data).length > 0) {
      const categories = currencies.data.crypto_currencies.map((item) => ({
        label: item.currency_code,
        value: item.id,
      }));
      setCategoriesOptions(categories);

      const selected_crypto = tokenSellData.token_type
        ? currencies.data.crypto_currencies.find(
            (category) => category.currency_code == tokenSellData.token_type
          )
        : currencies.data.crypto_currencies[0];

      setSelectedCrypto(
        tokenSellData.from_currency
          ? categories.find(
              (category) => category.label == tokenSellData.from_currency
            )
          : categories[0]
      );

      const forexCurrency = currencies.data.currencies
        .filter((currency) => currency.id != selected_crypto?.id)
        .map((currency) => ({
          label: currency.currency_code,
          value: currency.id,
        }));

      const selected_currency = tokenSellData.token_type
        ? currencies.data.currencies.find(
            (category) => category.currency_code == tokenSellData.token_type
          )
        : currencies.data.currencies.filter(
            (currency) => currency.id != selected_crypto?.id
          )[0];

      setForexOptions(forexCurrency);
      setSelectedForex(
        tokenSellData.to_currency
          ? forexCurrency.find(
              (forex) => forex.label == tokenSellData.to_currency
            )
          : forexCurrency[0]
      );
      updateTokenSellData({
        ...tokenSellData,
        from_currency: selected_crypto.currency_code,
        to_currency: selected_currency.currency_code,
        from_exchange_rate: selected_crypto?.exchange_rate,
        from_source_currency: selected_crypto?.source_currency,
        to_exchange_rate: selected_currency?.exchange_rate,
        to_source_currency: selected_currency?.source_currency,
      });
    }
  }, [currencies]);

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 200,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "8px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid transparent!important",
      borderRadius: "40px!important",
      boxShadow: "none!important",
      height: "42px",
      minWidth: "100px",
      cursor: "pointer",
      fontSize: "1em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#111",
      fontSize: "1.1em",
      fontWeight: "600",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#298bff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#298bff!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#298bff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#fffaf0" : "#fff",
        color: "#000",
      };
    },
  };

  const onCryptoChange = (selectedOption) => {
    const crypto = currencies.data.crypto_currencies.find(
      (crypto) => crypto.id == selectedOption.value
    );
    updateTokenSellData({
      ...tokenSellData,
      from_currency: selectedOption.label,
      from_exchange_rate: crypto.exchange_rate,
      from_source_currency: crypto.source_currency,
    });
    setSelectedCrypto(selectedOption);
  };

  const onCurrencyChange = (selectedOption) => {
    const forex = currencies.data.currencies.find(
      (forex) => forex.id == selectedOption.value
    );
    updateTokenSellData({
      ...tokenSellData,
      to_exchange_rate: forex.exchange_rate,
      to_source_currency: forex.source_currency,
      to_currency: selectedOption.label,
    });
    setSelectedForex(selectedOption);
  };

  return (
    <>
      {currencies.loading ? (
        <Skeleton count={2} height={150} />
      ) : Object.keys(currencies.data).length > 0 ? (
        <div className="exchange-conversion-box">
          {currencies.data.crypto_currencies.length > 0 && (
            <div className="exchange-conversion-card">
              <div className="border-theme-right">
                <div className="exchange-conversion-left-sec">
                  <div className="exchange-conversion-label">
                    <Image
                      src={
                        tokenSellData.from_currency
                          ? currencies.data.crypto_currencies.find(
                              (item) =>
                                item.currency_code ==
                                tokenSellData.from_currency
                            ).picture
                          : currencies.data.crypto_currencies[0].picture
                      }
                      className="exchange-conversion-icon"
                    />
                    <Select
                      className="fillert-drop"
                      options={categoriesOptions.filter(
                        (data) => data.value != selectedForex.value
                      )}
                      styles={customStyles}
                      value={selectedCrypto}
                      onChange={(selectedOption) =>
                        onCryptoChange(selectedOption)
                      }
                    />
                  </div>
                  <div className="exchange-select-sec">
                    <h3>
                      {tokenSellData.from_currency
                        ? currencies.data.crypto_currencies.find(
                            (item) =>
                              item.currency_code == tokenSellData.from_currency
                          ).name
                        : currencies.data.crypto_currencies[0].name}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="exchange-conversion-right-sec">
                <h5>{t("you_send")}</h5>
                <input
                  className="form-exchange-input"
                  value={tokenSellData.tokens}
                  autoFocus={true}
                  onChange={(e) => onTokenChange(e.target.value)}
                />
              </div>
            </div>
          )}
          <div className="swap-transfer-icon-sec">
            <span className="or-line">
              <span>
                <Image
                  src={
                    window.location.origin +
                    "/img/exchange/swap-transfer-icon.svg"
                  }
                  className="swap-icon"
                />
              </span>
            </span>
          </div>
          {currencies.data.currencies.length > 0 && (
            <div className="exchange-conversion-card">
              <div className="border-theme-right">
                <div className="exchange-conversion-left-sec">
                  <div className="exchange-conversion-label">
                    <Image
                      src={
                        tokenSellData.to_currency
                          ? currencies.data.currencies.find(
                              (item) =>
                                item.currency_code == tokenSellData.to_currency
                            ).picture
                          : currencies.data.currencies[0].picture
                      }
                      className="exchange-conversion-icon"
                    />
                    <Select
                      className="fillert-drop"
                      options={
                        selectedCrypto
                          ? forexOptions.filter(
                              (data) => data.value != selectedCrypto.value
                            )
                          : forexOptions.filter(
                              (data) => data.value != categoriesOptions[0].value
                            )
                      }
                      styles={customStyles}
                      value={selectedForex}
                      onChange={(selectedOption) =>
                        onCurrencyChange(selectedOption)
                      }
                    />
                  </div>
                  <div className="exchange-select-sec">
                    <h3>
                      {tokenSellData.to_currency
                        ? currencies.data.currencies.find(
                            (item) =>
                              item.currency_code == tokenSellData.to_currency
                          ).name
                        : currencies.data.currencies[0].name}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="exchange-conversion-right-sec">
                <h5 className="text-para">{t("you_get")}</h5>
                {/* <h3>{tokenSellData.to_currency ? currencies.data.currencies.find((item) =>
                    item.currency_code == tokenSellData.to_currency).exchange_rate * tokenSellData.tokens :
                    currencies.data.currencies[0].exchange_rate}</h3> */}
                <h3>
                  {(
                    tokenSellData.tokens *
                    (1 / tokenSellData.from_exchange_rate) *
                    tokenSellData.to_exchange_rate
                  ).toFixed(4)}
                </h3>
              </div>
            </div>
          )}
          <div className="exchange-btn-sec">
            <Button
              className="default-btn"
              onClick={onComplete}
              // disabled={amountConversion.loading || tokenSellData.amountConversion == 0 || errorMsg}
              disabled={!configuration.get("configData.exchange_rate_usdt_aed")}
            >
              {redirect ? t("sell") : t("continue")}
            </Button>
            {!redirect ? (
              <p>
                {t("by_continuing_you_agree_to_our")}{" "}
                <Link to="/page/privacy-agreement"> {t("cookie_policy")}.</Link>
              </p>
            ) : null}
          </div>
          {errorMsg ? (
            <div className="error-card mt-3">
              <Image
                src={window.location.origin + "/img/warning-icon.svg"}
                className="exchange-warning-icon"
              />
              <span>{errorMsg}</span>
            </div>
          ) : null}
          <div className="landing-swap-exchange-rate-sec">
            {
              <>
                <p>
                  {t("estimated_rate")} 1 {""}
                  {tokenSellData.from_currency
                    ? currencies.data.crypto_currencies.find(
                        (item) =>
                          item.currency_code == tokenSellData.from_currency
                      ).currency_code
                    : currencies.data.crypto_currencies[0].currency_code}{" "}
                  = {""}
                  {tokenSellData.to_currency
                    ? currencies.data.currencies.find(
                        (item) =>
                          item.currency_code == tokenSellData.to_currency
                      ).exchange_rate
                    : currencies.data.currencies[0].exchange_rate}
                  {""}{" "}
                  {tokenSellData.to_currency
                    ? currencies.data.currencies.find(
                        (item) =>
                          item.currency_code == tokenSellData.to_currency
                      ).currency_code
                    : currencies.data.forex_currencies[0].currency_code}{" "}
                </p>
                {/* <p>{t("no_extra_fee")}</p> */}
              </>
            }
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ExchangeRate;
