import {
  GET_ADMIN_BANK_ACCOUNT_START,
  GET_ADMIN_BANK_ACCOUNT_SUCCESS,
  GET_ADMIN_BANK_ACCOUNT_FAILURE,
  TOKEN_BUY_START,
  TOKEN_BUY_SUCCESS,
  TOKEN_BUY_FAILURE,
  SET_TOKEN_BUY_DATA,
  RESET_TOKEN_BUY_DATA,
  TOKEN_BUY_TRANSACTIONS_LIST_START,
  TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_BUY_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_BUY_TRANSACTIONS_LIST_START,
  WITHDRAWAL_REQUEST_FAILURE,
  WITHDRAWAL_REQUEST_START,
  WITHDRAWAL_REQUEST_SUCCESS,
  GET_ADMIN_CRYPTO_ACCOUNT_FAILURE,
  GET_ADMIN_CRYPTO_ACCOUNT_START,
  GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS,
  DEPOSIT_TOKEN_FAILURE,
  DEPOSIT_TOKEN_START,
  DEPOSIT_TOKEN_SUCCESS,
  TOKEN_SWAP_FAILURE,
  TOKEN_SWAP_START,
  TOKEN_SWAP_SUCCESS,
  GET_CURRENCIES_LIST_START,
  GET_CURRENCIES_LIST_SUCCESS,
  GET_CURRENCIES_LIST_FAILURE,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  TOKEN_SWAP_TRANSACTIONS_LIST_START,
  TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS,
  TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE,
  MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START,
  GET_ADMIN_WALLET_START,
  GET_ADMIN_WALLET_SUCCESS,
  GET_ADMIN_WALLET_FAILURE,
  SELL_TOKEN_START,
  SELL_TOKEN_SUCCESS,
  SELL_TOKEN_FAILURE,
  SET_TOKEN_SELL_DATA,
  RESET_TOKEN_SELL_DATA,
  SELL_TOKEN_TRANSACTIONS_LIST_START,
  SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS,
  SELL_TOKEN_TRANSACTIONS_LIST_FAILURE,
  SELL_TOKEN_MORE_TRANSACTIONS_LIST_START,
  BUY_TOKENS_EXPORT_START,
  BUY_TOKENS_EXPORT_SUCCESS,
  BUY_TOKENS_EXPORT_FAILURE,
  SELL_TOKENS_EXPORT_START,
  SELL_TOKENS_EXPORT_SUCCESS,
  SELL_TOKENS_EXPORT_FAILURE,
  DEPOSIT_TOKENS_EXPORT_START,
  DEPOSIT_TOKENS_EXPORT_SUCCESS,
  DEPOSIT_TOKENS_EXPORT_FAILURE,
  SWAP_TOKENS_EXPORT_START,
  SWAP_TOKENS_EXPORT_SUCCESS,
  SWAP_TOKENS_EXPORT_FAILURE,
  BUY_TOKENS_VIEW_START,
  BUY_TOKENS_VIEW_SUCCESS,
  BUY_TOKENS_VIEW_FAILURE,
  SELL_TOKENS_VIEW_START,
  SELL_TOKENS_VIEW_SUCCESS,
  SELL_TOKENS_VIEW_FAILURE,
  DEPOSIT_TOKENS_VIEW_START,
  DEPOSIT_TOKENS_VIEW_SUCCESS,
  DEPOSIT_TOKENS_VIEW_FAILURE,
  SWAP_TOKENS_VIEW_START,
  SWAP_TOKENS_VIEW_SUCCESS,
  SWAP_TOKENS_VIEW_FAILURE,
  CARD_TRANSACTIONS_START,
  CARD_TRANSACTIONS_SUCCESS,
  CARD_TRANSACTIONS_FAILURE,
  WALLET_PAYMENTS_EXPORT_START,
  WALLET_PAYMENTS_EXPORT_SUCCESS,
  WALLET_PAYMENTS_EXPORT_FAILURE,
  USER_WITHDRAWALS_EXPORT_START,
  USER_WITHDRAWALS_EXPORT_SUCCESS,
  USER_WITHDRAWALS_EXPORT_FAILURE,
  GENERATE_QUOTE_START,
  GENERATE_QUOTE_SUCCESS,
  GENERATE_QUOTE_FAILURE,
  WITHDRAWAL_STATUS_START,
  WITHDRAWAL_STATUS_SUCCESS,
  WITHDRAWAL_STATUS_FAILURE,
  BUY_TOKENS_VIA_WALLET_START,
  BUY_TOKENS_VIA_WALLET_SUCCESS,
  BUY_TOKENS_VIA_WALLET_FAILURE,
  DEPOSIT_TOKENS_VIA_BANK_START,
  DEPOSIT_TOKENS_VIA_BANK_SUCCESS,
  DEPOSIT_TOKENS_VIA_BANK_FAILURE,
  CRYPTO_WITHDRAWAL_REQUEST_START,
  CRYPTO_WITHDRAWAL_REQUEST_SUCCESS,
  CRYPTO_WITHDRAWAL_REQUEST_FAILURE,
  GET_SWAP_WALLETS_LIST_START,
  GET_SWAP_WALLETS_LIST_SUCCESS,
  GET_SWAP_WALLETS_LIST_FAILURE,
  CRYPTO_CURRENCY,
  GET_CURRENCY_PAIR_LIST_START,
  GET_CURRENCY_PAIR_LIST_SUCCESS,
  GET_CURRENCY_PAIR_LIST_FAILURE,
  TOKEN_TRADE_TRANSACTION_START,
  TOKEN_TRADE_TRANSACTION_SUCCESS,
  TOKEN_TRADE_TRANSACTION_FAILURE,
  TOKEN_TRADE_TRANSACTION_LIST_START,
  TOKEN_TRADE_TRANSACTION_LIST_SUCCESS,
  TOKEN_TRADE_TRANSACTION_LIST_FAILURE,
  SINGLE_TRADE_TRANSACTION_START,
  SINGLE_TRADE_TRANSACTION_SUCCESS,
  SINGLE_TRADE_TRANSACTION_FAILURE,
  CURRENCY_PAIR_START,
  EXPORT_TRADE_TRANSACTION_START,
  EXPORT_TRADE_TRANSACTION_SUCCESS,
  EXPORT_TRADE_TRANSACTION_FAILURE,
  EVENT_KEY_START,
  SINGLE_WITHDRAW_TRANSACTION_START,
  SINGLE_WITHDRAW_TRANSACTION_SUCCESS,
  SINGLE_WITHDRAW_TRANSACTION_FAILURE,
} from "./ActionConstant";

export const getAdminBankAccountStart = (data) => ({
  type: GET_ADMIN_BANK_ACCOUNT_START,
  data,
});
export const getAdminBankAccountSuccess = (data) => ({
  type: GET_ADMIN_BANK_ACCOUNT_SUCCESS,
  data,
});
export const getAdminBankAccountFailure = (error) => ({
  type: GET_ADMIN_BANK_ACCOUNT_FAILURE,
  error,
});

export const tokenBuyStart = (data) => ({ type: TOKEN_BUY_START, data });
export const tokenBuySuccess = (data) => ({ type: TOKEN_BUY_SUCCESS, data });
export const tokenBuyFailure = (error) => ({ type: TOKEN_BUY_FAILURE, error });

export const setTokenBuyData = (data) => ({ type: SET_TOKEN_BUY_DATA, data });
export const resetTokenBuyData = (data) => ({
  type: RESET_TOKEN_BUY_DATA,
  data,
});

export const tokenBuyTransactionsListStart = (data) => ({
  type: TOKEN_BUY_TRANSACTIONS_LIST_START,
  data,
});
export const tokenBuyTransactionsListSuccess = (data) => ({
  type: TOKEN_BUY_TRANSACTIONS_LIST_SUCCESS,
  data,
});
export const tokenBuyTransactionsListFailure = (error) => ({
  type: TOKEN_BUY_TRANSACTIONS_LIST_FAILURE,
  error,
});
export const moreTokenBuyTransactionsListStart = (data) => ({
  type: MORE_TOKEN_BUY_TRANSACTIONS_LIST_START,
  data,
});

export const withdrawalRequestStart = (data) => ({
  type: WITHDRAWAL_REQUEST_START,
  data,
});
export const withdrawalRequestSuccess = (data) => ({
  type: WITHDRAWAL_REQUEST_SUCCESS,
  data,
});
export const withdrawalRequestFailure = (error) => ({
  type: WITHDRAWAL_REQUEST_FAILURE,
  error,
});

export const getAdminCryptoAccountStart = (data) => ({
  type: GET_ADMIN_CRYPTO_ACCOUNT_START,
  data,
});
export const getAdminCryptoAccountSuccess = (data) => ({
  type: GET_ADMIN_CRYPTO_ACCOUNT_SUCCESS,
  data,
});
export const getAdminCryptoAccountFailure = (error) => ({
  type: GET_ADMIN_CRYPTO_ACCOUNT_FAILURE,
  error,
});

export const depositTokenStart = (data) => ({
  type: DEPOSIT_TOKEN_START,
  data,
});
export const depositTokenSuccess = (data) => ({
  type: DEPOSIT_TOKEN_SUCCESS,
  data,
});
export const depositTokenFailure = (error) => ({
  type: DEPOSIT_TOKEN_FAILURE,
  error,
});

export const tokenDepositTransactionsListStart = (data) => ({
  type: TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  data,
});
export const tokenDepositTransactionsListSuccess = (data) => ({
  type: TOKEN_DEPOSIT_TRANSACTIONS_LIST_SUCCESS,
  data,
});
export const tokenDepositTransactionsListFailure = (error) => ({
  type: TOKEN_DEPOSIT_TRANSACTIONS_LIST_FAILURE,
  error,
});
export const moreTokenDepositTransactionsListStart = (data) => ({
  type: MORE_TOKEN_DEPOSIT_TRANSACTIONS_LIST_START,
  data,
});

export const tokenSwapStart = (data) => ({ type: TOKEN_SWAP_START, data });
export const tokenSwapSuccess = (data) => ({ type: TOKEN_SWAP_SUCCESS, data });
export const tokenSwapFailure = (error) => ({
  type: TOKEN_SWAP_FAILURE,
  error,
});

export const getCurrenciesListStart = (data) => ({
  type: GET_CURRENCIES_LIST_START,
  data,
});
export const getCurrenciesListSuccess = (data) => ({
  type: GET_CURRENCIES_LIST_SUCCESS,
  data,
});
export const getCurrenciesListFailure = (error) => ({
  type: GET_CURRENCIES_LIST_FAILURE,
  error,
});

export const tokenSwapTransactionsListStart = (data) => ({
  type: TOKEN_SWAP_TRANSACTIONS_LIST_START,
  data,
});
export const tokenSwapTransactionsListSuccess = (data) => ({
  type: TOKEN_SWAP_TRANSACTIONS_LIST_SUCCESS,
  data,
});
export const tokenSwapTransactionsListFailure = (error) => ({
  type: TOKEN_SWAP_TRANSACTIONS_LIST_FAILURE,
  error,
});
export const moreTokenSwapTransactionsListStart = (data) => ({
  type: MORE_TOKEN_SWAP_TRANSACTIONS_LIST_START,
  data,
});

export const getAdminWalletStart = (data) => ({
  type: GET_ADMIN_WALLET_START,
  data,
});
export const getAdminWalletSuccess = (data) => ({
  type: GET_ADMIN_WALLET_SUCCESS,
  data,
});
export const getAdminWalletFailure = (error) => ({
  type: GET_ADMIN_WALLET_FAILURE,
  error,
});

export const sellTokenStart = (data) => ({ type: SELL_TOKEN_START, data });
export const sellTokenSuccess = (data) => ({ type: SELL_TOKEN_SUCCESS, data });
export const sellTokenFailure = (error) => ({
  type: SELL_TOKEN_FAILURE,
  error,
});

export const setTokenSellData = (data) => ({ type: SET_TOKEN_SELL_DATA, data });
export const resetTokenSellData = (data) => ({
  type: RESET_TOKEN_SELL_DATA,
  data,
});

export const sellTokenTransactionsListStart = (data) => ({
  type: SELL_TOKEN_TRANSACTIONS_LIST_START,
  data,
});
export const sellTokenTransactionsListSuccess = (data) => ({
  type: SELL_TOKEN_TRANSACTIONS_LIST_SUCCESS,
  data,
});
export const sellTokenTransactionsListFailure = (error) => ({
  type: SELL_TOKEN_TRANSACTIONS_LIST_FAILURE,
  error,
});
export const sellTokenMoreTransactionsListStart = (data) => ({
  type: SELL_TOKEN_MORE_TRANSACTIONS_LIST_START,
  data,
});

export const buyTokensExportStart = (data) => ({
  type: BUY_TOKENS_EXPORT_START,
  data,
});
export const buyTokensExportSuccess = (data) => ({
  type: BUY_TOKENS_EXPORT_SUCCESS,
  data,
});
export const buyTokensExportFailure = (error) => ({
  type: BUY_TOKENS_EXPORT_FAILURE,
  error,
});

export const sellTokensExportStart = (data) => ({
  type: SELL_TOKENS_EXPORT_START,
  data,
});
export const sellTokensExportSuccess = (data) => ({
  type: SELL_TOKENS_EXPORT_SUCCESS,
  data,
});
export const sellTokensExportFailure = (error) => ({
  type: SELL_TOKENS_EXPORT_FAILURE,
  error,
});

export const depositTokensExportStart = (data) => ({
  type: DEPOSIT_TOKENS_EXPORT_START,
  data,
});
export const depositTokensExportSuccess = (data) => ({
  type: DEPOSIT_TOKENS_EXPORT_SUCCESS,
  data,
});
export const depositTokensExportFailure = (error) => ({
  type: DEPOSIT_TOKENS_EXPORT_FAILURE,
  error,
});

export const swapTokensExportStart = (data) => ({
  type: SWAP_TOKENS_EXPORT_START,
  data,
});
export const swapTokensExportSuccess = (data) => ({
  type: SWAP_TOKENS_EXPORT_SUCCESS,
  data,
});
export const swapTokensExportFailure = (error) => ({
  type: SWAP_TOKENS_EXPORT_FAILURE,
  error,
});

export const buyTokensViewStart = (data) => ({
  type: BUY_TOKENS_VIEW_START,
  data,
});
export const buyTokensViewSuccess = (data) => ({
  type: BUY_TOKENS_VIEW_SUCCESS,
  data,
});
export const buyTokensViewFailure = (error) => ({
  type: BUY_TOKENS_VIEW_FAILURE,
  error,
});

export const sellTokensViewStart = (data) => ({
  type: SELL_TOKENS_VIEW_START,
  data,
});
export const sellTokensViewSuccess = (data) => ({
  type: SELL_TOKENS_VIEW_SUCCESS,
  data,
});
export const sellTokensViewFailure = (error) => ({
  type: SELL_TOKENS_VIEW_FAILURE,
  error,
});

export const depositTokensViewStart = (data) => ({
  type: DEPOSIT_TOKENS_VIEW_START,
  data,
});
export const depositTokensViewSuccess = (data) => ({
  type: DEPOSIT_TOKENS_VIEW_SUCCESS,
  data,
});
export const depositTokensViewFailure = (error) => ({
  type: DEPOSIT_TOKENS_VIEW_FAILURE,
  error,
});

export const swapTokensViewStart = (data) => ({
  type: SWAP_TOKENS_VIEW_START,
  data,
});
export const swapTokensViewSuccess = (data) => ({
  type: SWAP_TOKENS_VIEW_SUCCESS,
  data,
});
export const swapTokensViewFailure = (error) => ({
  type: SWAP_TOKENS_VIEW_FAILURE,
  error,
});

export const cardTransactionsStart = (data) => ({
  type: CARD_TRANSACTIONS_START,
  data,
});
export const cardTransactionsSuccess = (data) => ({
  type: CARD_TRANSACTIONS_SUCCESS,
  data,
});
export const cardTransactionsFailure = (error) => ({
  type: CARD_TRANSACTIONS_FAILURE,
  error,
});

export const walletPaymentsExportStart = (data) => ({
  type: WALLET_PAYMENTS_EXPORT_START,
  data,
});
export const walletPaymentsExportSuccess = (data) => ({
  type: WALLET_PAYMENTS_EXPORT_SUCCESS,
  data,
});
export const walletPaymentsExportFailure = (error) => ({
  type: WALLET_PAYMENTS_EXPORT_FAILURE,
  error,
});

export const userWithdrawalsExportStart = (data) => ({
  type: USER_WITHDRAWALS_EXPORT_START,
  data,
});
export const userWithdrawalsExportSuccess = (data) => ({
  type: USER_WITHDRAWALS_EXPORT_SUCCESS,
  data,
});
export const userWithdrawalsExportFailure = (error) => ({
  type: USER_WITHDRAWALS_EXPORT_FAILURE,
  error,
});

export const generateQuoteStart = (data) => ({
  type: GENERATE_QUOTE_START,
  data,
});
export const generateQuoteSuccess = (data) => ({
  type: GENERATE_QUOTE_SUCCESS,
  data,
});
export const generateQuoteFailure = (error) => ({
  type: GENERATE_QUOTE_FAILURE,
  error,
});

export const withdrawalStatusStart = (data) => ({
  type: WITHDRAWAL_STATUS_START,
  data,
});
export const withdrawalStatusSuccess = (data) => ({
  type: WITHDRAWAL_STATUS_SUCCESS,
  data,
});
export const withdrawalStatusFailure = (error) => ({
  type: WITHDRAWAL_STATUS_FAILURE,
  error,
});

export const buyTokensViaWalletStart = (data) => ({
  type: BUY_TOKENS_VIA_WALLET_START,
  data,
});
export const buyTokensViaWalletSuccess = (data) => ({
  type: BUY_TOKENS_VIA_WALLET_SUCCESS,
  data,
});
export const buyTokensViaWalletFailure = (error) => ({
  type: BUY_TOKENS_VIA_WALLET_FAILURE,
  error,
});

export const depositTokensViaBankStart = (data) => ({
  type: DEPOSIT_TOKENS_VIA_BANK_START,
  data,
});
export const depositTokensViaBankSuccess = (data) => ({
  type: DEPOSIT_TOKENS_VIA_BANK_SUCCESS,
  data,
});
export const depositTokensViaBankFailure = (error) => ({
  type: DEPOSIT_TOKENS_VIA_BANK_FAILURE,
  error,
});

export const cryptoWithdrawalRequestStart = (data) => ({
  type: CRYPTO_WITHDRAWAL_REQUEST_START,
  data,
});
export const cryptoWithdrawalRequestSuccess = (data) => ({
  type: CRYPTO_WITHDRAWAL_REQUEST_SUCCESS,
  data,
});
export const cryptoWithdrawalRequestFailure = (error) => ({
  type: CRYPTO_WITHDRAWAL_REQUEST_FAILURE,
  error,
});

export const getSwapWalletsListStart = (data) => ({
  type: GET_SWAP_WALLETS_LIST_START,
  data,
});
export const getSwapWalletsListSuccess = (data) => ({
  type: GET_SWAP_WALLETS_LIST_SUCCESS,
  data,
});
export const getSwapWalletsListFailure = (error) => ({
  type: GET_SWAP_WALLETS_LIST_FAILURE,
  error,
});

export const getCryptoCurrency = (data) => ({
  type: CRYPTO_CURRENCY,
  data,
});

export const getCurrencyPairListStart = (data) => ({
  type: GET_CURRENCY_PAIR_LIST_START,
  data,
});
export const getCurrencyPairListSuccess = (data) => ({
  type: GET_CURRENCY_PAIR_LIST_SUCCESS,
  data,
});
export const getCurrencyPairListFailure = (error) => ({
  type: GET_CURRENCY_PAIR_LIST_FAILURE,
  error,
});

export const tokenTradeTransactionStart = (data) => ({
  type: TOKEN_TRADE_TRANSACTION_START,
  data,
});
export const tokenTradeTransactionSuccess = (data) => ({
  type: TOKEN_TRADE_TRANSACTION_SUCCESS,
  data,
});
export const tokenTradeTransactionFailure = (error) => ({
  type: TOKEN_TRADE_TRANSACTION_FAILURE,
  error,
});

export const tokenTradeTransactionListStart = (data) => ({
  type: TOKEN_TRADE_TRANSACTION_LIST_START,
  data,
});
export const tokenTradeTransactionListSuccess = (data) => ({
  type: TOKEN_TRADE_TRANSACTION_LIST_SUCCESS,
  data,
});
export const tokenTradeTransactionListFailure = (error) => ({
  type: TOKEN_TRADE_TRANSACTION_LIST_FAILURE,
  error,
});

export const currencyPairStart = (data) => ({
  type: CURRENCY_PAIR_START,
  data,
});

export const singleTradeTransactionStart = (data) => ({
  type: SINGLE_TRADE_TRANSACTION_START,
  data,
});
export const singleTradeTransactionSuccess = (data) => ({
  type: SINGLE_TRADE_TRANSACTION_SUCCESS,
  data,
});
export const singleTradeTransactionFailure = (error) => ({
  type: SINGLE_TRADE_TRANSACTION_FAILURE,
  error,
});

export const exportTradeTransactionStart = (data) => ({
  type: EXPORT_TRADE_TRANSACTION_START,
  data,
});
export const exportTradeTransactionSuccess = (data) => ({
  type: EXPORT_TRADE_TRANSACTION_SUCCESS,
  data,
});
export const exportTradeTransactionFailure = (error) => ({
  type: EXPORT_TRADE_TRANSACTION_FAILURE,
  error,
});

export const eventKeyStart = (data) => ({
  type: EVENT_KEY_START,
  data,
});

export const singleWithdrawTransactionStart = (data) => ({
  type: SINGLE_WITHDRAW_TRANSACTION_START,
  data,
});
export const singleWithdrawTransactionSuccess = (data) => ({
  type: SINGLE_WITHDRAW_TRANSACTION_SUCCESS,
  data,
});
export const singleWithdrawTransactionFailure = (error) => ({
  type: SINGLE_WITHDRAW_TRANSACTION_FAILURE,
  error,
});
