import React from "react";
import Skeleton from "react-loading-skeleton";
import { Row, Container, Col, Button, Tab, Nav } from "react-bootstrap";

const ProfileLoader = () => {
  return (
    <div className="feature-profile-wrapped">
      <Container>
        <Row>
          <Col sm={12} lg={5} xl={5}>
            <Skeleton height={550} borderRadius={10} />
          </Col>
          <Col sm={12} lg={7} xl={7}>
            <div className="feature-profile-content-frame">
              <Skeleton height={180} width="100%" borderRadius={10} />
              <Skeleton height={100} width="100%" borderRadius={10} />
              <Skeleton height={130} width="100%" borderRadius={10} />
              <Skeleton height={80} width="100%" borderRadius={10} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileLoader;
