import React, { useEffect, useState } from "react";
import {
  Container,
  Tab,
  Tabs,
  Image,
  Button,
  Nav,
  Row,
  Col,
} from "react-bootstrap";
import "../Wallet/Wallet.css";
import { connect } from "react-redux";
import WalletWithdrawModal from "./WalletWithdrawModal";
import WithDrawHistory from "../WithDraw/WithDrawHistory";
import WalletTransaction from "./WalletTransaction";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { fetchSingleWalletViewStart } from "../../store/actions/WalletAction";
import { useTranslation } from "react-multi-lang";
import DepositModal from "./DepositModal";
import SwapModalNew from "./SwapModalNew";
import SwapModal from "./SwapModal";
import WithdrawCryptoModal from "./WithdrawCryptoModal";
import { useSearchParams } from "react-router-dom";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const WalletIndex = (props) => {
  const location = useLocation();
  const t = useTranslation("wallet_index");
  const [activeTab, setActiveTab] = useState("transactions");
  const params = useParams();
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [cryptoModalShow, setCryptoModalShow] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams("");
  const [depositModal, setDepositModal] = useState(false);
  const [swapModal, setSwapModal] = useState(false);
  const [tokenType, setTokenType] = useState("");
  useEffect(() => {
    props.dispatch(
      fetchSingleWalletViewStart({
        currency_code: params.user_wallet_id,
      })
    );
  }, [params.user_wallet_id]);

  const [swapModalNew, setSwapModalNew] = useState(false);

  return (
    <>
      <div className="wallet-wrapped">
        {props.wallet.loading ? (
          <>
            <Container>
              <div className="wallet-currency-title item_flex_x_5 over_space_my_1_5">
                <Skeleton height={40} width={100} borderRadius={10} />
              </div>
            </Container>

            <Container>
              <Skeleton height={160} borderRadius={10} />
            </Container>
          </>
        ) : Object.keys(props.wallet.data).length > 0 ? (
          <>
            <Container>
              <div className="wallet-currency-title item_flex_x_5 over_space_my_1_5">
                <Link
                  to={location.search == "" ? -1 : "/wallet-currency-list"}
                  className="item_center back_navigate_card"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke="#171717"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeMiterlimit="10"
                      strokeWidth="1.5"
                      d="M9.57 5.93L3.5 12l6.07 6.07M20.5 12H3.67"
                    ></path>
                  </svg>
                </Link>
                <h2 className="m-0">Wallet</h2>
              </div>
            </Container>
            <div className="wallet-currency-frame over_space_my_1_5">
              <Container>
                <div className="wallet-currency-card-frame item_line_between align-items-end">
                  <div className="wallet-currency-layout">
                    <div className="wallet-currency-card">
                      <div className="wallet-card-icon item_flex_x_5">
                        <CustomLazyLoad
                          src={
                            window.location.origin + "/img/icon/new/coin.png"
                          }
                          className="wallet-icon"
                        />
                        <h3>{props.wallet.data.user_wallet.currency_code}</h3>
                      </div>
                      <div className="wallet-card-info">
                        <h3>{props.wallet.data.user_wallet.total}</h3>
                        <p>
                          {t("total")}{" "}
                          {`${
                            props.wallet.data.user_wallet.currency_type ==
                            "crypto"
                              ? t("token_balance")
                              : t("amount_balance")
                          }`}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-currency-card">
                      <div className="wallet-card-icon item_flex_x_5">
                        <CustomLazyLoad
                          src={
                            window.location.origin + "/img/icon/new/coin.png"
                          }
                          className="wallet-icon"
                        />
                        <h3>{props.wallet.data.user_wallet.currency_code}</h3>
                      </div>
                      <div className="wallet-card-info">
                        <h3>{props.wallet.data?.user_wallet?.remaining}</h3>
                        <p>
                          {t("remaining")}{" "}
                          {`${
                            props.wallet.data.user_wallet.currency_type ==
                            "crypto"
                              ? t("tokens")
                              : t("amount")
                          }`}
                        </p>
                      </div>
                    </div>
                    <div className="wallet-currency-card">
                      <div className="wallet-card-icon item_flex_x_5">
                        <CustomLazyLoad
                          src={
                            window.location.origin + "/img/icon/new/coin.png"
                          }
                          className="wallet-icon"
                        />
                        <h3>{props.wallet.data.user_wallet.currency_code}</h3>
                      </div>
                      <div className="wallet-card-info">
                        <h3>{props.wallet.data.user_wallet.used}</h3>
                        <p>
                          {t("used")}{" "}
                          {`${
                            props.wallet.data.user_wallet.currency_type ==
                            "crypto"
                              ? t("tokens")
                              : t("amount")
                          }`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="wallet-currency-action item_flex_x_5">
                    {props.wallet.data.user_wallet.currency_type == "crypto" ? (
                      <Button
                        onClick={() => setSwapModalNew(true)}
                        disabled={
                          props.wallet.data?.user_wallet?.remaining_original ==
                          0
                        }
                        className="action-btn overlay item_flex_x_5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 17h5l1.67 -2.386m3.66 -5.227l1.67 -2.387h6" />
                          <path d="M18 4l3 3l-3 3" />
                          <path d="M3 7h5l7 10h6" />
                          <path d="M18 20l3 -3l-3 -3" />
                        </svg>
                        <span>{t("swap")}</span>
                      </Button>
                    ) : (
                      <Button
                        onClick={() => setSwapModalNew(true)}
                        disabled={
                          props.wallet.data?.user_wallet?.remaining_original ==
                          0
                        }
                        className="action-btn overlay item_flex_x_5"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#111"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M3 17h5l1.67 -2.386m3.66 -5.227l1.67 -2.387h6" />
                          <path d="M18 4l3 3l-3 3" />
                          <path d="M3 7h5l7 10h6" />
                          <path d="M18 20l3 -3l-3 -3" />
                        </svg>
                        <span>{t("swap")}</span>
                      </Button>
                    )}

                    {props.wallet.data.user_wallet.currency_type == "crypto" ? (
                      <>
                        <Button
                          onClick={() =>
                            setCryptoModalShow(
                              props.wallet.data.user_wallet.remaining_original
                            )
                          }
                          disabled={
                            props.wallet.data?.user_wallet
                              ?.remaining_original == 0 ||
                            props.profile.data.kyc_verified == 0
                          }
                          className="action-btn primary item_flex_x_5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#111"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M17 7l-10 10" />
                            <path d="M8 7l9 0l0 9" />
                          </svg>
                          <span>{t("withdraw")}</span>
                        </Button>
                        <Button
                          disabled={props.profile.data.kyc_verified == 0}
                          onClick={() => {
                            setTokenType(
                              props.wallet.data.user_wallet.currency_code
                            );
                            setDepositModal(props.wallet.data.user_wallet);
                          }}
                          className="action-btn secondary item_flex_x_5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M17 7l-10 10" />
                            <path d="M16 17l-9 0l0 -9" />
                          </svg>
                          <span>{t("deposit")}</span>
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          onClick={() =>
                            setModalShow(
                              props.wallet.data.user_wallet.remaining_original
                            )
                          }
                          disabled={
                            props.wallet.data?.user_wallet
                              ?.remaining_original == 0 ||
                            props.profile.data.kyc_verified == 0
                          }
                          className="action-btn primary item_flex_x_5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M17 7l-10 10" />
                            <path d="M8 7l9 0l0 9" />
                          </svg>
                          <span>{t("withdraw")}</span>
                        </Button>
                        <Button
                          // onClick={() => navigate("/buy")}
                          disabled={props.profile.data.kyc_verified == 0}
                          onClick={() => {
                            setTokenType(
                              props.wallet.data.user_wallet.currency_code
                            );
                            setDepositModal(props.wallet.data.user_wallet);
                          }}
                          className="action-btn secondary item_flex_x_5"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M17 7l-10 10" />
                            <path d="M16 17l-9 0l0 -9" />
                          </svg>
                          <span>{t("deposit")}</span>
                        </Button>
                      </>
                    )}
                  </div>
                </div>
                {props.profile.data.kyc_verified == 0 && (
                  <p className="text-danger text-end mt-2">
                    Withdraw, Deposit, and Swap features activated after KYC
                    verification
                  </p>
                )}
              </Container>
            </div>
          </>
        ) : null}
        <div className="wallet_recent_table">
          <Container>
            <div className="wallet-full-transaction-table">
              <div className="wallet_recent_table_titles out_space_mx_1">
                <h3>Transactions</h3>
              </div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col sm={12} className="">
                    <Nav variant="pills" className="out_space_mx_1">
                      <Nav.Item>
                        <Nav.Link
                          eventKey="first"
                          onClick={() => setActiveTab("transactions")}
                          className="wallet_tab_btn"
                        >
                          {t("transactions")} History
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="second"
                          onClick={() => setActiveTab("withdrawals")}
                          className="wallet_tab_btn"
                        >
                          {t("withdraw_history")}
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col sm={12}>
                    {activeTab == "transactions" &&
                      Object.keys(props.wallet.data).length > 0 && (
                        <div className="payment-tabs-content">
                          <WalletTransaction
                            activeTab={activeTab}
                            user_wallet_id={props.wallet.data?.user_wallet?.id}
                            currency_type={
                              !props.wallet.loading &&
                              Object.keys(props.wallet.data).length > 0
                                ? props.wallet.data.user_wallet.currency_type ==
                                  "crypto"
                                  ? "crypto"
                                  : "forex"
                                : "forex"
                            }
                          />
                        </div>
                      )}
                    {activeTab === "withdrawals" &&
                      Object.keys(props.wallet.data).length > 0 && (
                        <div className="payment-tabs-content">
                          <WithDrawHistory
                            activeTab={activeTab}
                            user_wallet_id={props.wallet.data?.user_wallet?.id}
                            currency_type={
                              !props.wallet.loading &&
                              Object.keys(props.wallet.data).length > 0
                                ? props.wallet.data.user_wallet.currency_type ==
                                  "crypto"
                                  ? "crypto"
                                  : "forex"
                                : "forex"
                            }
                          />
                        </div>
                      )}
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </Container>
        </div>
      </div>

      {/* modals  */}
      {modalShow && (
        <WalletWithdrawModal
          show={modalShow}
          user_wallet_id={props.wallet.data.user_wallet.id}
          currency={props.wallet.data.user_wallet.currency_code}
          amount={props.wallet.data.user_wallet.remaining}
          currencyCode={props.wallet.data.user_wallet.currency_code}
          onHide={() => setModalShow(false)}
        />
      )}
      {depositModal && (
        <DepositModal
          show={depositModal}
          token={props.wallet.data?.user_wallet?.currency_code}
          tokenType={tokenType}
          user_wallet_id={props?.wallet?.data?.user_wallet?.id}
          onHide={() => setDepositModal(false)}
        />
      )}
      {swapModal && (
        <SwapModal
          show={swapModal}
          user_wallet_id={props.wallet.data.user_wallet.id}
          onHide={() => setSwapModal(false)}
        />
      )}
      {cryptoModalShow && (
        <WithdrawCryptoModal
          show={cryptoModalShow}
          user_wallet_id={props.wallet.data.user_wallet.id}
          amount={props.wallet.data.user_wallet.remaining}
          currencyCode={props.wallet.data.user_wallet.currency_code}
          onHide={() => setCryptoModalShow(false)}
        />
      )}
      {swapModalNew && (
        <SwapModalNew
          show={swapModalNew}
          onHide={() => setSwapModalNew(false)}
          user_wallet_id={props.wallet.data.user_wallet.id}
        />
      )}
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.singleWallet,
  transaction: state.wallet.allTransaction,
  profile: state.user.profile,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WalletIndex);
