import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetBuyData } from "../../store/actions/UserAction";
import { Form, InputGroup, Button, Image } from "react-bootstrap";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../../components/helper/ToastNotification";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { resetTokenBuyData } from "../../store/actions/BuyAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";

const BuySuccess = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("buy_index");
  const buyToken = useSelector((state) => state.user.buyToken);
  const tokenBuy = useSelector(state => state.buy.tokenBuy);

  const onCopy = (event, type) => {
    getSuccessNotificationMessage(`${type} was copied to clipboard!`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      dispatch(resetBuyData());
      dispatch(resetTokenBuyData());
    }
  }, []);

  return (
    <>
      <div className="buy-processing-img-sec out_space_mx_1_5">
        <CustomLazyLoad
          src={window.location.origin + "/img/buy/payment-success.gif"}
          className="buy-success-img"
        />
      </div>
      <div className="payment-status-card out_space_mx_1_5">
        <div className="payment-success-text text-center">{t("payment_successful")}</div>
      </div>
      {Object.keys(tokenBuy.data).length > 0 && (
        <>
          <div className="buy-data-frame out_space_mx_1_5">
            <div className="buy-data-card mb-3">
            <h6 >{t("payment_id.label")}</h6>
            <div className="auth_buy_code">
              <div className="auth_deposit_code_card">
                <span>
                  {" "}
                  {tokenBuy.data.buy_transaction.payment_id}
                </span>{" "}
              </div>

              <CopyToClipboard
              text={tokenBuy.data.buy_transaction.payment_id}
              onCopy={(e) => onCopy(e, t("payment_id.label"))}
              >
                <Button className=" action-btn overlay item_center_column p-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#298BFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                  >
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    />
                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                  </svg>
                </Button>
              </CopyToClipboard>
            </div>
            </div>
            <div className="buy-data-card">

            <h6>{t("order_id")}</h6>
            <div className="auth_buy_code">
              <div className="auth_deposit_code_card">
                <span>
                  {" "}
                  {tokenBuy.data.buy_transaction.order_id}
                </span>{" "}
              </div>

              <CopyToClipboard
                text={tokenBuy.data.buy_transaction.order_id}
                onCopy={(e) => onCopy(e, t("order_id"))}
              >
                <Button className=" action-btn overlay item_center_column p-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#298BFF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                  >
                    <path
                      stroke="none"
                      d="M0 0h24v24H0z"
                      fill="none"
                    />
                    <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                    <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                  </svg>
                </Button>
              </CopyToClipboard>
            </div>
          </div>
          </div>
        </>
      )}
      <div className="buy-deposit-action">
        <Button
          className="action-btn primary w-100"
          onClick={() => {
            navigate(Object.keys(tokenBuy.data).length > 0 && tokenBuy.data.user_wallet_id ? `/transactions` : '/');
          }}
        >
          {t("continue")}
        </Button>
      </div>
    </>
  );
};

export default BuySuccess;
