import React from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";

const TransactionSuccess = (props) => {
  return (
    <>
      <div className="transfer-successful-sec">
        <div className="transfer-successful-card">
          <div className="transfer-successful-icon-sec">
            <Image
              className="transfer-successful-icon"
              src={window.location.origin + "/img/phase_1/success-img.gif"}
            />
          </div>
          <div className="transfer-successful-info-sec">
            <h2>{props.message}</h2>
          </div>
          <div className="transfer-successful-btn-sec">
            <Button
              className="action-btn primary"
              onClick={() => {
                props.onHide();
              }}
            >
              Done
            </Button>
            {props?.url && props?.url !== null && (
              <Button
                className="action-btn link-btn"
                onClick={() => {
                  window.open(props?.url, "_blank");
                }}
              >
                View on Explorer
              </Button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default TransactionSuccess;
