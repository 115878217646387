import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./Static.css";

const TermsAndConditions = () => {
  return (
    <>
      <div className="static-pag-sec">
        <Container>
          <>
            <Row className="justify-content-md-center">
              <Col md={8}>
                <div className="section-title">
                  <h1>Terms and Conditions</h1>
                </div>
              </Col>
            </Row>
            <div className="static-box">
              <Row>
                <Col md={12}>
                  <div className="static-card">
                    <div className="terms-and-conditions-details">
                      <h4>Terms and Conditions for Herald Exchange</h4>
                      <p>
                        Welcome to Herald Exchange! These Terms and Conditions
                        ("Terms") govern your use of our platform. By accessing
                        or using Herald Exchange, you agree to comply with and be
                        bound by these Terms. If you do not agree with these
                        Terms, please do not use our platform.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>1. Acceptance of Terms</h4>
                      <p>
                        By using Herald Exchange, you acknowledge that you have
                        read, understood, and agree to these Terms. If you are
                        using our platform on behalf of an organization, you
                        represent that you have the authority to bind that
                        organization to these Terms.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>2. Account Registration</h4>
                      <p>
                        To use certain features of Herald Exchange, you may need to
                        register for an account. You agree to provide accurate
                        and complete information during the registration process
                        and to update your information to keep it current.
                      </p>
                      <p>
                        You are responsible for maintaining the confidentiality
                        of your account credentials and for all activities that
                        occur under your account. You agree to notify us
                        immediately of any unauthorized use of your account.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>3. Use of the Platform</h4>
                      <p>
                        You agree to use Herald Exchange only for lawful purposes
                        and in accordance with these Terms. You are prohibited
                        from engaging in any activity that interferes with or
                        disrupts the operation of our platform.
                      </p>
                      <p>
                        We reserve the right to suspend or terminate your access
                        to Herald Exchange if you violate these Terms or engage in
                        any fraudulent or unlawful activities.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>4. Intellectual Property</h4>
                      <p>
                        All content and materials on Herald Exchange, including but
                        not limited to text, graphics, logos, and software, are
                        the property of Herald Exchange or its licensors and are
                        protected by intellectual property laws.
                      </p>
                      <p>
                        You may not reproduce, distribute, or create derivative
                        works from any content on our platform without our
                        express written permission.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>5. Limitation of Liability</h4>
                      <p>
                        Herald Exchange is provided "as is" and "as available"
                        without warranties of any kind. We do not guarantee the
                        accuracy, completeness, or reliability of any content on
                        our platform.
                      </p>
                      <p>
                        In no event shall Herald Exchange be liable for any
                        indirect, incidental, special, or consequential damages
                        arising out of or in connection with your use of our
                        platform.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>6. Indemnification</h4>
                      <p>
                        You agree to indemnify, defend, and hold harmless Herald
                        Exchange and its affiliates from and against any claims,
                        liabilities, damages, losses, and expenses arising out
                        of or related to your use of the platform or your
                        violation of these Terms.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>7. Governing Law</h4>
                      <p>
                        These Terms are governed by and construed in accordance
                        with the laws of the jurisdiction in which Herald Exchange
                        operates, without regard to its conflict of law
                        principles.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>8. Changes to Terms</h4>
                      <p>
                        We may update these Terms from time to time. We will
                        notify users of any material changes through our
                        platform. Your continued use of Herald Exchange after any
                        changes indicates your acceptance of the new Terms.
                      </p>
                    </div>
                    <div className="terms-and-conditions-details">
                      <h4>9. Contact Us</h4>
                      <p>
                        If you have any questions or concerns about these Terms,
                        please contact us at{" "}
                        <a href="mailto:contact@Heraldexchange.com">
                          contact@Heraldexchange.com
                        </a>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        </Container>
      </div>
    </>
  );
};

export default TermsAndConditions;
