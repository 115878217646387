import React, { useEffect, useState } from "react";
import {
  Image,
  Offcanvas,
  Button,
  InputGroup,
  Form,
  Nav,
  Tab,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import StockList from "./StockList";
import { useDispatch, useSelector } from "react-redux";
import {
  currencyPairStart,
  getCurrenciesListStart,
  getCurrencyPairListStart,
} from "../../store/actions/BuyAction";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";

const TradeNav = () => {
  const dispatch = useDispatch();
  const currencyPairList = useSelector((state) => state.buy.currencyPairList);
  const currencyPairs = useSelector((state) => state.buy.currencyPair);
  const currencies = useSelector((state) => state.buy.currencies);
  const [show, setShow] = useState(false);
  const [currencyPair, setCurrencyPair] = useState([]);
  const [search, setSearch] = useState("");
  const [skipRender, setSkipRender] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (search) => {
    const searchValue = search.toLowerCase();
    if (searchValue == "") handleClear();
    else {
      const filteredPairs = currencyPairList.data.currency_pairs.filter(
        (pair) => {
          return (
            pair.currency1.toLowerCase().includes(searchValue) ||
            pair.currency2.toLowerCase().includes(searchValue) ||
            pair.unique_id.toLowerCase().includes(searchValue)
          );
        }
      );
      setCurrencyPair(filteredPairs);
    }
  };
  const handleClear = () => {
    setSearch("");
    setCurrencyPair(currencyPairList.data.currency_pairs);
  };

  useEffect(() => {
    if (
      !currencyPairList.loading &&
      Object.keys(currencyPairList.data).length > 0 &&
      Object.keys(currencyPairList.data.currency_pairs).length > 0
    ) {
      setCurrencyPair(currencyPairList.data.currency_pairs);
    }
  }, [currencyPairList]);

  useEffect(() => {
    if (Object.keys(currencies.data).length <= 0)
      dispatch(getCurrenciesListStart());
  }, []);

  return (
    <>
      <div className="trading-header">
        <div className="trade-header-detail">
          <Link to="#" onClick={handleShow}>
            <div className="trade-header-heading grid-from">
              <div className="trade-header-icons">
                <Image
                  className="bitcoin-icon"
                  src={
                    currencyPairs?.image && currencyPairs?.image !== ""
                      ? currencyPairs.image
                      : window.location.origin + "/img/trading/crypto.png"
                  }
                  type="image/png"
                />
              </div>
              <div className="trading-head-info">
                <div className="trading-titles font-8_5 font-bold-600 line_block">
                  {currencyPairs?.unique_id}
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  enableBackground="new 0 0 512 512"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#131615"
                    d="M15.4 9.88l-4.59-4.59a1 1 0 00-1.41 0 1 1 0 000 1.42l4.6 4.58a1 1 0 010 1.42l-4.6 4.58a1 1 0 001.41 1.42l4.59-4.59a3 3 0 000-4.24z"
                    data-original="#000000"
                  ></path>
                </svg>
              </div>
            </div>
          </Link>
          {/* <div className="trade-header-action">

                        <Image
                            className="favorite-icon"
                            src={
                                window.location.origin + "/img/trading/favorite.svg"
                            }
                            type="image/png"
                        />
                    </div> */}
          {/* <div className="trade-stock-count">
            <div className="trade-stock-amount font-bold-500 font-7_5">
              56,758.0
            </div>
            <div className="trade-stock-percentage font-bold-500 font-7_5">
              +1.86%
            </div>
          </div>
          <div className="trade-stock-detail">
            <div className="trade-stock-type font-8 font-bold-400 font-gray ">
              USD
            </div>
            <div className="trade-stock-amount font-7_5 font-bold-500 font-dark">
              $5,54,379.5
            </div>
          </div>
          <div className="trade-stock-detail">
            <div className="trade-stock-type font-8 font-bold-400 font-gray ">
              24h low
            </div>
            <div className="trade-stock-amount font-7_5 font-bold-500 font-dark">
              54,379.5
            </div>
          </div>
          <div className="trade-stock-detail">
            <div className="trade-stock-type font-8 font-bold-400 font-gray ">
              24h high
            </div>
            <div className="trade-stock-amount font-7_5 font-bold-500 font-dark">
              57,280.0
            </div>
          </div>
          <div className="trade-stock-detail">
            <div className="trade-stock-type font-8 font-bold-400 font-gray ">
              24h volume (BTC)
            </div>
            <div className="trade-stock-amount font-7_5 font-bold-500 font-dark">
              15,119
            </div>
          </div>
          <div className="trade-stock-detail">
            <div className="trade-stock-type font-8 font-bold-400 font-gray ">
              24h turnover (USDT)
            </div>
            <div className="trade-stock-amount font-7_5 font-bold-500 font-dark">
              847.05M
            </div>
          </div> */}
        </div>
        {/* <div className="trade-header-setting">
                    <div className="trade-setting-detail">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#131615"
                                fillRule="evenodd"
                                d="M13.024 4.4a.6.6 0 01.563.474l2.605 12.156 2.257-5.266A.6.6 0 0119 11.4h3a.6.6 0 010 1.2h-2.604l-2.844 6.636a.6.6 0 01-1.138-.11L12.904 7.41l-2.329 7.761a.6.6 0 01-1.126.064l-2.49-5.81-1.422 2.842A.6.6 0 015 12.6H2a.6.6 0 110-1.2h2.63l1.834-3.668a.6.6 0 011.088.032l2.349 5.48 2.525-8.416a.6.6 0 01.598-.427z"
                                clipRule="evenodd"
                                data-original="#333333"
                            ></path>
                        </svg>
                        <h4>Trading Data</h4>
                    </div>
                    <div className="trade-setting-detail">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#131615"
                                d="M19.5 0h-15C2.02 0 0 2.02 0 4.5v15C0 21.98 2.02 24 4.5 24h15c2.48 0 4.5-2.02 4.5-4.5v-15C24 2.02 21.98 0 19.5 0zM23 19.5c0 1.93-1.57 3.5-3.5 3.5h-15C2.57 23 1 21.43 1 19.5v-15C1 2.57 2.57 1 4.5 1h15C21.43 1 23 2.57 23 4.5v15zM5 9.5c0-.28.22-.5.5-.5h10c.28 0 .5.22.5.5s-.22.5-.5.5h-10c-.28 0-.5-.22-.5-.5zm0-4c0-.28.22-.5.5-.5h7c.28 0 .5.22.5.5s-.22.5-.5.5h-7c-.28 0-.5-.22-.5-.5zm14 8c0 .28-.22.5-.5.5h-13c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h13c.28 0 .5.22.5.5zm-10 4c0 .28-.22.5-.5.5h-3c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h3c.28 0 .5.22.5.5z"
                                data-original="#000000"
                            ></path>
                        </svg>
                        <h4>Information</h4>
                    </div>
                    <div className="trade-setting-detail">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 24 24"
                        >
                            <path
                                fill="#131615"
                                d="M12.51 6.9l.98.2-2 10-.98-.2 2-10zM9.33 9.56l-.71-.71-2.19 2.19c-.58.58-.58 1.52 0 2.1l2.16 2.16.71-.71-2.16-2.16a.489.489 0 010-.69l2.19-2.19zm6.07-.75l-.71.71 2.18 2.18c.19.19.19.5 0 .69l-2.18 2.18.71.71 2.18-2.18c.58-.58.58-1.52 0-2.1L15.4 8.82zm5.39 5.09l3 1.85-3.15 5.11-3-1.85c-.8.65-1.68 1.14-2.64 1.48V24H9v-3.51c-.95-.34-1.84-.83-2.64-1.48l-3 1.85-3.15-5.11 3-1.85C3.07 13.26 3 12.62 3 12s.07-1.26.21-1.9l-3-1.85 3.15-5.11 3 1.85c.8-.65 1.68-1.14 2.64-1.48V0h6v3.51c.95.34 1.84.83 2.64 1.48l3-1.85 3.15 5.11-3 1.85c.14.64.21 1.28.21 1.9s-.07 1.26-.21 1.9zm-1.15.47l.09-.36c.18-.68.27-1.35.27-2.01s-.09-1.33-.27-2.01l-.09-.36 2.77-1.71-2.1-3.4-2.77 1.71-.28-.24c-.86-.75-1.84-1.3-2.91-1.63L14 4.25V1h-4v3.24l-.35.11c-1.07.33-2.05.88-2.91 1.63l-.28.24-2.77-1.71-2.1 3.41 2.77 1.71-.09.36C4.09 10.67 4 11.34 4 12s.09 1.33.27 2.01l.09.36-2.77 1.71 2.1 3.4 2.77-1.71.28.24c.86.75 1.84 1.3 2.91 1.63l.35.11v3.24h4v-3.24l.35-.11c1.07-.33 2.05-.88 2.91-1.63l.28-.24 2.77 1.71 2.1-3.4-2.77-1.71z"
                                data-original="#000000"
                            ></path>
                        </svg>
                    </div>
                </div> */}
        <Offcanvas
          className="trading-header-filter"
          show={show}
          onHide={handleClose}
          start
        >
          <Offcanvas.Header closeButton>
            {((Object.keys(currencyPairList.data).length > 0 &&
              Object.keys(currencyPairList.data.currency_pairs).length > 0) ||
              search) && (
              <div className="stock-search">
                <Form>
                  <InputGroup>
                    <Form.Control
                      placeholder="Search currency pair"
                      onChange={(e) => {
                        setSearch(e.target.value);
                        e.target.value == ""
                          ? handleClear()
                          : handleSubmit(e.target.value);
                      }}
                      value={search}
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(search);
                        }
                      }}
                    />
                    {search == "" ? (
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => {
                          handleSubmit(search);
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width=""
                          height="20"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#cccccc"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                          <path d="M21 21l-6 -6" />
                        </svg>
                      </InputGroup.Text>
                    ) : (
                      <InputGroup.Text
                        id="basic-addon2"
                        onClick={() => {
                          handleClear();
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          enableBackground="new 0 0 512 512"
                          viewBox="0 0 512.015 512.015"
                          fill="#171717"
                        >
                          <path
                            d="M298.594 256.011L503.183 51.422c11.776-11.776 11.776-30.81 0-42.586s-30.81-11.776-42.586 0L256.008 213.425 51.418 8.836C39.642-2.94 20.608-2.94 8.832 8.836s-11.776 30.81 0 42.586l204.589 204.589L8.832 460.6c-11.776 11.776-11.776 30.81 0 42.586a30.034 30.034 0 0021.293 8.824c7.71 0 15.42-2.952 21.293-8.824l204.589-204.589 204.589 204.589a30.034 30.034 0 0021.293 8.824c7.71 0 15.42-2.952 21.293-8.824 11.776-11.776 11.776-30.81 0-42.586L298.594 256.011z"
                            data-original="#000000"
                          ></path>
                        </svg>
                      </InputGroup.Text>
                    )}
                  </InputGroup>
                </Form>
              </div>
            )}
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            {/* <StockList currencyPair={currencyPair} onHide={handleClose} /> */}
            <>
              <div className="trade-header-stock-box">
                {currencyPairList.loading ? (
                  <div className="trade-fav-stock-card">
                    {[...Array(15)].map(() => (
                      <div className="trade-header-stock-card">
                        <Skeleton height={40} width={360} />
                      </div>
                    ))}
                  </div>
                ) : currencyPair.length > 0 ? (
                  currencyPair.map((currency, index) => (
                    <div
                      className="trade-fav-stock-card"
                      onClick={() => {
                        handleClose();
                        dispatch(currencyPairStart(currency));
                      }}
                    >
                      <div
                        className={`trade-header-stock-card ${
                          currency?.unique_id == currencyPairs?.unique_id &&
                          "active"
                        }`}
                      >
                        <div className="stock-card-name grid-from_2">
                          <div className="stock-card-avater">
                            {/* <div className="fav-stock-check">
                          <input class="form-check-input" type="checkbox" value="" id={`fav_${index + 1}`} />
                          <div className="points-star" for={`fav_${index + 1}`}>
                          </div>
                        </div> */}
                            <Image
                              className="stock-icon"
                              src={
                                window.location.origin +
                                "/img/trading/crypto.png"
                              }
                              type="image/png"
                            />
                          </div>
                          <div className="stock-card-info">
                            <div className="stock-card-crypto font-8 font-bold-500">
                              {currency.unique_id}
                            </div>
                            {/* <div className="stock-card-crypto-coin font-8 font-bold-500 font-gray">
                    Bitcoin
                  </div> */}
                          </div>
                        </div>
                        {/* <div className="stock-card-price">
                <div className="stock-card-crypto-amount font-8 font-bold-500">
                
                </div>
              </div> */}
                        {/* <div className="stock-card-status">
                <div className="profit font-8 font-bold-500">-0.876%</div>
              </div> */}
                      </div>
                    </div>
                  ))
                ) : (
                  <NoDataFound />
                )}
              </div>
            </>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
};

export default TradeNav;
