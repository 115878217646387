import React from 'react'
import { Nav, Tab } from 'react-bootstrap'
import TradingTools from './TradingTools'
import TradeStock from './TradeStock'

const StockTrading = () => {
  return (
    // <Tab.Container id="left-tabs-example" defaultActiveKey="trade">
    //   <Nav variant="pills">
    //     <Nav.Item>
    //       <Nav.Link eventKey="trade">Trade</Nav.Link>
    //     </Nav.Item>
    //     {/* <Nav.Item>
    //       <Nav.Link eventKey="tools">Tools</Nav.Link>
    //     </Nav.Item> */}
    //   </Nav>
    //   <Tab.Content>
    //     <Tab.Pane eventKey="trade">
    //       <TradeStock/>
    //     </Tab.Pane>
    //     {/* <Tab.Pane eventKey="tools">
    //       <TradingTools/>
    //     </Tab.Pane> */}
    //   </Tab.Content>
    // </Tab.Container>
    <>
    <TradeStock/>
    </>
  )
}

export default StockTrading