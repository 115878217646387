import React from 'react'
import TradingHeader from './TradingHeader'
import TradingGraph from './TradingGraph'
import NewLandingHeader from "../NewLanding/NewLandingHeader";
import "./trading.css"

const TradingIndex = () => {
  return (
    <div className="trading-sec">
      <NewLandingHeader/>
      <TradingHeader/>
      <div className="feature-profile-wrapped trade-bg-sec">
        <TradingGraph/>
      </div>
    </div>
  )
}

export default TradingIndex