import React, { useEffect, useState } from 'react';
import { Modal, Button, Form } from "react-bootstrap";
import '../Wallet/Wallet.css';
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from 'react-multi-lang';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import {
  addBeneficiariesStart,
  getIbanWalletsListStart
} from '../../store/actions/TransferAction';
import Skeleton from 'react-loading-skeleton';

const AddBeneficiaryAccount = (props) => {

  const t = useTranslation("beneficiaryAccount_create");
  const dispatch = useDispatch();
  const addBeneficiaries = useSelector(state => state.transfer.addBeneficiaries);
  const ibanWalletList = useSelector(state => state.transfer.ibanWalletList);

  const validationSchema = Yup.object().shape({
    account_holder_name: Yup.string().required(t("account_holder_name.required")),
    nickname: Yup.string()
      .required(t("nickname.required")),
    iban_number: Yup.string()
      .required(t("iban_number.required")),
    address: Yup.string()
      .required(t("address.required")),
    country: Yup.string()
      .required(t("country.required")),
    account_type: Yup.string()
      .required(t("account_type.required")),
    user_wallet_id: Yup.string()
      .required(t("user_wallet_id.required"))
  });

  useEffect(() => {
    dispatch(getIbanWalletsListStart());
  }, []);

  const handleSubmit = (values) => {
    dispatch(
      addBeneficiariesStart(values)
    );
  };

  const account_options = [
    { value: "personal", label: t("personal") },
    { value: "business", label: t("business") }
  ]

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "12px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "12px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "1em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };


  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className='new-modal-custome'
      >
         <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <h4> {t("heading")}</h4>
          <Formik
            initialValues={{
              user_wallet_id: "",
              account_holder_name: "",
              iban_number: "",
              nickname: "",
              address: "",
              country: "",
              account_type: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, setFieldTouched, errors }) => (
              <FORM className='new-feature-form'>
                <Form.Group className="mb-3">
                  <Form.Label>
                    {t("user_wallet_id.label")}
                    <span>*</span>
                  </Form.Label>
                  <Select
                    aria-label="Default select example"
                    name="user_wallet_id"
                    styles={customStyles}
                    onChange={(e) => {
                      setFieldValue("user_wallet_id", e.target.value)
                    }}
                  >
                    <option selected disabled>{t("user_wallet_id.placeholder")}</option>
                    {ibanWalletList.loading ?
                      <Skeleton count={1} />
                      :
                      Object.keys(ibanWalletList.data).length > 0 &&
                        ibanWalletList.data.user_wallets.length > 0 ?
                        ibanWalletList.data.user_wallets.map(
                          (wallet, i) => (
                            <option key={i} value={wallet.id}>
                              {wallet.currency_code}
                            </option>
                          )
                        )
                        :
                        null
                    }
                  </Select>
                  <ErrorMessage
                    component={"div"}
                    name="user_wallet_id"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("account_holder_name.label")}</Form.Label>
                  <Field
                    type="text"
                    className="form-control"
                    placeholder={t("account_holder_name.placeholder")}
                    name="account_holder_name"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="account_holder_name"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("iban_number.label")}</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("iban_number.placeholder")}
                    name="iban_number"
                    type="text"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="iban_number"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("nickname.label")}</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("nickname.placeholder")}
                    name="nickname"
                    type="text"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="nickname"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("address.label")}</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("address.placeholder")}
                    name="address"
                    type="text"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="address"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3" >
                  <Form.Label>{t("country.label")}</Form.Label>
                  <Field
                    className="form-control"
                    placeholder={t("country.placeholder")}
                    name="country"
                  />
                  <ErrorMessage
                    component={"div"}
                    name="country"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("account_type.label")}</Form.Label>
                  <Select
                    options={account_options}
                    styles={customStyles}
                    isisSearchable={false}
                    onChange={(selectedOption) => {
                      setFieldValue("account_type", selectedOption.value);
                    }}
                    placeholder={t("account_type.placeholder")}
                  />
                  <ErrorMessage
                    component={"div"}
                    name="account_type"
                    className="errorMsg w-100"
                  />
                </Form.Group>
                <div className="new-action-frame layout-element">
                  <Button
                    className='new-overlay-btn'
                    onClick={props.onHide}
                  >{t("save_btn.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className='new-primary-btn'
                    disabled={addBeneficiaries.buttonDisable}
                  >
                    {addBeneficiaries.buttonDisable
                      ? t("save_btn.loading")
                      : t("save_btn.text")}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
};

export default AddBeneficiaryAccount;
