import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Image } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  cryptoWithdrawalRequestStart,
  withdrawalRequestStart,
} from "../../store/actions/BuyAction";
import configuration, { set } from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import {
  fetchAllTransactionStart,
  fetchSingleWalletViewStart,
} from "../../store/actions/WalletAction";
import { useParams } from "react-router-dom";
import TransactionSuccess from "../helper/TransactionSuccess";

const WithdrawCryptoModal = (props) => {
  const t = useTranslation("withdraw_modal");
  const dispatch = useDispatch();
  const params = useParams();
  const cryptoWithdrawalRequest = useSelector(
    (state) => state.buy.cryptoWithdrawalRequest
  );
  const [url, setUrl] = useState(null);
  const [skipRender, setSkipRender] = useState(true);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const currencies = useSelector((state) => state.buy.currencies);
  const [rangeValue, setRangeValue] = useState(0);

  const validationSchema = Yup.object().shape({
    wallet_address: Yup.string().required(t("wallet_address.required")),
    amount: Yup.number()
      .required(t("amount.required"))
      .min(0.0000001, t("amount.invalid"))
      .max(parseFloat(props.show).toFixed(8), t("amount.max_invalid")),
  });

  const handleSubmit = (values) => {
    dispatch(
      cryptoWithdrawalRequestStart({
        ...values,
        type: 1,
        user_wallet_id: props.user_wallet_id,
      })
    );
  };

  useEffect(() => {
    if (
      !skipRender &&
      !cryptoWithdrawalRequest.loading &&
      Object.keys(cryptoWithdrawalRequest.data).length > 0
    ) {
      setUrl(cryptoWithdrawalRequest.data.withdraw_transaction?.explorer_url);
      setTransactionSuccess(true);
    }
    setSkipRender(false);
  }, [cryptoWithdrawalRequest]);

  const onHide = () => {
    setTransactionSuccess(false);
    props.onHide();
    dispatch(
      fetchSingleWalletViewStart({
        user_wallet_id: props.user_wallet_id,
      })
    );
    dispatch(
      fetchAllTransactionStart({
        user_wallet_id: props.user_wallet_id,
        skip: 0,
        take: 12,
      })
    );
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="crypto_modal"
        backdrop="static"
      >
        <Modal.Body>
          {!transactionSuccess ? (
            <div className="crypto_modal_content">
              <Formik
                initialValues={{
                  wallet_address: "",
                  amount: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ touched, errors, setFieldValue, values }) => (
                  <>
                    <div className="crypto_modal_titles out_space_mx_1_5">
                      <h4> {t("heading")}</h4>
                    </div>
                    <div className="crypto_modal_form">
                      <FORM>
                        <div className="crypto_amount_input out_space_mx_1_5">
                          <div className="crypto_amount_field margin-btm-sm">
                            <Field
                              type="number"
                              name="amount"
                              onChange={(e) => {
                                const newValue = e.target.value;
                                const remainingOriginal = props.show || 0;

                                const percentage =
                                  (parseFloat(newValue) / remainingOriginal) *
                                  100;
                                setRangeValue(
                                  isNaN(percentage)
                                    ? 0
                                    : Math.min(percentage, 100).toFixed(2)
                                );
                                setFieldValue("amount", newValue);
                              }}
                              placeholder={`Enter the Amount in ${props.amount.slice(
                                -4
                              )}`}
                              className="form-control"
                            />
                            <ErrorMessage
                              component={"div"}
                              name="amount"
                              className="text-danger text-center"
                            />
                          </div>
                          <Form.Group
                            controlId="exampleForm.ControlInput1"
                            className="trade-percent-range mt-2 mb-2"
                          >
                            {/* <div className="range-label">
                      <span>{rangeValue}%</span>
                      <span>100%</span>
                    </div> */}
                            <Form.Range
                              disabled={!props.show || props.show <= 0}
                              min="0"
                              max="100"
                              value={rangeValue}
                              onChange={(e) => {
                                const data =
                                  (parseFloat(e.target.value) / 100) *
                                  props.show;
                                setRangeValue(e.target.value);
                                setFieldValue("amount", data.toFixed(8));
                              }}
                            />
                            <div className="trade-range-value">
                              {rangeValue} % / 100 %
                            </div>
                          </Form.Group>
                          <div className="crypto_amount-bls mt-2">
                            <h5>
                              Balance = <span> {props.amount}</span>
                            </h5>
                          </div>
                        </div>
                        <div className="crypto_address_card out_space_mx_1_5">
                          <div className="crypto_address_field">
                            <Form.Group className="mb-3">
                              <Form.Label>
                                {t("wallet_address.label")}
                              </Form.Label>
                              <Field
                                type="text"
                                className="form-control"
                                placeholder={t("wallet_address.placeholder")}
                                name="wallet_address"
                              />
                              <ErrorMessage
                                component={"div"}
                                name="wallet_address"
                                className="text-danger text-right"
                              />
                            </Form.Group>
                          </div>
                          <div className="crypto_address_info ">
                            <h5 className="crypto_service_info out_space_mx_1">
                              incl.
                              {t("service_fees", {
                                value: props.amount.slice(-4),
                              })}
                              =
                              <span>
                                {!isNaN(
                                  values.amount *
                                    (currencies.data?.crypto_currencies?.find(
                                      (item) =>
                                        item.currency_code ==
                                        props?.currencyCode
                                    )?.withdraw_commission /
                                      100)
                                )
                                  ? (
                                      values.amount *
                                      (currencies.data?.crypto_currencies?.find(
                                        (item) =>
                                          item.currency_code ==
                                          props?.currencyCode
                                      )?.withdraw_commission /
                                        100)
                                    ).toFixed(7)
                                  : "0.00"}
                              </span>
                            </h5>
                            <h5 className="crypto_token_info">
                              {t("user_receive_tokens")}={" "}
                              <span>
                                {!isNaN(
                                  values.amount -
                                    values.amount *
                                      (currencies.data?.crypto_currencies?.find(
                                        (item) =>
                                          item.currency_code ==
                                          props?.currencyCode
                                      )?.withdraw_commission /
                                        100)
                                )
                                  ? (
                                      values.amount -
                                      values.amount *
                                        (currencies.data?.crypto_currencies?.find(
                                          (item) =>
                                            item.currency_code ==
                                            props?.currencyCode
                                        )?.withdraw_commission /
                                          100)
                                    ).toFixed(7)
                                  : "0.00"}
                              </span>
                            </h5>
                          </div>
                        </div>
                        <div className="crypto_action item_flex_x_5">
                          <Button
                            className="action-btn overlay w-100"
                            onClick={props.onHide}
                            disabled={cryptoWithdrawalRequest.buttonDisable}
                          >
                            {t("cancel")}
                          </Button>
                          <Button
                            type="submit"
                            className="action-btn primary w-100"
                            disabled={cryptoWithdrawalRequest.buttonDisable}
                          >
                            {cryptoWithdrawalRequest.buttonDisable
                              ? t("loading")
                              : t("withdraw")}
                          </Button>
                        </div>
                      </FORM>
                    </div>
                  </>
                )}
              </Formik>
            </div>
          ) : (
            <TransactionSuccess
              message="Withdraw Completed Successfully"
              onHide={onHide}
              url={url}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WithdrawCryptoModal;
