import React, { useState } from "react";
import { Form, Button, Image, Row, Col, Container } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

const CreateRecipient = () => {
  const navigate = useNavigate();
  const [selectedUserType, setSelectedUserType] = useState("");

  const handleCardSelect = (userType) => {
    setSelectedUserType(userType);
  };

  const handleNextClick = () => {
    if (selectedUserType === "individual") {
      navigate("/create-personal-recipient");
    } else if (selectedUserType === "business") {
      navigate("/create-business-recipient");
    }
  };

  return (
    <div className="create-recipient">
      <Container>
        <div className="create-recipient-form-back">
          <Link onClick={() => navigate(-1)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="#171717"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M9.57 5.93L3.5 12l6.07 6.07M20.5 12H3.67"
              ></path>
            </svg>
          </Link>
          <h2>Add Recipients</h2>
        </div>
        <Row>
          <Col md={12}>
            <div className="select-recipent-list">
              <div className="select-recipent-heading">
                <h2>Select User Type</h2>
                <p>You can change your account type anytime</p>
              </div>
              <Row className="justify-content-center g-3">
                <Col md={12} lg={6} xl={6}>
                  <div
                    className={`select-recipent-card ${selectedUserType === "individual" ? "selected-recipent" : ""}`}
                    onClick={() => handleCardSelect("individual")}
                  >
                    <Image
                      src={
                        window.location.origin + "/img/icon/new/individual-user.svg"
                      }
                      className="recipient-icon"
                    />
                    <h4>Individual User</h4>
                    <p>Develop your business or startup idea</p>
                  </div>
                </Col>
                <Col md={12} lg={6} xl={6}>
                  <div
                    className={`select-recipent-card ${selectedUserType === "business" ? "selected-recipent" : ""}`}
                    onClick={() => handleCardSelect("business")}
                  >
                    <Image
                      src={
                        window.location.origin + "/img/icon/new/business-user.svg"
                      }
                      className="recipient-icon"
                    />
                    <h4>Business User</h4>
                    <p>Manage your account with your team</p>
                  </div>
                </Col>
              </Row>
              <div className="select-recipent-submit">
                <Button className="default-btn" onClick={handleNextClick}>Next</Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreateRecipient;
