import {
  FETCH_WALLET_DETAILS_START,
  FETCH_WALLET_DETAILS_SUCCESS,
  FETCH_WALLET_DETAILS_FAILURE,
  FETCH_ALL_TRANSACTION_START,
  FETCH_ALL_TRANSACTION_SUCCESS,
  FETCH_ALL_TRANSACTION_FAILURE,
  FETCH_MORE_ALL_TRANSACTION_START,
  SEND_WITHDRAW_REQUEST_START,
  SEND_WITHDRAW_REQUEST_SUCCESS,
  SEND_WITHDRAW_REQUEST_FAILURE,
  FETCH_WITHDRAWALS_START,
  FETCH_MORE_WITHDRAWALS_START,
  FETCH_WITHDRAWALS_SUCCESS,
  FETCH_WITHDRAWALS_FAILURE,
  CANCEL_WITHDRAW_REQUEST_START,
  CANCEL_WITHDRAW_REQUEST_SUCCESS,
  CANCEL_WITHDRAW_REQUEST_FAILURE,
  FETCH_SINGLE_WALLET_VIEW_START,
  FETCH_SINGLE_WALLET_VIEW_SUCCESS,
  FETCH_SINGLE_WALLET_VIEW_FAILURE,
  FETCH_WITHDRAWALS_UPDATE,
} from "../actions/ActionConstant";

const initialState = {
  walletData: {
    data: {},
    loading: true,
    error: false,
  },
  singleWallet: {
    data: {},
    loading: false,
    buttonDisable: false,
    error: false,
  },
  allTransaction: {
    data: {
      user_wallet_payments: [],
      total_payments: 0,
    },
    loading: true,
    error: false,
  },
  sendWithDraw: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  withDrawals: {
    data: {
      user_withdrawals: [],
      total_user_withdrawals: 0,
    },
    loading: true,
    error: false,
  },
  cancelWithDraw: {
    data: {},
    loading: true,
    error: false,
    buttonDisable: false,
    loadingButtonContent: null,
  },
  singleWallet: {
    data: {},
    loading: true,
    error: false,
  },
};

const WalletReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_WALLET_DETAILS_START:
      return {
        ...state,
        walletData: {
          data: {},
          loading: true,
          error: false,
        },
      };
    case FETCH_WALLET_DETAILS_SUCCESS:
      return {
        ...state,
        walletData: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_WALLET_DETAILS_FAILURE:
      return {
        ...state,
        walletData: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case FETCH_SINGLE_WALLET_VIEW_START:
      return {
        ...state,
        singleWallet: {
          data: {},
          loading: true,
          buttonDisable: true,
          error: false,
        },
      };
    case FETCH_SINGLE_WALLET_VIEW_SUCCESS:
      return {
        ...state,
        singleWallet: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          error: false,
        },
      };
    case FETCH_SINGLE_WALLET_VIEW_FAILURE:
      return {
        ...state,
        singleWallet: {
          data: {},
          loading: false,
          buttonDisable: false,
          error: action.error,
        },
      };

    case FETCH_ALL_TRANSACTION_START:
      return {
        ...state,
        allTransaction: {
          data: {
            user_wallet_payments: [],
            total_payments: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_ALL_TRANSACTION_SUCCESS:
      return {
        ...state,
        allTransaction: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_ALL_TRANSACTION_FAILURE:
      return {
        ...state,
        allTransaction: {
          data: {},
          loading: true,
          error: action.error,
        },
      };

    case FETCH_MORE_ALL_TRANSACTION_START:
      return state;

    case SEND_WITHDRAW_REQUEST_START:
      return {
        ...state,
        sendWithDraw: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case SEND_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        sendWithDraw: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case SEND_WITHDRAW_REQUEST_FAILURE:
      return {
        ...state,
        sendWithDraw: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case FETCH_WITHDRAWALS_START:
      return {
        ...state,
        withDrawals: {
          data: {
            user_withdrawals: [],
            total_user_withdrawals: 0,
          },
          loading: true,
          error: false,
        },
      };
    case FETCH_WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withDrawals: {
          data: action.data,
          loading: false,
          error: false,
        },
      };
    case FETCH_WITHDRAWALS_FAILURE:
      return {
        ...state,
        withDrawals: {
          data: {},
          loading: false,
          error: action.error,
        },
      };

    case FETCH_MORE_WITHDRAWALS_START:
      return state;

    case CANCEL_WITHDRAW_REQUEST_START:
      return {
        ...state,
        cancelWithDraw: {
          data: {},
          loading: true,
          error: false,
          buttonDisable: true,
          loadingButtonContent: "Loading...",
        },
      };
    case CANCEL_WITHDRAW_REQUEST_SUCCESS:
      return {
        ...state,
        cancelWithDraw: {
          data: action.data,
          loading: false,
          error: false,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };
    case CANCEL_WITHDRAW_REQUEST_FAILURE:
      return {
        ...state,
        cancelWithDraw: {
          data: {},
          loading: true,
          error: action.error,
          buttonDisable: false,
          loadingButtonContent: null,
        },
      };

    case FETCH_SINGLE_WALLET_VIEW_START:
      return {
        ...state,
        singleWallet: {
          data: {},
          loading: true,
          buttonDisable: true,
          error: false,
        },
      };
    case FETCH_SINGLE_WALLET_VIEW_SUCCESS:
      return {
        ...state,
        singleWallet: {
          data: action.data,
          loading: false,
          buttonDisable: false,
          error: false,
        },
      };
    case FETCH_SINGLE_WALLET_VIEW_FAILURE:
      return {
        ...state,
        singleWallet: {
          data: {},
          loading: false,
          buttonDisable: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
};

export default WalletReducer;
