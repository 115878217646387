import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Image,
  Form,
  InputGroup,
  Button,
  Row,
  Container,
  FormControl,
  Col,
  Modal,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { onboardingStart, registerStart } from "../../store/actions/UserAction";
import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useTranslation } from "react-multi-lang";
import Select from "react-select";
import countryList from "react-select-country-list";
import DatePicker from "react-multi-date-picker";
import "react-date-picker/dist/DatePicker.css";
import InputIcon from "react-multi-date-picker/components/input_icon";
import { Checkbox, Switch } from "pretty-checkbox-react";
import { useDispatch, useSelector } from "react-redux";
import { countryData } from "../helper/CountryList";
import { ButtonLoader } from "../helper/Loader";

const OnboardingModal = (props) => {
  const formRef = useRef(null);
  const t = useTranslation("onboarding");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboarding = useSelector((state) => state.user.onboarding);
  const [selectedGender, setSelectedGender] = useState(null);
  const [step, setStep] = useState(2);
  const [valuesSet, setValuesSet] = useState(1);
  const [value, setValues] = useState(1);
  const [checked, setChecked] = useState(false);
  const [skipRender, setSkipRender] = useState(true);
  const [selectedBillingCountry, setSelectedBillingCountry] = useState(null);
  const [selectedDeliveryCountry, setSelectedDeliveryCountry] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [onboarModalShow, setOnboarModalShow] = useState(false);
  const options = Object.entries(countryData)
    .filter(([code, name]) => code !== "IN" && code !== "IO")
    .map(([code, name]) => ({
      label: name,
      value: code,
    }));

  const genderOptions = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
    {
      label: "Others",
      value: "O",
    },
  ];

  const handleDateChange = (values) => {
    formRef.current.setFieldValue(
      "dob",
      values !== null ? JSON.stringify(new Date(values)).slice(1, 11) : ""
    );
    setSelectedDate(values);
  };

  // const validationSchema = Yup.object().shape({
  //     gender: Yup.string().required(t("required")),
  //     dob: Yup.string()
  //         .required(t("required"))
  //         .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
  //         .test("is-past-date", "date not be future", (value) => {
  //             const dob = new Date(value);
  //             const currentDate = new Date();
  //             return dob <= currentDate;
  //         })
  //         .test("is-18-years-old", t("dob.min_invalid"), (value) => {
  //             const dob = new Date(value);
  //             const currentDate = new Date();
  //             const age = currentDate.getFullYear() - dob.getFullYear();
  //             const monthDiff = currentDate.getMonth() - dob.getMonth();
  //             return (
  //                 age > 18 ||
  //                 (age === 18 && monthDiff > 0) ||
  //                 (age === 18 &&
  //                     monthDiff === 0 &&
  //                     currentDate.getDate() >= dob.getDate())
  //             );
  //         }),

  //     billing_address_1: Yup.string().required(t("required")),
  //     billing_address_2: Yup.string().required(t("required")),
  //     billing_country: Yup.string().required(t("required")),
  //     billing_city: Yup.string().required(t("required")),
  //     billing_state: Yup.string().required(t("required")),
  //     billing_postal_code: Yup.string()
  //         .required(t("required"))
  //         .test("valid-zipcode", t("postal_code.max_invalid"), (value) => {
  //             return value && value.length == 6 ? true : false;
  //         }),
  //     delivery_address_1: Yup.string().required(t("required")),
  //     delivery_address_2: Yup.string().required(t("required")),
  //     delivery_country: Yup.string().required(t("required")),
  //     delivery_city: Yup.string().required(t("required")),
  //     delivery_state: Yup.string().required(t("required")),
  //     delivery_postal_code: Yup.string()
  //         .required(t("required"))
  //         .test("valid-zipcode", t("postal_code.max_invalid"), (value) => {
  //             return value && value.length == 6 ? true : false;
  //         }),
  // });

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required(t("first_name.required")),
    dob: Yup.string()
      .required(t("website_url.required"))
      .matches(/^\d{4}-\d{2}-\d{2}$/, t("dob.invalid_date_format"))
      .test("is-past-date", "date not be future", (value) => {
        const dob = new Date(value);
        const currentDate = new Date();
        return dob <= currentDate;
      })
      .test("is-18-years-old", "must be 18 year old", (value) => {
        const dob = new Date(value);
        const currentDate = new Date();
        const age = currentDate.getFullYear() - dob.getFullYear();
        const monthDiff = currentDate.getMonth() - dob.getMonth();
        return (
          age > 18 ||
          (age === 18 && monthDiff > 0) ||
          (age === 18 &&
            monthDiff === 0 &&
            currentDate.getDate() >= dob.getDate())
        );
      }),
  });

  const billingAddressSchema = Yup.object().shape({
    billing_address_1: Yup.string()
      .required(t("first_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length > 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    billing_address_2: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length > 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    billing_country: Yup.string().required(t("last_name.required")),
    billing_city: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length > 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    billing_state: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length > 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    billing_postal_code: Yup.string()
      .required(t("mobile_number.required"))
      .test("valid-zipcode", "Five or six digits only allowed", (value) => {
        return value && (value.length == 6 || value.length == 5) ? true : false;
      }),
  });

  const deliveryAddress = Yup.object().shape({
    delivery_address_1: Yup.string()
      .required(t("first_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length > 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    delivery_address_2: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 6 }), (value) => {
        return value && value.length > 6 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 60 }), (value) => {
        return value && value.length <= 60 ? true : false;
      }),
    delivery_country: Yup.string().required(t("last_name.required")),
    delivery_city: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length > 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    delivery_state: Yup.string()
      .required(t("last_name.required"))
      .matches(
        /^\s*[a-zA-Z0-9_@.,#\/-]+(?:\s+[a-zA-Z0-9_@.,#\/-]+)*\s*$/,
        "Field contains invalid characters or extra spaces."
      )
      .test("min_invalid", t("min_invalid", { value: 3 }), (value) => {
        return value && value.length > 3 ? true : false;
      })
      .test("max_invalid", t("max_invalid", { value: 20 }), (value) => {
        return value && value.length <= 25 ? true : false;
      }),
    delivery_postal_code: Yup.string()
      .required(t("mobile_number.required"))
      .test("valid-zipcode", "Five or six digits only allowed", (value) => {
        return value && (value.length == 6 || value.length == 5) ? true : false;
      }),
  });

  const handleOnboarding = (values) => {
    valuesSet == 1 &&
      formRef.current.setValues({
        ...values,
        billing_address_1: "",
        billing_address_2: "",
        billing_country: "",
        billing_state: "",
        billing_city: "",
        billing_postal_code: "",
      });
    step == 3 &&
      value == 1 &&
      formRef.current.setValues({
        ...values,
        delivery_address_1: "",
        delivery_address_2: "",
        delivery_country: "",
        delivery_state: "",
        delivery_city: "",
        delivery_postal_code: "",
      });
    step == 3 && setValues(0);
    step == 2 && setValuesSet(0);
    step !== 4 && setStep(step + 1);
    step == 4 && dispatch(onboardingStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !onboarding.loading &&
      Object.keys(onboarding.data).length > 0
    ) {
      navigate("/");
    }
    setSkipRender(false);
  }, [onboarding]);

  // const handleSameBilling = (data) => {
  //   if (data == false) {
  //     formRef.current.setFieldValue(
  //       "delivery_address_1",
  //       formRef.current?.values.billing_address_1
  //     );
  //     formRef.current.setFieldValue(
  //       "delivery_address_2",
  //       formRef.current?.values.billing_address_2
  //     );
  //     formRef.current.setFieldValue(
  //       "delivery_country",
  //       formRef.current?.values.billing_country
  //     );
  //     formRef.current.setFieldValue(
  //       "delivery_state",
  //       formRef.current?.values.billing_state
  //     );
  //     formRef.current.setFieldValue(
  //       "delivery_city",
  //       formRef.current?.values.billing_city
  //     );
  //     formRef.current.setFieldValue(
  //       "delivery_postal_code",
  //       formRef.current?.values.billing_postal_code
  //     );
  //     setChecked(!checked);
  //     setSelectedDeliveryCountry(selectedBillingCountry);
  //   } else {
  //     formRef.current.setFieldValue("delivery_address_1", "");
  //     formRef.current.setFieldValue("delivery_address_2", "");
  //     formRef.current.setFieldValue("delivery_country", "");
  //     formRef.current.setFieldValue("delivery_state", "");
  //     formRef.current.setFieldValue("delivery_city", "");
  //     formRef.current.setFieldValue("delivery_postal_code", "");
  //     setChecked(!checked);
  //     setSelectedDeliveryCountry(null);
  //   }
  // };

  const handleSameBilling = (data) => {
    if (data == false) {
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_address_1: billingAddress.billing_address_1,
        delivery_address_2: billingAddress.billing_address_2,
        delivery_country: billingAddress.billing_country,
        delivery_state: billingAddress.billing_state,
        delivery_city: billingAddress.billing_city,
        delivery_postal_code: billingAddress.billing_postal_code,
      });

      setChecked(!checked);
      setSelectedDeliveryCountry(selectedBillingCountry);
    } else {
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_address_1: "",
        delivery_address_2: "",
        delivery_country: "",
        delivery_state: "",
        delivery_city: "",
        delivery_postal_code: "",
      });

      setChecked(false);
      setSelectedDeliveryCountry(null);
    }
  };

  const customStyles = {
    ///.....
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "10px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #e5eaf4!important",
      borderRadius: "10px!important",
      boxShadow: "none!important",
      height: "42px",
      cursor: "pointer",
      minWidth: "auto",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#878e96",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "600",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  const allowedPattern = /^[a-zA-Z0-9-.,\/ ]*$/;

  const handleKeyDown = (event) => {
    const { key } = event;
    const keyPressed = event.key;
    if (
      [
        "Backspace",
        "Tab",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Delete",
      ].includes(key)
    ) {
      return;
    }
    if (!allowedPattern.test(keyPressed) && keyPressed.length === 1) {
      event.preventDefault();
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="onboard_modal"
        backdrop="static"
      >
        <Modal.Body>
          <>
            <div className="onboard_form_wrapped">
              <div className="onboard_form_titles item_line_between ">
                <h2>{t("onboarding")}</h2>
                <Button
                  className="action-btn link-btn"
                  onClick={() => navigate("/")}
                >
                  Skip
                </Button>
              </div>
              <div className="board-form-frame">
                <Formik
                  initialValues={{
                    gender: "",
                    dob: "",
                  }}
                  validationSchema={
                    step == 2
                      ? validationSchema
                      : step == 3
                      ? billingAddressSchema
                      : deliveryAddress
                  }
                  onSubmit={handleOnboarding}
                  innerRef={formRef}
                >
                  {({ errors, touched, values, setFieldValue }) => (
                    <FORM className="create-recipient-form">
                      <div className="borad-accordian-wrapped ">
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>Personal Details</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${
                              step == 2 ? "active" : ""
                            }`}
                          >
                            <Row>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("gender")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    options={genderOptions}
                                    styles={customStyles}
                                    value={selectedGender}
                                    onChange={(data) => {
                                      setSelectedGender(data);
                                      data !== null &&
                                        setFieldValue("gender", data.value);
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="gender"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={12} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label className="w-100">
                                    {t("dob.name")} <span>*</span>
                                  </Form.Label>
                                  <DatePicker
                                    value={selectedDate}
                                    render={<InputIcon />}
                                    placeholderText="Select Schedule Date"
                                    minDate={
                                      new Date(
                                        new Date().setFullYear(
                                          new Date().getFullYear() - 60
                                        )
                                      )
                                    }
                                    maxDate={
                                      new Date(
                                        new Date().setHours(23, 59, 59, 999)
                                      )
                                    }
                                    onChange={handleDateChange}
                                    format="DD/MM/YYYY"
                                    multiple={false}
                                    placeholder="Select DOB"
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="dob"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="board-accordian-action">
                              <Button
                                className="action-btn primary"
                                type="submit"
                              >
                                {t("next")}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>{t("billing_heading")}</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${
                              step == 3 ? "active" : ""
                            }`}
                          >
                            <Row>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_address.name")} 1 <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="billing_address_1"
                                    type="text"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_address_1",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    placeholder={`${t(
                                      "billing_address.placeholder"
                                    )} 1`}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_address_1"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_address.name")} 2 <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="billing_address_2"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_address_2",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    type="text"
                                    placeholder={`${t(
                                      "billing_address.placeholder"
                                    )} 2`}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_address_2"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_city.name")} <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="billing_city"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_city",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t("billing_city.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_city"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_state.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="billing_state"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_state",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t("billing_state.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_state"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_country")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedBillingCountry}
                                    options={options}
                                    styles={customStyles}
                                    onChange={(country) => {
                                      setChecked(false);
                                      setSelectedBillingCountry(country);
                                      setFieldValue(
                                        "billing_country",
                                        country.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_country"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("billing_zip_code.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name="billing_postal_code"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "billing_postal_code",
                                        e.target.value
                                      );
                                    }}
                                    placeholder={t(
                                      "billing_zip_code.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="billing_postal_code"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <div className="board-accordian-action">
                              <Button
                                className="action-btn secondary"
                                onClick={() => {
                                  setStep(step - 1);
                                }}
                              >
                                {t("back")}
                              </Button>
                              <Button
                                className="action-btn primary"
                                type="submit"
                              >
                                {t("next")}
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="borad-accordian-card">
                          <div className="board-accordian-title">
                            <h3>{t("delivery_heading")}</h3>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 32 32"
                              width="16"
                              height="16"
                              fill="#979BA2"
                            >
                              <path d="M2 7a2 2 0 011.41.59L16 20.17 28.59 7.59a2 2 0 012.83 2.83l-14 14a2 2 0 01-2.83 0l-14-14A2 2 0 012 7z"></path>
                            </svg>
                          </div>
                          <div
                            className={`board-accordian-body ${
                              step == 4 ? "active" : ""
                            }`}
                          >
                            <Row className="justify-content-end">
                              <Col lg={6}>
                                <div className="mb-3 text-end">
                                  <Checkbox
                                    onClick={() => {
                                      handleSameBilling(checked);
                                    }}
                                    checked={checked}
                                  >
                                    {t("same_billing_details")}
                                  </Checkbox>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_address.name")} 1{" "}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="delivery_address_1"
                                    type="text"
                                    placeholder={`${t(
                                      "delivery_address.placeholder"
                                    )} 1`}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_address_1",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={handleKeyDown}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_address_1"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_address.name")} 2{" "}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    name="delivery_address_2"
                                    type="text"
                                    placeholder={`${t(
                                      "delivery_address.placeholder"
                                    )} 2`}
                                    onKeyDown={handleKeyDown}
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_address_2",
                                        e.target.value
                                      );
                                    }}
                                    className="auth-signup-adrs form-control"
                                    rows={1}
                                  />

                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_address_2"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_city.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_city",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        !/[A-Z]|[a-z]|Backspace|Spacebar|Tab/.test(
                                          e.key
                                        ) &&
                                        !(
                                          (e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")) ||
                                          e.code == "Space"
                                        )
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    name="delivery_city"
                                    placeholder={t("delivery_city.placeholder")}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_city"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_state.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="text"
                                    className="form-control"
                                    name="delivery_state"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_state",
                                        e.target.value
                                      );
                                    }}
                                    onKeyDown={(e) => {
                                      if (
                                        (!/[A-Z]|[a-z]|Backspace||Tab/.test(
                                          e.key
                                        ) &&
                                          !(
                                            e.ctrlKey &&
                                            (e.key === "v" ||
                                              e.key === "c" ||
                                              e.key === "x" ||
                                              e.key === "a")
                                          )) ||
                                        e.code == "Space"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    placeholder={t(
                                      "delivery_state.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_state"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_country")} <span>*</span>
                                  </Form.Label>
                                  <Select
                                    value={selectedDeliveryCountry}
                                    styles={customStyles}
                                    options={options}
                                    onChange={(country) => {
                                      setChecked(false);
                                      setSelectedDeliveryCountry(country);
                                      setFieldValue(
                                        "delivery_country",
                                        country.value
                                      );
                                    }}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_country"
                                    className="text-danger text-right"
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={12} lg={6} xl={6}>
                                <Form.Group
                                  controlId="formBasicEmail"
                                  className="mb-3"
                                >
                                  <Form.Label>
                                    {t("delivery_zip_code.name")}
                                    <span>*</span>
                                  </Form.Label>
                                  <Field
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      setChecked(false);
                                      setFieldValue(
                                        "delivery_postal_code",
                                        e.target.value
                                      );
                                    }}
                                    name="delivery_postal_code"
                                    placeholder={t(
                                      "delivery_zip_code.placeholder"
                                    )}
                                  />
                                  <ErrorMessage
                                    component={"div"}
                                    name="delivery_postal_code"
                                    className="text-danger"
                                  />
                                </Form.Group>
                              </Col>
                            </Row>

                            <div className="onboarding-action-btn-sec">
                              <div>
                                <Button
                                  className="action-btn  overlay"
                                  onClick={() => navigate("/")}
                                >
                                  {t("cancel")}
                                </Button>
                              </div>
                              <div>
                                <div className="board-accordian-action">
                                  <Button
                                    className="action-btn secondary"
                                    onClick={() => setStep(step - 1)}
                                  >
                                    {t("back")}
                                  </Button>
                                  <Button
                                    className="action-btn primary"
                                    type="submit"
                                    disabled={onboarding.buttonDisable}
                                  >
                                    {onboarding.buttonDisable ? (
                                      <ButtonLoader />
                                    ) : (
                                      t("onboard")
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </FORM>
                  )}
                </Formik>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OnboardingModal;
