import React, { useState, useEffect } from "react";
import { Nav, Tab } from "react-bootstrap";
import BuyStock from "./BuyStock";
import SellStock from "./SellStock";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSingleWalletViewStart,
  fetchWalletDetailsStart,
} from "../../../store/actions/WalletAction";
import {
  currencyPairStart,
  getCurrencyPairListStart,
  eventKeyStart,
} from "../../../store/actions/BuyAction";

const TradeStock = () => {
  const dispatch = useDispatch();
  const wallet = useSelector((state) => state.wallet.walletData);
  const [skipRender, setSkipRender] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const currencyPair = useSelector((state) => state.buy.currencyPair);
  const eventKey = useSelector((state) => state.buy.eventKey);
  const currencyPairList = useSelector((state) => state.buy.currencyPairList);

  useEffect(() => {
    if (sessionStorage.getItem("token") || localStorage.getItem("token")) {
      dispatch(fetchWalletDetailsStart());
    }
    dispatch(getCurrencyPairListStart());
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "second" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data["crypto_wallets"].find(
        (data) => data.currency_code === currencyPair.currency1
      );
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
  }, [currencyPair, eventKey, refresh, wallet]);

  useEffect(() => {
    if (
      !skipRender &&
      !wallet.loading &&
      eventKey == "first" &&
      Object.keys(wallet.data).length > 0 &&
      currencyPair !== null &&
      (sessionStorage.getItem("token") || localStorage.getItem("token"))
    ) {
      const walletID = wallet.data[
        ["fiat", "fait"].includes(currencyPair.currency_type2)
          ? "user_wallets"
          : "crypto_wallets"
      ].find((data) => data.currency_code === currencyPair.currency2);
      dispatch(
        fetchSingleWalletViewStart({
          user_wallet_id: walletID?.id,
        })
      );
    }
    setSkipRender(false);
  }, [currencyPair, eventKey, refresh, wallet]);

  useEffect(() => {
    if (
      !skipRender &&
      !currencyPairList.loading &&
      Object.keys(currencyPairList.data).length > 0 &&
      Object.keys(currencyPairList.data.currency_pairs).length > 0
    ) {
      const data = currencyPairList.data?.currency_pairs[0];
      dispatch(currencyPairStart(data));
    }
  }, [currencyPairList]);

  return (
    <div className="trade-stock-sec">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="first"
        onSelect={(e) => {
          dispatch(eventKeyStart(e));
        }}
      >
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="first">Buy</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="second">Sell</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className="trade-stock-main">
          <Tab.Content>
            <Tab.Pane eventKey="first" className="trade-stock-tab">
              <BuyStock onRefresh={() => setRefresh(!refresh)} />
            </Tab.Pane>
            <Tab.Pane eventKey="second" className="trade-stock-tab">
              <SellStock onRefresh={() => setRefresh(!refresh)} />
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  );
};

export default TradeStock;
