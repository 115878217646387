import React, { useEffect, useState } from "react";
import { Container, Image, Modal, Button, Tab, Nav } from "react-bootstrap";
import "../Wallet/Wallet.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { connect, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import NoDataFound from "../helper/NoDataFound";
import { fetchWalletDetailsStart } from "../../store/actions/WalletAction";
import CustomLazyLoad from "../helper/CustomLazyLoad";
import WalletRecentTransaction from "./WalletRecentTransaction";

const WalletMain = (props) => {
  const t = useTranslation("wallet_main");
  const currencies = useSelector((state) => state.buy.currencies);

  useEffect(() => {
    props.dispatch(fetchWalletDetailsStart());
  }, []);

  return (
    <>
      <div className="wallet-wrapped">
        <div className="wallet-currency-list-wrapped">
          <Container>
            <div className="wallet-currency-title">
              <h2>Wallets</h2>
            </div>
          </Container>
          <div className="wallet-tabs-content">
            <Container>
              <Tab.Container id="wallet-tabs" defaultActiveKey="crypto_wallet">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="crypto_wallet"
                      className="wallet_tab_btn"
                    >
                      {t("heading")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="fiat_wallet" className="wallet_tab_btn">
                      {t("fiat_wallets")}
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane
                    eventKey="crypto_wallet"
                    className="over_space_my_1_5"
                  >
                    <div className="wallet-currency-list-area">
                      {props.wallet.loading ? (
                        [...Array(4)].map((i, key) => (
                          <Skeleton key={key} height={135} width={250} />
                        ))
                      ) : Object.keys(props.wallet.data).length > 0 &&
                        Object.keys(currencies.data).length > 0 &&
                        props.wallet.data.user_wallets.length > 0 ? (
                        props.wallet.data.user_wallets.map(
                          (wallet, i) =>
                            wallet.currency_type == "crypto" && (
                              <div className="wallet-currency-list-card">
                                <div className="wallet-currency-list_left">
                                  <div className="wallet_currency_form item_flex_x_5">
                                    <div className="wallet-currency-list-icon">
                                      <CustomLazyLoad
                                        src={
                                          wallet.currency_picture ||
                                          window.location.origin +
                                            "/img/currency-icons/usdt.svg"
                                        }
                                      />
                                    </div>
                                    <div className="wallet-currency-list-info">
                                      <h3>{wallet.currency_code}</h3>
                                    </div>
                                  </div>
                                  <div className="wallet_currency_info">
                                    <h3>{wallet.remaining}</h3>

                                    <p>
                                      {`$${
                                        !isNaN(
                                          (
                                            props?.cryptoCurrency.find(
                                              (data) =>
                                                data.asset ==
                                                wallet.currency_code
                                            )?.value * wallet.remaining_original
                                          ).toFixed(5)
                                        )
                                          ? (
                                              props?.cryptoCurrency.find(
                                                (data) =>
                                                  data.asset ==
                                                  wallet.currency_code
                                              )?.value *
                                              wallet.remaining_original
                                            ).toFixed(5)
                                          : "0.00000"
                                      } USD`}
                                    </p>
                                  </div>
                                </div>
                                <div className="wallet-currency-list-action">
                                  <Link
                                    to={`/wallet/${wallet.currency_code}`}
                                    className="currency_list_link item_center_column"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        d="M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0145.254-45.254l224 224a32 32 0 010 45.254l-224 224a32 32 0 01-45.254-45.254z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="fiat_wallet"
                    className="over_space_my_1_5"
                  >
                    <div className="wallet-currency-list-area">
                      {props.wallet.loading ? (
                        [...Array(4)].map((i, key) => (
                          <Skeleton key={key} height={220} />
                        ))
                      ) : Object.keys(props.wallet.data).length > 0 &&
                        Object.keys(currencies.data).length > 0 &&
                        props.wallet.data.user_wallets.length > 0 ? (
                        props.wallet.data.user_wallets.map(
                          (wallet, i) =>
                            wallet.currency_type == "forex" && (
                              <div className="wallet-currency-list-card">
                                <div className="wallet-currency-list_left">
                                  <div className="wallet_currency_form item_flex_x_5">
                                    <div className="wallet-currency-list-icon">
                                      <CustomLazyLoad
                                        src={
                                          wallet.currency_picture ||
                                          window.location.origin +
                                            "/img/currency-icons/aed.svg"
                                        }
                                      />
                                    </div>
                                    <div className="wallet-currency-list-info">
                                      <h3>{wallet.currency_code}</h3>
                                    </div>
                                  </div>
                                  <div className="wallet_currency_info">
                                    <h3>{wallet.remaining}</h3>
                                    <p>
                                      {`$${
                                        wallet.remaining_original /
                                        currencies?.data?.forex_currencies
                                          .find(
                                            (data) =>
                                              data.currency_code ==
                                              wallet.currency_code
                                          )
                                          ?.exchange_rate.toFixed(5)
                                      } USD`}
                                    </p>
                                  </div>
                                </div>
                                <div className="wallet-currency-list-action">
                                  <Link
                                    to={`/wallet/${wallet.currency_code}`}
                                    className="currency_list_link item_center_column"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="12"
                                      height="12"
                                      enableBackground="new 0 0 512 512"
                                      viewBox="0 0 512 512"
                                    >
                                      <path
                                        d="M121.373 457.373L322.745 256 121.373 54.627a32 32 0 0145.254-45.254l224 224a32 32 0 010 45.254l-224 224a32 32 0 01-45.254-45.254z"
                                        data-original="#000000"
                                      ></path>
                                    </svg>
                                  </Link>
                                </div>
                              </div>
                            )
                        )
                      ) : (
                        <NoDataFound />
                      )}
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </Container>
          </div>
          <div className="wallet_recent_table">
            <Container>
              <WalletRecentTransaction />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => ({
  wallet: state.wallet.walletData,
  cryptoCurrency: state.buy.cryptoCurrency,
});

function mapDispatchToProps(dispatch) {
  return { dispatch };
}
export default connect(mapStateToPros, mapDispatchToProps)(WalletMain);
