import React from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";
const NoDataFound = (props) => {
  const t = useTranslation("no_data_found");
  return (
    <>
      <div className="no_data_content item_center_column  text-center">
        <Image
          className="no-data-img"
          src={window.location.origin + "/img/phase_1/data/no-data.png"}
        />
        <div className="no-bot-exists-info">
          <h4>{props?.message ? props?.message : t("heading")}</h4>
        </div>
      </div>
    </>
  );
};

export default NoDataFound;
