import {
  BUY_TOKEN_FAILURE,
  BUY_TOKEN_START,
  BUY_TOKEN_SUCCESS,
  CREATE_KYC_APPLICANT_FAILURE,
  CREATE_KYC_APPLICANT_START,
  CREATE_KYC_APPLICANT_SUCCESS,
  GENERATE_STRIPE_PAYMENT_FAILURE,
  GENERATE_STRIPE_PAYMENT_START,
  GENERATE_STRIPE_PAYMENT_SUCCESS,
  SWAP_TOKEN_FAILURE,
  SWAP_TOKEN_START,
  SWAP_TOKEN_SUCCESS,
  SET_BUY_DATA,
  SET_SWAP_DATA,
  RESET_BUY_DATA,
  RESET_SWAP_DATA,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REGISTER_START,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  GET_LOGIN_DETAILS,
  PROFILE_START,
  PROFILE_SUCCESS,
  PROFILE_FAILURE,
  UPDATE_KYC_START,
  UPDATE_KYC_SUCCESS,
  UPDATE_KYC_FAILURE,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_START,
  UPDATE_PROFILE_SUCCESS,
  STYLOPAY_PAYMENT_FAILURE,
  STYLOPAY_PAYMENT_START,
  STYLOPAY_PAYMENT_SUCCESS,
  TRANSATIONS_LIST_FAILURE,
  TRANSATIONS_LIST_START,
  TRANSATIONS_LIST_SUCCESS,
  FETCH_MORE_TRANSATIONS,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  DELETE_ACCOUNT_START,
  DELETE_ACCOUNT_SUCCESS,
  DELETE_ACCOUNT_FAILURE,
  ONBOARDING_START,
  ONBOARDING_SUCCESS,
  ONBOARDING_FAILURE,
  EMAIL_VERIFICATION_START,
  EMAIL_VERIFICATION_SUCCESS,
  EMAIL_VERIFICATION_FAILURE,
  EMAIL_VERIFICATION_CODE_START,
  EMAIL_VERIFICATION_CODE_SUCCESS,
  EMAIL_VERIFICATION_CODE_FAILURE,
  TWO_STEP_VERIFICATION_START,
  TWO_STEP_VERIFICATION_SUCCESS,
  TWO_STEP_VERIFICATION_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_START,
  VERIFY_FORGOT_PASSWORD_SUCCESS,
  VERIFY_FORGOT_PASSWORD_FAILURE,
  VERIFY_FORGOT_PASSWORD_START,
  RESEND_OTP_SUCCESS,
  RESEND_OTP_FAILURE,
  RESEND_OTP_START,
  ENABLE_DISABLE_TWOSTEP_AUTH_START,
  ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS,
  ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./ActionConstant";

// Store Data
export const updateBuyData = (data) => ({ type: SET_BUY_DATA, data });
export const resetBuyData = (data) => ({ type: RESET_BUY_DATA, data });
export const updateSwapData = (data) => ({ type: SET_SWAP_DATA, data });
export const resetSwapData = (data) => ({ type: RESET_SWAP_DATA, data });

// Generate stripe
export const generateStripePaymentStart = (data) => ({
  type: GENERATE_STRIPE_PAYMENT_START,
  data,
});
export const generateStripePaymentSuccess = (data) => ({
  type: GENERATE_STRIPE_PAYMENT_SUCCESS,
  data,
});
export const generateStripePaymentFailure = (error) => ({
  type: GENERATE_STRIPE_PAYMENT_FAILURE,
  error,
});

// Buy Token
export const buyTokenStart = (data) => ({ type: BUY_TOKEN_START, data });
export const buyTokenSuccess = (data) => ({ type: BUY_TOKEN_SUCCESS, data });
export const buyTokenFailure = (error) => ({ type: BUY_TOKEN_FAILURE, error });

// Create kyc applicant
export const createKycApplicantStart = (data) => ({
  type: CREATE_KYC_APPLICANT_START,
  data,
});
export const createKycApplicantSuccess = (data) => ({
  type: CREATE_KYC_APPLICANT_SUCCESS,
  data,
});
export const createKycApplicantFailure = (error) => ({
  type: CREATE_KYC_APPLICANT_FAILURE,
  error,
});

// Swap token
export const swapTokenStart = (data) => ({ type: SWAP_TOKEN_START, data });
export const swapTokenSuccess = (data) => ({ type: SWAP_TOKEN_SUCCESS, data });
export const swapTokenFailure = (error) => ({
  type: SWAP_TOKEN_FAILURE,
  error,
});

export const transactionsStart = (data) => ({
  type: TRANSATIONS_LIST_START,
  data,
});
export const fetchMoreTransactionsStart = (data) => ({
  type: FETCH_MORE_TRANSATIONS,
  data,
});
export const transactionsSuccess = (data) => ({
  type: TRANSATIONS_LIST_SUCCESS,
  data,
});
export const transactionsFailure = (error) => ({
  type: TRANSATIONS_LIST_FAILURE,
  error,
});

// Auth Actions
export const loginStart = (data) => ({ type: LOGIN_START, data });
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data });
export const loginFailure = (error) => ({ type: LOGIN_FAILURE, error });

export const registerStart = (data) => ({ type: REGISTER_START, data });
export const registerSuccess = (data) => ({ type: REGISTER_SUCCESS, data });
export const registerFailure = (error) => ({ type: REGISTER_FAILURE, error });

// Profile Actions
export const profileStart = (data) => ({ type: PROFILE_START, data });
export const profileSuccess = (data) => ({ type: PROFILE_SUCCESS, data });
export const profileFailure = (error) => ({ type: PROFILE_FAILURE, error });

export const profileUpdateStart = (data) => ({
  type: UPDATE_PROFILE_START,
  data,
});
export const profileUpdateSuccess = (data) => ({
  type: UPDATE_PROFILE_SUCCESS,
  data,
});
export const profileUpdateFailure = (error) => ({
  type: UPDATE_PROFILE_FAILURE,
  error,
});

export const updateKYCStart = (data) => ({ type: UPDATE_KYC_START, data });
export const updateKYCSuccess = (data) => ({ type: UPDATE_KYC_SUCCESS, data });
export const updateKYCFailure = (error) => ({
  type: UPDATE_KYC_FAILURE,
  error,
});

// Create STYLOPAY
export const stylopayPaymentStart = (data) => ({
  type: STYLOPAY_PAYMENT_START,
  data,
});
export const stylopayPaymentSuccess = (data) => ({
  type: STYLOPAY_PAYMENT_SUCCESS,
  data,
});
export const stylopayPaymentFailure = (error) => ({
  type: STYLOPAY_PAYMENT_FAILURE,
  error,
});

// Change Password
export const changePasswordStart = (data) => ({
  type: CHANGE_PASSWORD_START,
  data,
});
export const changePasswordSuccess = (data) => ({
  type: CHANGE_PASSWORD_SUCCESS,
  data,
});
export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  error,
});

// Delete Account
export const deleteAccountStart = (data) => ({
  type: DELETE_ACCOUNT_START,
  data,
});
export const deleteAccountSuccess = (data) => ({
  type: DELETE_ACCOUNT_SUCCESS,
  data,
});
export const deleteAccountFailure = (error) => ({
  type: DELETE_ACCOUNT_FAILURE,
  error,
});

//Onboarding
export const onboardingStart = (data) => ({
  type: ONBOARDING_START,
  data,
});
export const onboardingSuccess = (data) => ({
  type: ONBOARDING_SUCCESS,
  data,
});
export const onboardingFailure = (error) => ({
  type: ONBOARDING_FAILURE,
  error,
});

export const emailVerifyStart = (data) => ({
  type: EMAIL_VERIFICATION_START,
  data,
});
export const emailVerifySuccess = (data) => ({
  type: EMAIL_VERIFICATION_SUCCESS,
  data,
});
export const emailVerifyFailure = (error) => ({
  type: EMAIL_VERIFICATION_FAILURE,
  error,
});

export const emailVerifyCodeStart = (data) => ({
  type: EMAIL_VERIFICATION_CODE_START,
  data,
});
export const emailVerifyCodeSuccess = (data) => ({
  type: EMAIL_VERIFICATION_CODE_SUCCESS,
  data,
});
export const emailVerifyCodeFailure = (error) => ({
  type: EMAIL_VERIFICATION_CODE_FAILURE,
  error,
});

export const twoStepVerificationStart = (data) => ({
  type: TWO_STEP_VERIFICATION_START,
  data,
});
export const twoStepVerificationSuccess = (data) => ({
  type: TWO_STEP_VERIFICATION_SUCCESS,
  data,
});
export const twoStepVerificationFailure = (error) => ({
  type: TWO_STEP_VERIFICATION_FAILURE,
  error,
});

export const forgotPasswordStart = (data) => ({
  type: FORGOT_PASSWORD_START,
  data,
});
export const forgotPasswordSuccess = (data) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  data,
});
export const forgotPasswordFailure = (error) => ({
  type: FORGOT_PASSWORD_FAILURE,
  error,
});

export const verifyForgotPasswordStart = (data) => ({
  type: VERIFY_FORGOT_PASSWORD_START,
  data,
});
export const verifyForgotPasswordSuccess = (data) => ({
  type: VERIFY_FORGOT_PASSWORD_SUCCESS,
  data,
});
export const verifyForgotPasswordFailure = (error) => ({
  type: VERIFY_FORGOT_PASSWORD_FAILURE,
  error,
});

export const resendOtpStart = (data) => ({
  type: RESEND_OTP_START,
  data,
});
export const resendOtpSuccess = (data) => ({
  type: RESEND_OTP_SUCCESS,
  data,
});
export const resendOtpFailure = (error) => ({
  type: RESEND_OTP_FAILURE,
  error,
});

export const enableDisableTwoStepAuthStart = (data) => ({
  type: ENABLE_DISABLE_TWOSTEP_AUTH_START,
  data,
});
export const enableDisableTwoStepAuthSuccess = (data) => ({
  type: ENABLE_DISABLE_TWOSTEP_AUTH_SUCCESS,
  data,
});
export const enableDisableTwoStepAuthFailure = (error) => ({
  type: ENABLE_DISABLE_TWOSTEP_AUTH_FAILURE,
  error,
});

export const logoutStart = (data) => ({
  type: LOGOUT_START,
  data,
});
export const logoutSuccess = (data) => ({
  type: LOGOUT_SUCCESS,
  data,
});
export const logoutFailure = (error) => ({
  type: LOGOUT_FAILURE,
  error,
});
