import React, { useEffect, useState } from "react";
import { Nav, Tab, Image } from "react-bootstrap";
import AllStock from "./AllStock";
import ProfitStock from "./ProfitStock";
import LossStock from "./LossStock";
import LastTrade from "./LastTrade";
import Select from "react-select";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { getCryptoCurrency } from "../../../store/actions/BuyAction";
import configuration from "react-global-configuration";

const StockTableMain = () => {
  const [selectedOption, setSelectedOption] = useState({
    value: "option1",
    label: "0.5",
  });
  const [buyTrade, setBuyTrade] = useState([]);
  const [sellTrade, setSellTrade] = useState([]);
  const currencyPair = useSelector((state) => state.buy.currencyPair);

  const options = [
    { value: "option1", label: "0.5" },
    { value: "option2", label: "1" },
    { value: "option3", label: "10" },
  ];

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "12px",
      overflow: "hidden",
      minWidth: "100px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.8em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "4px!important",
      boxShadow: "none!important",
      height: "15px",
      cursor: "pointer",
      minWidth: "80px!important",
      fontSize: "0.8em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000",
      fontSize: "0.8em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      color: "#DDE1E6!important",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      fill: "#DDE1E6!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#50506B80",
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isSelected ? "#f7f7f7" : "#fff",
        color: "#000",
      };
    },
  };

  useEffect(() => {
    const cryptoExchangeUrl = configuration.get("configData.urls")
      ? configuration.get("configData.urls")
      : "wss://cms-proexchange.rare-able.com:3091";

    const socket = new WebSocket(cryptoExchangeUrl);
    socket.onopen = () => {
      const subscribeMessage = JSON.stringify({
        type: "order-book",
        pair: currencyPair?.unique_id ? currencyPair.unique_id : "BTC-USD",
      });

      socket.send(subscribeMessage);
    };

    socket.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);
        //in parsed data first one is price ,second one is size
        // setCryptoCurrencyList(
        //   parsedData.map(([asset, value]) => ({
        //     asset,
        //     value,
        //   }))
        // );
        setBuyTrade(
          parsedData.buy.map(([price, value]) => ({
            price: price,
            value: value,
          }))
        );

        setSellTrade(
          parsedData.sell.map(([price, value]) => ({
            price: price,
            value: value,
          }))
        );
      } catch (error) {}
    };
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, [currencyPair]);

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <div className="stock-table-main-tabs">
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link eventKey="first">Order Book</Nav.Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Nav.Link eventKey="second">Last Trade</Nav.Link>
          </Nav.Item> */}
        </Nav>
        {/* <div className="stock-table-main-filter">
          <Dropdown  autoClose="outside">
            <Dropdown.Toggle id="dropdown-basic">
              <Image
                className="candle-icon"
                src={
                  window.location.origin + "/img/trading/table-filter-icon.svg"
                }
                type="image/png"
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/action-1">
                <div className="table-filter-layout">
                  <div className="table-filter-qblt">
                    <div className="table-filter-info">QB LT</div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                <div className="table-filter-layout-2">
                  <div className="table-filter-qblt">
                    <div className="table-filter-info">QB</div >
                  </div>
                  <div className="table-filter-qblt">
                    <div className="table-filter-info">LT</div >
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
              <div className="table-filter-layout-3">
                  <div className="table-filter-qblt">
                    <div className="table-filter-info">QB</div >
                  </div>
                  <div className="table-filter-qblt">
                    <div className="table-filter-info">LT</div >
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      <Tab.Content>
        <Tab.Pane eventKey="first">
          <div className="stock-table-box">
            <Tab.Container id="left-tabs-example" defaultActiveKey="all">
              <div className="stock-table-tabs">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="all">
                      <Image
                        className="candle-icon"
                        src={
                          window.location.origin + "/img/trading/all-stock.png"
                        }
                        type="image/png"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="profit">
                      <Image
                        className="candle-icon"
                        src={window.location.origin + "/img/trading/profit.png"}
                        type="image/png"
                      />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="loss">
                      <Image
                        className="candle-icon"
                        src={
                          window.location.origin + "/img/trading/loss-stock.png"
                        }
                        type="image/png"
                      />
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {/* <div className="stock-table-filter">
                  <Select
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    styles={customStyles}
                  />
                </div> */}
              </div>
              <Tab.Content>
                <Tab.Pane eventKey="all">
                  <AllStock
                    buyTrade={buyTrade}
                    sellTrade={sellTrade}
                    currencyPair={currencyPair}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="profit">
                  <ProfitStock
                    buyTrade={buyTrade}
                    currencyPair={currencyPair}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="loss">
                  {" "}
                  <LossStock
                    sellTrade={sellTrade}
                    currencyPair={currencyPair}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </Tab.Pane>
        {/* <Tab.Pane eventKey="second">
          <LastTrade />
        </Tab.Pane> */}
      </Tab.Content>
    </Tab.Container>
  );
};

export default StockTableMain;
